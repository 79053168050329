import { PageWithBreadcrumbsInHeader } from '@cfra-nextgen-frontend/shared/src/components/PageWithBreadcrumbsInHeader';
import { Page } from 'analytics/Page';
import { Route, Routes } from 'react-router-dom';
import { ScreenerHome } from '../components/ScreenerHome';

export function ScreenerRoutes() {
    const pathMapping = {
        'ETF Screener': 'screener',
    };

    return (
        <Routes>
            <Route element={<PageWithBreadcrumbsInHeader pathMapping={pathMapping} includeHeader={false} />}>
                <Route
                    path='/'
                    element={
                        <Page name='etf screener'>
                            <ScreenerHome />
                        </Page>
                    }
                />
            </Route>
        </Routes>
    );
}
