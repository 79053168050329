import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { getSaveFiltersFromDirtyData } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { SaveScreenContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/saveScreenerContext/Context';
import { UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';
import { useContext } from 'react';
import { getFiltersData, getFiltersReqBody } from '../api/filters';
import { getAllSavedScreen, saveScreen } from '../api/savedScreens';
import { SaveModal } from './SaveModal';

export function SaveScreenModal() {
    const {
        saveScreenState: { openModal, saveScreenValue },
        saveScreenActionDispatcher,
    } = useContext(SaveScreenContext);
    const { filtersPostData } = useContext(FiltersModalContext);
    const {
        chipStateManager: {
            chipState: { filtersDirtyData },
        },
    } = useContext(ResultsContext);
    const filtersDataUseQueryResult = getFiltersData({ requestBody: undefined });
    const saveScreenQuery = saveScreen({
        requestBody: saveScreenValue,
        config: {
            enabled: saveScreenValue !== undefined,
        },
    });

    const getSavedItemsQuery = getAllSavedScreen({ types: [UserSavedItemsLookupID.ETFScreenerSavedScreen] });
    if (saveScreenQuery && !saveScreenQuery.isLoading && saveScreenQuery.data?.data?.id) {
        refetchScreenData(saveScreenQuery.data?.data);
    }

    async function refetchScreenData(data: any) {
        await getSavedItemsQuery.refetch();
        if (!getSavedItemsQuery.isFetching && !getSavedItemsQuery.isError && getSavedItemsQuery?.data?.data) {
            saveScreenActionDispatcher({ type: 'SaveScreenAndClose', payload: undefined });
            saveScreenActionDispatcher({ type: 'SetSelectedScreen', payload: data });
        }
    }

    const handleSaveScreenClick = (data: any) => {
        const filterData = filtersDataUseQueryResult.data;

        if (filterData) {
            const screenValue = {
                data: getFiltersReqBody(filtersPostData)?.['filters']?.['values'],
                filters: getSaveFiltersFromDirtyData(filtersDirtyData, filterData),
            };

            const payload = {
                name: data,
                saved_item_lid: 1,
                value: screenValue,
            };
            saveScreenActionDispatcher({ type: 'SaveScreen', payload });
        }
    };

    const savedScreenList = getSavedItemsQuery.data?.data?.map((item: { name: any }) => item?.name) || [];

    return (
        <SaveModal
            title='Save Screen'
            openModal={openModal}
            callbackOnClose={() => {
                saveScreenActionDispatcher({ type: 'CloseSaveScreenModal' });
            }}
            saveType='Save screen'
            onPositiveAction={handleSaveScreenClick}
            existingNames={savedScreenList}
        />
    );
}
