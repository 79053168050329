import { StringKeyValueItemWithViewdata } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { CommonFormComponentProps } from '@cfra-nextgen-frontend/shared/src/components/Form/types/form';
import { Controller } from 'react-hook-form';
import AutocompletePicklist from './AutocompletePicklist';

type FormAutocompletePicklistProps = {
    options: Array<StringKeyValueItemWithViewdata>;
    placeholder: string;
    label: string;
    onChange?: (value: any) => void;
} & CommonFormComponentProps;

export default function FormAutocompletePicklist(props: FormAutocompletePicklistProps) {
    return (
        <Controller
            name={props.name}
            control={props.control}
            defaultValue={[]}
            render={({ field }) => (
                <AutocompletePicklist
                    id={props.name}
                    options={props.options}
                    placeholder={props.placeholder}
                    defaultValues={[]}
                    value={field.value}
                    label={props.label}
                    onChange={(_, data) => {
                        field.onChange(data);
                        props.onChange?.(data);
                    }}
                />
            )}
        />
    );
}
