import Highcharts, { AxisLabelsFormatterContextObject, SeriesOptionsType } from 'highcharts';
import { Chart, getMinAndMaxTick } from './Chart';
import { defaultGridLineStyle, getDefaultFontStyle, getTooltipHTML, getToolTipOptions } from './Options';
import { ChartSeries } from './types';

export function ColumnChart({
    categories,
    series,
    subTitle,
    exportFileName,
    columnHeaderFormatter,
    useMinValue,
    tickInterval = 5,
    enableExportMenusAndTitle,
    setChartRef,
    plotOptionsBarGroupPadding = 0.2,
    plotOptionsBarPointWidth,
    chartHeight,
    chartMarginBottom,
    xAxisLabelsFormatter,
    xAxisLabelsXValue,
    xAxisOffset,
}: {
    categories: Array<string>;
    series: Array<ChartSeries> | ChartSeries;
    subTitle?: string;
    exportFileName?: string;
    columnHeaderFormatter?: (item: any, key: any) => string;
    useMinValue?: boolean; // if false min value is 0 by default
    tickInterval?: number;
    setChartRef?: (value: any) => void;
    enableExportMenusAndTitle?: boolean;
    plotOptionsBarGroupPadding?: number;
    plotOptionsBarPointWidth?: number;
    chartHeight?: number;
    chartMarginBottom: number;
    xAxisLabelsFormatter?: (this: AxisLabelsFormatterContextObject) => string;
    xAxisLabelsXValue?: number;
    xAxisOffset?: number;
}) {
    if (!Array.isArray(series)) series = [series];

    let resultSeries = series.map((value) => {
        return {
            ...value,
            type: 'column',
            borderRadiusTopLeft: '50%',
            borderRadiusTopRight: '50%',
        };
    });

    let resultSeriesData: Array<number | null> = [];
    resultSeries.forEach((value) => {
        resultSeriesData = resultSeriesData.concat(value.data as Array<number | null>);
    });

    const tooltipFormatter = function (this: Highcharts.TooltipFormatterContextObject & { category?: string }) {
        // category missed in TooltipFormatterContextObject
        let resultY;
        if (this.y !== 0) resultY = this.y?.toFixed(2);
        else resultY = this.y;
        return getTooltipHTML(this.category, [`${this.series.getName()}: ${resultY}%`]);
    };

    const [minTick, maxTick]: [number, number] = getMinAndMaxTick(tickInterval, resultSeriesData, useMinValue);

    const options: Highcharts.Options = {
        chart: {
            spacing: [0, 0, 0, 0],
            marginTop: 10, // avoid cutting top yAxis label
            marginBottom: chartMarginBottom,
            type: 'column',
            height: chartHeight,
        },
        exporting: {
            csv: {
                columnHeaderFormatter: columnHeaderFormatter,
            },
            enabled: false,
            fallbackToExportServer: false,
            filename: exportFileName,
        },
        legend: {
            enabled: true,
            itemStyle: {
                ...getDefaultFontStyle(),
                fontWeight: 'normal',
            },
            itemMarginBottom: -4, // make 0px distance between bottom of the chart and legend
            padding: 5, // influence on the size of the legend
            symbolPadding: 12, // padding between circle and legend item text
            symbolHeight: 18, // radius of circle
            useHTML: true, // need it to align symbol and item text vertically
            itemDistance: 24, // distance between items
        },
        plotOptions: {
            column: {
                minPointLength: 2,
                groupPadding: plotOptionsBarGroupPadding, // distance between bar groups, default value 0.2
                pointWidth: plotOptionsBarPointWidth,
            },
        },
        series: resultSeries as Array<SeriesOptionsType>,
        title: {
            text: undefined,
        },
        tooltip: {
            ...getToolTipOptions(true, tooltipFormatter),
        },
        xAxis: {
            categories: categories,
            labels: {
                align: 'left',
                formatter: xAxisLabelsFormatter,
                enabled: true,
                style: { ...getDefaultFontStyle(), textOverflow: 'none', whiteSpace: 'nowrap' },
                y: 0, // align labels with aAxis
                x: xAxisLabelsXValue,
                rotation: -90,
            },
            lineWidth: 0,
            offset: xAxisOffset,
            title: {
                text: undefined,
            },
        },
        yAxis: {
            labels: {
                format: '{value}%',
                overflow: 'allow',
                padding: 5,
                style: { ...getDefaultFontStyle() },
                x: -28, // distance between yAxis labels and chart area
            },

            max: maxTick,
            min: minTick,
            tickInterval: tickInterval,
            title: {
                text: undefined,
            },
            ...defaultGridLineStyle,
        } as Highcharts.XAxisOptions,
    };

    return (
        <Chart
            subTitle={subTitle}
            options={options}
            setChartRef={setChartRef}
            enableExportMenusAndTitle={enableExportMenusAndTitle}
        />
    );
}
