import { ThemeProvider } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import React from 'react';
import { fontFamilies } from '../../utils';
import Tooltip from '../dataDisplay/Tooltip';
import { tooltipThemeV2 } from '../themes/theme';

type SelectionProps = {
    selectItems?: Array<string>;
    currentSelection?: string;
    handleSelectionChange: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void;
};

const fontStyle = { fontFamily: fontFamilies.GraphikRegular, fontSize: '14px' };

const StyledSelect = styled(Select)(({ theme }) => ({
    ...fontStyle,
    color: '#3078B5',
    verticalAlign: 'top',
    height: '100%',
    padding: '0px 10px 0px 6px',
    '&.MuiInputBase-root': {
        height: '100%',
        '&::before': {
            borderBottom: '2px solid #4375BB',
        },
        '&:hover': {
            '&::before': {
                borderBottom: '2px solid #4375BB',
            },
        },
        '& .MuiSelect-select': { height: '100%' },
        '& .MuiSelect-icon': { top: '-10%' },
    },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    ...fontStyle,
    color: '#57626A',
}));

//mousehandler is an optional event to handle open/close behaviour for a surrounding tooltip [controlled tooltip].
export default function CFRASelect({ selectItems = [], currentSelection, handleSelectionChange }: SelectionProps) {
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const handleTooltip = (state: boolean) => setTooltipOpen(state);
    return (
        <ThemeProvider theme={tooltipThemeV2}>
            <Tooltip title='Select Period' open={tooltipOpen}>
                <StyledSelect
                    variant='standard'
                    value={currentSelection}
                    onChange={handleSelectionChange}
                    onMouseEnter={() => handleTooltip(true)}
                    onMouseLeave={() => handleTooltip(false)}
                    onOpen={() => handleTooltip(false)}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                filter: 'drop-shadow(0px 0px 3.68px rgba(0, 0, 0, 0.3))',
                                '& .MuiList-root': {
                                    padding: '0px 0px',
                                },
                            },
                        },
                    }}>
                    {selectItems.map((item) => (
                        <StyledMenuItem value={item} key={item}>
                            {item}
                        </StyledMenuItem>
                    ))}
                </StyledSelect>
            </Tooltip>
        </ThemeProvider>
    );
}
