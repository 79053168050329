import ETFModal from '@cfra-nextgen-frontend/shared/src/components/ETFModal/index';
import { overlayModalTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import { SxProps, ThemeProvider } from '@mui/material';

type ModalProps = {
    title?: string;
    children: React.ReactNode;
    openModal: boolean;
    callbackOnClose?: () => void;
    titleAndDescriptionContainerStyle?: SxProps;
    titleAndDescriptionContainerXs?: number | boolean;
    modalBoxStyles?: SxProps;
    closeButtonFontSize?: number;
    showCloseButton?: boolean;
    description?: string;
    descriptionStyle?: SxProps;
};

export function Modal({
    title,
    children,
    openModal,
    callbackOnClose,
    titleAndDescriptionContainerStyle,
    titleAndDescriptionContainerXs,
    modalBoxStyles,
    closeButtonFontSize = 30,
    showCloseButton,
    description,
    descriptionStyle,
}: ModalProps) {
    return (
        <ThemeProvider theme={overlayModalTheme}>
            <ETFModal
                title={title}
                description={description}
                descriptionStyle={descriptionStyle}
                titleStyle={{
                    color: '#0B2958',
                    fontSize: '28px',
                    fontFamily: fontFamilies.GraphikMedium,
                    paddingBottom: '0px',
                }}
                titleAndDescriptionContainerStyle={titleAndDescriptionContainerStyle}
                titleAndDescriptionContainerXs={titleAndDescriptionContainerXs}
                closeButtonFontSize={closeButtonFontSize}
                callbackOnClose={callbackOnClose}
                modalBoxStyles={{
                    height: '78.7%',
                    display: 'block',
                    maxWidth: '1400px',
                    maxHeight: 'none',
                    overflowY: 'hidden',
                    ...modalBoxStyles,
                }}
                externalOpenModal={openModal}
                showCloseButton={showCloseButton}
                keepContentOnClose
                zIndex={2000}>
                {children}
            </ETFModal>
        </ThemeProvider>
    );
}
