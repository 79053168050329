import { createContext, useMemo, useReducer } from 'react';
import { customViewEditorReducer } from './CustomViewEditorReducer';
import { DispatcherState, State } from './types';

export const CustomViewEditorContext = createContext<DispatcherState>({} as DispatcherState);

const InitialState: State = {
    openModal: false,
    isDirtyRightColumn: false,
    openExitConfirmationModal: false,
    openNoColumnsSelectedModal: false,
    nameOverlayMode: false,
    resetColumnsState: false,
};

export function CustomViewEditorContextProvider({ children }: { children: React.ReactNode }) {
    const memorizedChildren = useMemo(() => children, [children]);
    const [state, stateDispatcher] = useReducer(customViewEditorReducer, InitialState);

    const contextValue = {
        customViewEditorState: state,
        customViewEditorStateDispatcher: stateDispatcher,
    };

    return (
        <CustomViewEditorContext.Provider value={contextValue}>{memorizedChildren}</CustomViewEditorContext.Provider>
    );
}
