import { Dispatch, createContext, useMemo, useState } from 'react';

export type AgGridSelectedRowsContextType = {
    selectedRowsCount: number;
    setSelectedRowsCount: Dispatch<number>;
};

export const AgGridSelectedRowsContext = createContext<AgGridSelectedRowsContextType>(
    {} as AgGridSelectedRowsContextType,
);

export function AgGridSelectedRowsContextProvider({ children }: { children: React.ReactNode }) {
    const memorizedChildren = useMemo(() => children, [children]);
    const [selectedRowsCount, setSelectedRowsCount] = useState<number>(0);

    const contextValue = {
        selectedRowsCount,
        setSelectedRowsCount,
    };

    return (
        <AgGridSelectedRowsContext.Provider value={contextValue}>
            {memorizedChildren}
        </AgGridSelectedRowsContext.Provider>
    );
}
