import { CheckboxProps, Checkbox as MUICheckbox } from '@mui/material';
import { forwardRef } from 'react';

const defaultCheckboxStyle = {
    width: '24px',
    height: '24px',
    '&.Mui-checked': {
        color: '#3078B5',
    },
    color: '#9E9E9E',
};

export const CustomCheckbox = forwardRef<HTMLButtonElement, CheckboxProps>((props, ref) => {
    const { sx, ...restProps } = props;
    return <MUICheckbox ref={ref} sx={{ ...defaultCheckboxStyle, ...sx }} {...restProps} />;
});
