import { Card, Typography } from "@cfra-nextgen-frontend/shared";
import { Box, CardProps, Stack } from "@mui/material";

type FeatureCardProps = CardProps & {
    img: string;
    text: string;
};

export function FeatureCard(props: FeatureCardProps) {
    const { img, text, ...rest } = props;
    return (
        <Card.Card {...rest}>
            <Stack direction="column" spacing={4}>
                <Box component='img' src={img} alt="feature image" sx={{ width: '40%', minWidth: '90px', height: 'auto' }} />
                <Typography variant="body1" textAlign='left'>{text}</Typography>
            </Stack>
        </Card.Card>
    );
};