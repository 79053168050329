import { CustomCheckbox } from '@cfra-nextgen-frontend/shared/src/components/ETFButton';
import {
    AutocompleteSearchProps,
    Root,
    SearchBar,
} from '@cfra-nextgen-frontend/shared/src/components/Form/shared/Autocomplete';
import { Item } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout/Grid';
import {
    BorderHeight,
    BorderStyle,
} from '@cfra-nextgen-frontend/shared/src/components/layout/ScrollableColumn/ScrollableColumn';
import { breakpointsTheme, scrollbarThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import { useAutocomplete } from '@mui/base/useAutocomplete';
import { Box, CheckboxProps, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

const StyledHr = styled('hr')(() => ({
    border: '0px',
    borderBottom: BorderStyle,
    margin: '0px',
}));

const Label = styled('label')(() => ({
    paddingLeft: '1px',
    color: '#0B2958',
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '18px',
    paddingTop: '26px',
    paddingBottom: '24px',
    display: 'block',
}));

const OptionLabel = styled('label')(() => ({
    color: '#57626A',
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '16px',
    display: 'block',
}));

let numberOfColumnsInTable = 3;

const GridCell = (props: { index: number; children?: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <Grid
            item
            xs={12 / numberOfColumnsInTable}
            sx={{
                borderRight: BorderStyle,
                borderBottom: BorderStyle,
                paddingLeft: (props.index + 1) % numberOfColumnsInTable === 1 ? '29px' : '0px',
                height: `${45 + BorderHeight}px`,
                display: 'flex',
                alignItems: 'center',
                ...props.style,
            }}>
            {props.children}
        </Grid>
    );
};

const GridHeaderCell = (props: { index: number; children?: React.ReactNode }) => {
    return (
        <GridCell style={{ height: '70px' }} index={props.index}>
            {props.children}
        </GridCell>
    );
};

const GridRow = ({
    indexStart,
    label,
    CellElement,
    LabelElement,
    labelProps,
}: {
    indexStart: number;
    label: string;
    CellElement: React.FC<any>;
    LabelElement: React.FC;
    labelProps?: React.HTMLAttributes<HTMLLabelElement>;
}) => {
    return (
        <>
            <CellElement index={indexStart + 0}>
                <LabelElement {...labelProps}>{label}</LabelElement>
            </CellElement>
            {Array.from(Array(numberOfColumnsInTable - 1)).map((_, index) => (
                <CellElement index={indexStart + 1 + index} key={indexStart + 1 + index} />
            ))}
        </>
    );
};

export default function AutocompleteSearchTable(props: AutocompleteSearchProps<Item>) {
    const [inputValue, setInputValue] = useState('');
    const isBelowSm = useMediaQuery(breakpointsTheme.breakpoints.down('sm'));

    if (isBelowSm) {
        numberOfColumnsInTable = 2;
    } else {
        numberOfColumnsInTable = 3;
    }

    const { getRootProps, getInputLabelProps, getInputProps, getOptionProps, groupedOptions, focused, setAnchorEl } =
        useAutocomplete({
            id: props.id,
            open: true,
            defaultValue: props.defaultValues,
            multiple: true,
            options: props.options,
            getOptionLabel: (option) => option.value,
            onChange: props.onChange,
            value: props.value,
            isOptionEqualToValue: (option, value) => option.key === value.key,
            clearOnBlur: false,
            inputValue: inputValue,
            onInputChange: (event, newInputValue, reason) => {
                if (reason === 'reset') {
                    return; // prevent input from clearing on option select
                }
                setInputValue(newInputValue);
            },
            filterOptions: (options, { inputValue }) =>
                options.filter((option) => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1),
        });
    return (
        <Root>
            <SearchBar
                focused={focused}
                inputProps={getInputProps()}
                placeholder={props.placeholder}
                inputValue={inputValue}
                setInputValue={setInputValue}
                setAnchorEl={setAnchorEl}
                rootProps={getRootProps()}
                inputStyle={{
                    marginLeft: '32px',
                    marginRight: '32px',
                    width: 'calc(100% - 64px)',
                }}
            />
            <StyledHr />
            <Box
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    overflowY: 'auto',
                    flexDirection: 'column',
                    ...scrollbarThemeV2,
                }}>
                <Grid container>
                    <GridRow
                        indexStart={0}
                        label={props.label}
                        LabelElement={Label}
                        CellElement={GridHeaderCell}
                        labelProps={getInputLabelProps()}
                    />
                    {(groupedOptions as Array<Item>).map((option, index) => {
                        const optionProps = getOptionProps({
                            option,
                            index,
                        }) as unknown as CheckboxProps; // required to do such conversion, due to getOptionProps return li props

                        return (
                            <GridCell index={numberOfColumnsInTable + index} key={numberOfColumnsInTable + index}>
                                <CustomCheckbox
                                    {...optionProps}
                                    checked={Boolean(optionProps['aria-selected'])}
                                    sx={{ width: '42px', height: '42px', marginLeft: '8px', marginRight: '8px' }}
                                />
                                <OptionLabel>{option.value}</OptionLabel>
                            </GridCell>
                        );
                    })}
                    {groupedOptions.length % numberOfColumnsInTable > 0
                        ? Array.from(
                              Array(numberOfColumnsInTable - (groupedOptions.length % numberOfColumnsInTable)),
                          ).map((_, index) => (
                              <GridCell
                                  index={groupedOptions.length + numberOfColumnsInTable + index}
                                  key={numberOfColumnsInTable + index}
                              />
                          ))
                        : null}
                    {groupedOptions.length === 0 ? (
                        <GridRow
                            label={'No Results'}
                            LabelElement={OptionLabel}
                            CellElement={GridCell}
                            indexStart={numberOfColumnsInTable}
                        />
                    ) : null}
                </Grid>
                <Grid container sx={{ flexGrow: 1 }}></Grid>
            </Box>
        </Root>
    );
}
