import { HorizontalPagePaddingString } from '@cfra-nextgen-frontend/shared/src/utils';
import Box from '@mui/material/Box';
import React from 'react';

type Props = {
    children: React.ReactNode;
    style?: Object;
};

export function ETFTabContainer({ children, style }: Props) {
    return (
        <Box
            sx={{
                ...{
                    backgroundColor: '#F2F5FC',
                    padding: `32px ${HorizontalPagePaddingString} 72px ${HorizontalPagePaddingString}`,
                },
                ...style,
            }}>
            {children}
        </Box>
    );
}
