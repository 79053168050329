import Axios, { AxiosRequestConfig } from 'axios';
import { MSA_API_URL } from 'config';
import { storage } from 'utils/storage';

function authRequestInterceptor(config: AxiosRequestConfig) {
    const token = storage.getToken();
    const requestConfig = config;

    // Example of how to append token for all requests, would be Authorization: Bearer <token> for a real implementation
    if (token && requestConfig && requestConfig.headers) {
        requestConfig.headers.authorization = `Bearer ${token.idToken}`;
    }
    if (requestConfig && requestConfig.headers) {
        requestConfig.headers.Accept = 'application/json';
    }
    return requestConfig;
}

// Set Base URL
export const axios = Axios.create({
    baseURL: MSA_API_URL,
});

// Use Auth Request Interceptor to add Auth Header (Bearer Token)
axios.interceptors.request.use(authRequestInterceptor);
