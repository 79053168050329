import { ETFCard, Layout } from '@cfra-nextgen-frontend/shared';
import { OverflowElipsis } from '@cfra-nextgen-frontend/shared/src/components/ETFTwoColumnGrid';
import Tooltip from '@cfra-nextgen-frontend/shared/src/components/dataDisplay/Tooltip';
import { getExportExcelFileName } from '@cfra-nextgen-frontend/shared/src/components/excelExport/export';
import {
    ColumnsToFormat,
    ExcelCustomFields,
    ExcelMetadata,
    ExcelTable,
} from '@cfra-nextgen-frontend/shared/src/components/excelExport/type';
import { ColumStyle } from '@cfra-nextgen-frontend/shared/src/components/layout/types/types';
import { tableTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import {
    DataPointsDisplayNames,
    FormatValueParams,
    SectionsNames,
    asOfDateFormat,
    assetClasses,
    formatValue,
    getDataPointDisplayNameToFieldName,
    getDataPointsDisplayNameToFormattingType,
    getDataPointsDisplayNames,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { valuesTypesToExcelNumberFormat } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { ThemeProvider } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { getDownloadAction } from 'analytics/utils';
import { TableExportMenus } from 'components/Chart/ExportMenus';
import { getDefaultFontStyle } from 'components/Chart/Options';
import { exportExcel } from 'components/excelExport';
import { ETFInfo } from 'components/layout';
import * as React from 'react';
import { getEtfDataHoldingsAndExposure } from '../api/etfDetailsData';
import { EtfDataHoldingsAndExposure, EtfDataHoldingsAndExposureRow, EtfDetailsData } from '../types/research';

const informationLabel = {
    title: 'Information',
    content:
        'The sum of weights for top holdings can exceed 100% in some cases e.g. when an ETF has derivatives exposure or has both long & short positions. Such ETFs will have some constituents with negative weights, and the sum of weights will always be 100%.',
};

export default function TopHoldings({ etfDetailsData, cfraId }: { etfDetailsData: EtfDetailsData; cfraId: string }) {
    const top = 10;
    const etfDataHoldingsAndExposure = React.useRef<any>();

    // getting UseQueryResult object with data for etfDataHoldingsAndExposure.current
    const etfDataHoldingsAndExposureQueryResult = getEtfDataHoldingsAndExposure({
        cfraId: cfraId,
        top: top,
        config: {
            refetchOnWindowFocus: false,
        },
    });

    const { refetch } = getEtfDataHoldingsAndExposure({
        cfraId: cfraId,
        top: 50000,
        config: {
            refetchOnWindowFocus: false,
            enabled: false,
        },
    });

    // get display names list for all data points
    const DataPointsDisplayNames = getDataPointsDisplayNames();

    // get matching between display names and object property name
    const dataPointDisplayNameToFieldName = getDataPointDisplayNameToFieldName(SectionsNames.TopHoldings);

    // get matching between display names and value formatting type
    const dataPointsDisplayNameToFormattingType = getDataPointsDisplayNameToFormattingType(SectionsNames.TopHoldings);
    // get total row template
    const totalRowText: string = DataPointsDisplayNames.TotalsRow.replace('{top}', top.toString());

    // create matching between asset class and data points to show in UI
    const assetClassToDataPoints: Record<assetClasses, Array<DataPointsDisplayNames>> = {
        [assetClasses.EquitiesStocks]: [
            DataPointsDisplayNames.Name,
            DataPointsDisplayNames.Ticker,
            DataPointsDisplayNames.PercentageOfETFAssets,
            DataPointsDisplayNames.ConstituentType,
            DataPointsDisplayNames.YTDReturn,
            DataPointsDisplayNames.Sector,
        ],
        [assetClasses.Bonds]: [
            DataPointsDisplayNames.Name,
            DataPointsDisplayNames.Cusip,
            DataPointsDisplayNames.PercentageOfETFAssets,
            DataPointsDisplayNames.ConstituentType,
            DataPointsDisplayNames.MaturityDate,
            DataPointsDisplayNames.Coupon,
        ],
        [assetClasses.CommoditiesAndMetals]: [
            DataPointsDisplayNames.Name,
            DataPointsDisplayNames.TickerCusip,
            DataPointsDisplayNames.PercentageOfETFAssets,
            DataPointsDisplayNames.ConstituentType,
        ],
        [assetClasses.TargetDateMultiAsset]: [
            DataPointsDisplayNames.Name,
            DataPointsDisplayNames.TickerCusip,
            DataPointsDisplayNames.PercentageOfETFAssets,
            DataPointsDisplayNames.ConstituentType,
        ],
        [assetClasses.Currency]: [
            DataPointsDisplayNames.Name,
            DataPointsDisplayNames.TickerCusip,
            DataPointsDisplayNames.PercentageOfETFAssets,
            DataPointsDisplayNames.ConstituentType,
        ],
        [assetClasses.OtherAssetTypes]: [
            DataPointsDisplayNames.Name,
            DataPointsDisplayNames.TickerCusip,
            DataPointsDisplayNames.PercentageOfETFAssets,
            DataPointsDisplayNames.ConstituentType,
        ],
    };

    // show card loading if data still loading
    if (etfDataHoldingsAndExposureQueryResult.isLoading && !etfDataHoldingsAndExposureQueryResult.data) {
        return <ETFCard.ETFCard isLoading={etfDataHoldingsAndExposureQueryResult.isLoading} />;
    }

    const cardName = SectionsNames.TopHoldings;

    // return EmptyCard if no data
    if (
        !(
            etfDataHoldingsAndExposureQueryResult.data &&
            etfDataHoldingsAndExposureQueryResult.data.holdings &&
            etfDataHoldingsAndExposureQueryResult.data.holdings.length > 0 &&
            etfDetailsData.asset_class
        )
    ) {
        return <ETFCard.ETFEmptyCard cardLabel={cardName}></ETFCard.ETFEmptyCard>;
    }

    // cut off UseQueryResult attributes, extract only etfDataHoldingsAndExposure.current data
    etfDataHoldingsAndExposure.current = etfDataHoldingsAndExposureQueryResult.data;

    // create matching data point name and column width for specific asset class
    const assetClassTodataPointNameToCellStyle: Record<assetClasses, { [key in DataPointsDisplayNames]?: ColumStyle }> =
        {
            [assetClasses.EquitiesStocks]: {
                [DataPointsDisplayNames.Name]: { width: '35%' },
                [DataPointsDisplayNames.Cusip]: { width: '10%' },
                [DataPointsDisplayNames.PercentageOfETFAssets]: { width: '10%' },
            },
            [assetClasses.Bonds]: {
                [DataPointsDisplayNames.Name]: { width: '45%' },
                [DataPointsDisplayNames.Cusip]: { width: '10%' },
                [DataPointsDisplayNames.PercentageOfETFAssets]: { width: '10%' },
                [DataPointsDisplayNames.MaturityDate]: { width: '10%' },
            },
            [assetClasses.CommoditiesAndMetals]: {
                [DataPointsDisplayNames.Name]: { width: '50%' },
            },
            [assetClasses.Currency]: {
                [DataPointsDisplayNames.Name]: { width: '45%' },
            },
            [assetClasses.OtherAssetTypes]: {
                [DataPointsDisplayNames.Name]: { width: '45%' },
            },
            [assetClasses.TargetDateMultiAsset]: {
                [DataPointsDisplayNames.Name]: { width: '50%' },
            },
        };

    const displayShortNames: { [k in DataPointsDisplayNames]?: string } = {
        [DataPointsDisplayNames.ConstituentType]: 'Type',
        [DataPointsDisplayNames.PercentageOfETFAssets]: '% of ETF',
        [DataPointsDisplayNames.MaturityDate]: 'Maturity',
    };

    // get total row value
    const totalRowValue = formatValue({
        value: etfDataHoldingsAndExposure.current.total_weight_percentage,
        formattingType: dataPointsDisplayNameToFormattingType[DataPointsDisplayNames.TotalsRow],
    });

    const asOfDate = etfDataHoldingsAndExposure.current.holdings
        .map((item: { as_of_date: any }) => item.as_of_date)
        .sort()
        .reverse()[0];

    function handleHoldingsData({
        holdingsData,
        fillTableCells,
        downloadCardName,
    }: {
        holdingsData: EtfDataHoldingsAndExposure;
        fillTableCells: boolean;
        downloadCardName: string;
    }) {
        const allColumns: Array<string> = [];
        const exportColumns: ColumnsToFormat = {};
        const exportData: any[][] = [];
        const tableRows: any = [];

        holdingsData.holdings.forEach((row: EtfDataHoldingsAndExposureRow, rowIndex: number) => {
            const tableCells: any = [];
            const exportCells: any = [];

            // loop through all data points for this asset class
            assetClassToDataPoints[etfDetailsData.asset_class].forEach((key) => {
                // create instance of FormatValueParams class
                const formatValueParams = new FormatValueParams({
                    source: row,
                    dataPointDisplayNameToFieldName: dataPointDisplayNameToFieldName,
                    dataPointsDisplayNameToFormattingType: dataPointsDisplayNameToFormattingType,
                });
                // create parameters for formatValue function to get the data point value placed in key variable
                let formatValueParameters = formatValueParams.create({ key: key });
                // data processing for export
                if (dataPointDisplayNameToFieldName[key]) {
                    if (rowIndex === 0) {
                        allColumns.push(key);
                        exportColumns[key] = {
                            numberFormat: valuesTypesToExcelNumberFormat[formatValueParameters.formattingType],
                        };
                    }
                    exportCells.push(formatValueParameters.value);
                }
                // for data point MaturityDate set dateFormat
                if (key === DataPointsDisplayNames.MaturityDate) {
                    formatValueParameters = {
                        ...formatValueParameters,
                        ...{
                            additionalConfig: {
                                dateFormat: 'MM/DD/YYYY',
                            },
                        },
                    };
                }
                // for data point Coupon set parameter showNumberIfZero - formatter will return 0 if value === 0 (usually it returns dash in this case)
                if (key === DataPointsDisplayNames.Coupon) {
                    formatValueParameters = {
                        ...formatValueParameters,
                        showNumberIfZero: true,
                    };
                }
                // get formatted value
                let formatedValue = formatValue(formatValueParameters);
                // for the data point TickerCusip apply logic - show Ticker value if it is available
                // otherwise show Cusip value if it is available
                // otherwise show dash
                if (key === DataPointsDisplayNames.TickerCusip) {
                    formatedValue = formatValue(formatValueParams.create({ key: DataPointsDisplayNames.Ticker }));
                    if (formatedValue === '-') {
                        formatedValue = formatValue(formatValueParams.create({ key: DataPointsDisplayNames.Cusip }));
                    }
                }

                if (!fillTableCells) {
                    return;
                }

                // fill tableCells list with table cell filled with formatted value
                tableCells.push(
                    <TableCell>
                        {[DataPointsDisplayNames.Name, DataPointsDisplayNames.ConstituentType].includes(key) ? (
                            <Tooltip title={formatedValue}>
                                <OverflowElipsis
                                    sx={{
                                        textTransform: key === DataPointsDisplayNames.Name ? 'uppercase' : 'none',
                                        color: '#002B5A',
                                        WebkitLineClamp: '1',
                                    }}>
                                    {formatedValue}
                                </OverflowElipsis>
                            </Tooltip>
                        ) : (
                            formatedValue
                        )}
                    </TableCell>,
                );
            });

            exportData.push(exportCells);
            // tableRows with TableRow element with tableCells inside
            tableRows.push(<TableRow>{React.Children.toArray(tableCells)}</TableRow>);
        });

        const excelTable: ExcelTable = {
            data: exportData,
            allColumns: allColumns,
            columnsToFormat: exportColumns,
            columnStyles: assetClassTodataPointNameToCellStyle[etfDetailsData.asset_class],
        };

        const excelMetadata: ExcelMetadata = {
            cardName: downloadCardName,
            ticker: etfDetailsData.ticker,
            etfName: etfDetailsData.composite_name,
            asOfDate: `${asOfDateFormat(asOfDate)}`,
        };

        return {
            tableRows,
            excelTable,
            excelMetadata,
        };
    }

    let { tableRows, excelTable, excelMetadata } = handleHoldingsData({
        holdingsData: etfDataHoldingsAndExposure.current,
        fillTableCells: true,
        downloadCardName: SectionsNames.TopHoldings,
    });

    const customFields: ExcelCustomFields = {
        data: [[`${totalRowText} ${totalRowValue}`]],
        fieldStyle: [[{ font: { bold: true } }]],
    };

    function getDownloadOptions() {
        const registerDownload = (downloadType: string) =>
            globalThis.analytics?.registerAction?.({
                action: getDownloadAction(downloadType),
                cardName: SectionsNames.TopHoldings,
                reportType: 'XLSX',
                reportName: getExportExcelFileName(excelMetadata),
            });

        const downloadOptions = new Map<string, () => void>();

        downloadOptions.set('Download Top Ten', () => {
            exportExcel(excelTable, excelMetadata, customFields);
            registerDownload('top ten');
        });

        const allHoldings = 'All Holdings';

        downloadOptions.set(`Download ${allHoldings}`, async () => {
            refetch().then((data) => {
                if (!data.data) {
                    return;
                }

                let { excelTable, excelMetadata } = handleHoldingsData({
                    holdingsData: data.data,
                    fillTableCells: false,
                    downloadCardName: allHoldings,
                });
                exportExcel(excelTable, excelMetadata);
                registerDownload(allHoldings.toLocaleLowerCase());
            });
        });

        return downloadOptions;
    }

    const ItemSubHeader = styled(ETFCard.ItemHeader)(({ theme }) => ({
        ...getDefaultFontStyle(15),
    }));

    const subHeader = 'See this ETF’s ten largest holdings in the table below, or download all current fund holdings.';

    return (
        <ThemeProvider theme={tableTheme}>
            <ETFCard.ETFCard containerStyles={{ position: 'relative' }}>
                <Layout.Grid item xs={12} sx={{ paddingLeft: '28px', paddingRight: '28px' }}>
                    <ETFCard.ItemHeader style={{ paddingBottom: '12px' }}>{cardName}</ETFCard.ItemHeader>
                    <TableExportMenus
                        menuItemsProps={getDownloadOptions()}
                        exportCallback={() => exportExcel(excelTable, excelMetadata, customFields)}
                    />
                </Layout.Grid>
                <ItemSubHeader style={{ paddingLeft: '28px' }}>{subHeader}</ItemSubHeader>
                <TableContainer component={Paper}>
                    <Table aria-label='top holdings table'>
                        <TableHead>
                            <TableRow>
                                {assetClassToDataPoints[etfDetailsData.asset_class].map(
                                    (displayName: DataPointsDisplayNames, index: number) => (
                                        <TableCell
                                            key={index}
                                            sx={
                                                assetClassTodataPointNameToCellStyle[etfDetailsData.asset_class][
                                                    displayName
                                                ]
                                            }>
                                            {displayShortNames[displayName] || displayName}
                                        </TableCell>
                                    ),
                                )}
                            </TableRow>
                        </TableHead>
                        {<TableBody>{React.Children.toArray(tableRows)}</TableBody>}
                    </Table>
                </TableContainer>
                <Layout.Grid item xs={12} sx={{ paddingLeft: '28px' }}>
                    <ETFCard.TotalRowBox>
                        <Layout.Grid
                            item
                            sx={{
                                alignItems: 'center',
                            }}>
                            {totalRowText}:&nbsp;
                            {etfDataHoldingsAndExposure.current.total_weight_percentage > 1 ? (
                                <ETFInfo
                                    title={informationLabel.title}
                                    description={informationLabel.content}
                                    analyticsOpenModalCallback={() =>
                                        globalThis.analytics?.registerAction?.({
                                            action: 'open information overlay',
                                            cardName: cardName,
                                        })
                                    }
                                />
                            ) : (
                                totalRowValue
                            )}
                            <div style={{ flexGrow: 1 }} />
                        </Layout.Grid>
                    </ETFCard.TotalRowBox>
                </Layout.Grid>
                <Layout.DataAsOfDate date={asOfDate} />
            </ETFCard.ETFCard>
        </ThemeProvider>
    );
}
