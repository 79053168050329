import { useLocation, useRoutes } from 'react-router-dom';
// Pattern with child routes
import { HomeRoutes } from 'features/home';
// Pattern with no child routes
import { Fallback } from '@cfra-nextgen-frontend/shared';
import { Analytics } from 'analytics/analytics';
import ErrorBoundary from 'components/fallbacks/ErrorBoundary';
import { ENABLE_ETF_SCREENER, ENABLE_CFRA_INSIGHTS } from 'config';
import { Footer } from 'features/Footer';
import { getUserDetails } from 'features/auth/api/userDetails';
import { ETF } from 'features/etfData';
import { ScreenerRoutes } from 'features/etfScreener/routes/ScreenerRoutes';
import { MarketTrendsRoutes } from 'features/marketTrends/routes/MarketTrendsRoutes';
import { CFRAInsightsRoutes } from 'features/cfraInsights/routes/CFRAInsightsRoutes';
import { TopNavigation } from 'features/topNavigation';
import { useEffect } from 'react';
import { isLoggedIn, useAuth } from '../lib/authentication';

export default function AppRoutes() {
    const commonRoutes = [
        { path: '*', element: <HomeRoutes /> },
        { path: '/etf/ticker/:ticker/exchange/:exchange', element: <ETF /> },
        { path: '/etf/cfra-id/:cfraId', element: <ETF /> },
        { path: '/market-trends/*', element: <MarketTrendsRoutes /> },
    ];

    if (ENABLE_ETF_SCREENER) {
        commonRoutes.push({ path: '/screener/*', element: <ScreenerRoutes /> });
    }

    if (ENABLE_CFRA_INSIGHTS) {
        commonRoutes.push({ path: '/thematic/*', element: <CFRAInsightsRoutes /> });
    }

    const element = useRoutes([...commonRoutes]);
    const location = useLocation();
    const { user } = useAuth();

    useEffect(() => {
        if (user) isLoggedIn();
    }, [location, user]);

    useEffect(() => {
        globalThis.analytics = new Analytics();
    }, []);

    useEffect(() => {
        cfraData.userData = {
            name: user?.username || 'unauthorized',
        };
    }, [user?.username]);

    const userDetails = getUserDetails();

    useEffect(() => {
        userDetails.then((response) => {
            globalThis.analytics?.fillUserDetails?.(response);
        });
    }, [userDetails]);

    if (!user) return null;

    return (
        <>
            <TopNavigation />
            {/* Error Boundary Ensures We never lose the Top Navigation if an error is thrown */}
            <ErrorBoundary FallbackComponent={Fallback.ErrorFallback}>{element}</ErrorBoundary>
            <Footer />
        </>
    );
}
