import { Layout } from '@cfra-nextgen-frontend/shared';
import { useMediaQuery } from '@mui/material';
import { Page } from 'analytics/Page';
import OverviewCircle from 'assets/images/OverviewCircle.svg';
import ETFFlowsToAssetThemesAndFactors from 'features/marketTrends/components/charts/ETFFlowsToAssetThemesAndFactors';
import ETFPerformanceThemesAndFactors from 'features/marketTrends/components/charts/ETFPerformanceThemesAndFactors';
import KeyAssetClasses from './charts/KeyAssetClasses';
import SectorPerformance from './charts/SectorPerformance';
import './print.scss';

export function MarketTrendsHome() {
    const isAbove1000px = useMediaQuery('(min-width:1001px)');

    function getLayout(slot1: React.ReactNode, slot2: React.ReactNode, slot3: React.ReactNode, slot4: React.ReactNode) {
        function _getLayout(xs: number) {
            return (
                <>
                    <Layout.Grid item xs={xs}>
                        {slot1}
                    </Layout.Grid>
                    <Layout.Grid item xs={xs}>
                        {slot2}
                    </Layout.Grid>
                    <Layout.Grid item xs={xs}>
                        {slot3}
                    </Layout.Grid>
                    <Layout.Grid item xs={xs}>
                        {slot4}
                    </Layout.Grid>
                </>
            );
        }

        return (
            <Layout.Grid container spacing={3}>
                <Layout.Grid item xs={12}>
                    <Layout.ETFDivider
                        imageSource={OverviewCircle}
                        header='Overview'
                        description='Track flows and performance data across detailed market sectors and emerging themes over time, all based on CFRA’s proprietary classifications. The charts below also highlight flows and performance for major asset classes and sectors, based on popular U.S. listed exchange-traded funds used to track each.'
                    />
                </Layout.Grid>

                {isAbove1000px ? _getLayout(6) : _getLayout(12)}
            </Layout.Grid>
        );
    }

    function addStyle(styleText: string) {
        const style = document.createElement('style');
        style.textContent = styleText;
        document.head.appendChild(style);
    }

    function removePrintPageStyle() {
        Array.from(document.head.querySelectorAll('style')).forEach((style) => {
            if (style.textContent?.startsWith('@page { size:')) {
                document.head.removeChild(style);
            }
        });
    }

    const getPrintPageStyle = (sizeInPx: number) => `@page { size: ${sizeInPx}px }`;

    if (isAbove1000px) {
        removePrintPageStyle();
        addStyle(getPrintPageStyle(1400));
    } else {
        removePrintPageStyle();
        addStyle(getPrintPageStyle(780));
    }

    return (
        <Page name='market trends'>
            {getLayout(
                <ETFFlowsToAssetThemesAndFactors />,
                <ETFPerformanceThemesAndFactors />,
                <KeyAssetClasses />,
                <SectorPerformance />,
            )}
        </Page>
    );
}
