import { ETFCard, Layout } from '@cfra-nextgen-frontend/shared';
import { Sectors, ValueTypes, isCloseOrEqualToZero } from '@cfra-nextgen-frontend/shared/src/utils';
import { formatPercentages } from '@cfra-nextgen-frontend/shared/src/utils/valuesFormatter';
import { BarChart } from 'components/Chart';
import {
    addAsOfDateToHighchart,
    addLogoToHighchart,
    exportChartAsImgWithFonts,
    getExportDefaultChartOptions,
} from 'components/Chart/Export';
import { CustomExportsProps } from 'components/Chart/ExportMenus';
import { getSpacingOptions, getTooltipHTML } from 'components/Chart/Options';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { getEtfHoldingsAndExposure } from '../api/etfDetailsData';
import { ETFDetailsParams, EtfDataSectorExposure } from '../types/research';

export default function SectorExposure({
    cfraId,
    companyData,
    componentRendered,
}: {
    cfraId: string;
    companyData: ETFDetailsParams;
    componentRendered?: boolean;
}) {
    // getting UseQueryResult object with data for sectorExposure chart
    const sectorExposureDataQueryResult = getEtfHoldingsAndExposure<EtfDataSectorExposure>({
        dataType: 'sector-exposure',
        cfraId: cfraId,
    });
    // show card loading if data still loading
    if (sectorExposureDataQueryResult.isLoading) {
        return <ETFCard.ETFCard isLoading={sectorExposureDataQueryResult.isLoading} />;
    }
    const cardTitle = 'Sector Exposure';
    const subTitle = 'Current ETF holdings by GICS sector';

    // return EmptyCard if no data
    if (
        !(
            sectorExposureDataQueryResult.data &&
            sectorExposureDataQueryResult.data.sectors &&
            sectorExposureDataQueryResult.data.sectors.some((x) => Number.isFinite(x.agg_weighting))
        )
    )
        return <ETFCard.ETFEmptyCard cardLabel={cardTitle}></ETFCard.ETFEmptyCard>;
    // cut off UseQueryResult attributes, extract only sectorExposure data
    let sectorExposureData = sectorExposureDataQueryResult.data.sectors;
    // remove Other and Unclassified items with too small weighting
    sectorExposureData = sectorExposureData.filter((value) => {
        if (value.gics_name === Sectors.Other || value.gics_name === Sectors.Unclassified) {
            return !isCloseOrEqualToZero(value.agg_weighting);
        }
        return true;
    });
    // create list with sectors which present in response from API
    const availableSectors: Array<string> = sectorExposureData.map((element) => element.gics_name);
    // create a list with all sectors, which should be displayed in the chart
    const allSectors: Array<string> = [
        Sectors.CommunicationServices,
        Sectors.ConsumerDiscretionary,
        Sectors.ConsumerStaples,
        Sectors.Energy,
        Sectors.Financials,
        Sectors.HealthCare,
        Sectors.Industrials,
        Sectors.InformationTechnology,
        Sectors.Materials,
        Sectors.RealEstate,
        Sectors.Utilities,
    ];
    // detect sectors which missing in response from API, and add them to result list with 0% weighting
    allSectors.forEach((element) => {
        if (!availableSectors.includes(element))
            sectorExposureData.push({
                as_of_date: '',
                agg_weighting: 0,
                constituent_types: [],
                gics_id: 0,
                gics_name: element as Sectors,
            });
    });
    // sort output list with sectors and weighting
    sectorExposureData.sort(function (a, b) {
        const isNullOrZero = (value: number) => value === null || value === 0;
        // if weighting === 0 or no data for weighting - show sector in the the middle of the chart ordered by alphabet
        if (isNullOrZero(a.agg_weighting) && isNullOrZero(b.agg_weighting)) return a.gics_name < b.gics_name ? -1 : 1;
        // if weighting is valid number > 0 show sector in the top of the chart ordered by weighting in descending order
        return a.agg_weighting < b.agg_weighting ? 1 : -1;
    });
    // create function for setting columns names in csv export
    const columnHeaderFormatter = function (item: any, key: any) {
        if (item instanceof Highcharts.Axis && item.isXAxis) {
            return 'GICS sector';
        } else return 'Sector Exposure';
    };

    const categories: Array<string> = [];
    // fill the list with the sectors, skip Other if weighting is not a number of is equals to zero

    const categoriesData: Array<number> = [];
    // fill the list with the weighting values, skip Other if weighting is not a number of is equals to zero
    sectorExposureData.forEach((val) => {
        categoriesData.push(val.agg_weighting);
        categories.push(val.gics_name);
    });

    const tooltipFormatter = function (this: any) {
        const getFormattedValue = (unformattedValue: number | null) =>
            unformattedValue !== null && unformattedValue !== 0 ? unformattedValue.toFixed(2) : unformattedValue;

        const getOtherToolTipRow = () =>
            sectorExposureData
                .filter((value) => value.gics_name === Sectors.Other)[0]
                .constituent_types.map(
                    (value) =>
                        `<span>${value.type_name}: ${getFormattedValue(
                            formatPercentages(value.agg_weighting),
                        )}%</span>`,
                );

        return getTooltipHTML(
            this.category,
            this.category === Sectors.Other ? getOtherToolTipRow() : [`${getFormattedValue(this.y as number)}%`],
        );
    };

    const asOfDate = sectorExposureData
        .map((item) => item.as_of_date)
        .sort()
        .reverse()[0];

    const customExportsProps: CustomExportsProps = [
        {
            type: 'JPEG',
            callback: ({ chartRef }) =>
                exportSectorExposureJpegChart({
                    chartRef: chartRef.current,
                    title: cardTitle,
                    subTitle,
                    ticker: companyData.ticker,
                    asOfDate,
                }),
        },
    ];

    return (
        <ETFCard.ETFCard containerStyles={{ paddingBottom: '32px', position: 'relative' }}>
            <BarChart
                categories={categories}
                series={{ data: categoriesData }}
                columnHeaderFormatter={columnHeaderFormatter}
                title={cardTitle}
                subTitle={subTitle}
                exportFileName={`sector-exposure-chart-${companyData.ticker}-${companyData.exchange}`}
                tooltipFormatter={tooltipFormatter}
                useHTML={true}
                plotOptionsAnimations={componentRendered ? false : true}
                customExports={customExportsProps}
                exports={{
                    asOfDate: asOfDate,
                    ticker: companyData.ticker,
                    etfName: companyData.composite_name,
                    columns: new Map<string, ValueTypes>([
                        ['GICS Sector', ValueTypes.Text],
                        ['Sector Exposure', ValueTypes.Percentage],
                    ]),
                }}
            />
            <Layout.DataAsOfDate date={asOfDate} />
        </ETFCard.ETFCard>
    );
}

async function exportSectorExposureJpegChart(props: {
    chartRef: HighchartsReact.RefObject;
    title: string;
    subTitle: string;
    ticker: string;
    asOfDate: string;
}) {
    const { chartRef, title, subTitle, ticker, asOfDate } = props;
    const chart: Highcharts.Chart = chartRef.chart;

    const defaultChartOptions: Highcharts.Options = getExportDefaultChartOptions({ title, subtitle: subTitle, ticker });

    const chartOptions: Highcharts.Options = {
        ...defaultChartOptions,
        chart: {
            ...defaultChartOptions.chart,
            ...getSpacingOptions([25, 45, 40, 40]),
            height: 500,
            width: 1000,
            events: {
                load: function (this: Highcharts.Chart) {
                    addLogoToHighchart(this);
                    addAsOfDateToHighchart(this, asOfDate);
                },
            },
        },
    };

    exportChartAsImgWithFonts(chart, chartOptions);
}
