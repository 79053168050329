import { inputFontStyle } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/StyledFormLabel';
import { ThemeProvider } from '@emotion/react';
import { TextField, TextFieldProps, createTheme } from '@mui/material';
import { useRef, useState } from 'react';

type FormTextFieldProps = {
    value: string;
    valueOnFocus: string;
    onBlur: TextFieldProps['onChange'];
    onChange: TextFieldProps['onChange'];
    width: string;
    minWidth?: string;
    valuePostfix?: string;
    isError?: boolean;
};

export const inputBaseStyles = {
    padding: '5.5px 5px 4.5px !important',
    lineHeight: '20px',
};

let theme = createTheme({
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    ...inputBaseStyles,
                    ...inputFontStyle,
                },
                input: {
                    padding: '0px !important',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .Mui-error': {
                        color: 'red',
                    },
                },
            },
        },
    },
});

export function FormTextField({
    value,
    valueOnFocus,
    onBlur,
    onChange,
    width,
    minWidth,
    valuePostfix,
    isError,
}: FormTextFieldProps) {
    const [isFocusedLocal, setIsFocusedLocal] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    function handleKeyPress(event: React.KeyboardEvent) {
        if (event.key === 'Enter') {
            inputRef?.current?.blur();
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <TextField
                inputRef={inputRef}
                onKeyDown={handleKeyPress}
                inputProps={{
                    style: { fontSize: inputFontStyle.fontSize },
                }}
                onFocus={() => {
                    setIsFocusedLocal(true);
                }}
                onBlur={(e) => {
                    onBlur?.(e);
                    setIsFocusedLocal(false);
                }}
                onChange={onChange}
                value={isFocusedLocal || isError ? valueOnFocus : `${value}${valuePostfix || ''}`}
                fullWidth
                size='small'
                sx={{
                    width: width,
                    minWidth: minWidth,
                }}
                error={isError}
            />
        </ThemeProvider>
    );
}
