import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { TransparentButtonChip } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsPanelRowStyle';
import {
    compareFilters,
    getSaveFiltersFromDirtyData,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { SaveScreenContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/saveScreenerContext/Context';
import { UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';
import { Box } from '@mui/material';
import SaveIcon from 'assets/images/SaveIcon.svg';
import { useContext } from 'react';
import { getFiltersData, getFiltersReqBody } from '../api/filters';
import { getAllSavedScreen, getSavedScreenById, updateSavedScreenById } from '../api/savedScreens';

export function UpdateScreenButton(props: { cardName: string }) {
    const { filtersPostData } = useContext(FiltersModalContext);
    const {
        saveScreenState: { selectedScreen, updateScreenValue },
        saveScreenActionDispatcher,
    } = useContext(SaveScreenContext);

    const {
        chipStateManager: {
            chipState: { filtersDirtyData },
        },
    } = useContext(ResultsContext);

    const buttonText = 'Update Screen';
    const filtersDataQry = getFiltersData({ requestBody: undefined });

    const updateScreenQuery = updateSavedScreenById({
        savedItems: selectedScreen?.id,
        requestBody: updateScreenValue,
        config: {
            enabled: updateScreenValue !== undefined,
        },
    });

    const getScreenByIdQry = getSavedScreenById({ savedItems: selectedScreen?.id });
    const refetchUpdatedData = Boolean(
        updateScreenValue && updateScreenQuery && !updateScreenQuery.isLoading && updateScreenQuery.data?.data?.id,
    );

    getAllSavedScreen({
        types: [UserSavedItemsLookupID.ETFScreenerSavedScreen],
        config: { enabled: refetchUpdatedData },
    }).refetch();

    if (refetchUpdatedData) {
        refetchAllSavedScreens();
    }

    async function refetchAllSavedScreens() {
        await getScreenByIdQry.refetch();
        if (!getScreenByIdQry.isFetching && !getScreenByIdQry.isError) {
            saveScreenActionDispatcher({ type: 'UpdateScreenAndClose', payload: undefined });
        }
    }

    const handleUpdateScreenClick = () => {
        const filterData = filtersDataQry.data;

        if (filterData) {
            const screenValue = {
                data: getFiltersReqBody(filtersPostData)?.['filters']?.['values'],
                filters: getSaveFiltersFromDirtyData(filtersDirtyData, filterData),
            };

            const payload = {
                name: selectedScreen?.name,
                value: screenValue,
            };

            saveScreenActionDispatcher({ type: 'UpdateScreen', payload });
        }
    };

    if (!filtersDataQry.isLoading && !filtersDataQry.isError && filtersDataQry?.data?.data) {
        const currentAppliedFilters = getFiltersReqBody(filtersPostData)?.['filters']?.['values'] || {};
        const selectedScreenFilters = getScreenByIdQry?.data?.data?.value?.data || {};
        const isSameFilters = compareFilters(currentAppliedFilters, selectedScreenFilters);
        if (isSameFilters) {
            return <></>;
        }
    }

    return (
        <TransparentButtonChip
            key='UpdateScreenButton'
            text={buttonText}
            startIcon={<Box component='img' src={SaveIcon} alt='Update Icon' />}
            sx={{ margin: '4px 8px' }}
            onClick={() => {
                globalThis.analytics?.registerAction?.({
                    action: `click on ${buttonText}`,
                    cardName: props.cardName,
                });
                handleUpdateScreenClick();
            }}
        />
    );
}
