import { MetadataFieldDefinition } from '@cfra-nextgen-frontend/shared/src/components/types/fieldMetadata';

// eslint-disable-next-line no-template-curly-in-string
export const defaultHeaderTemplate = '${label} (${symbol}${scale})';

// eslint-disable-next-line no-template-curly-in-string
export const defaultValueTemplate = '${value}%';

export function fillTemplate({
    template_name,
    template,
    fieldMetadata,
    formattedValue,
}: {
    template_name: string;
    template: string;
    fieldMetadata: MetadataFieldDefinition;
    formattedValue?: string;
}): string {
    const templatesRegex = /\${(.+?)}/g;
    let matches: string[] = [];
    let match;
    while ((match = templatesRegex.exec(template)) !== null) {
        matches.push(match[1]);
    }
    if (matches) {
        matches.forEach((key) => {
            if (!fieldMetadata.hasOwnProperty(key) && (formattedValue === undefined || key !== 'value')) {
                throw new Error(
                    `The ${template_name} for ${fieldMetadata.label} column contains value out of metadata - "${key}".`,
                );
            }

            const valueToReplace = `$\{${key}}`;
            if (key === 'value' && formattedValue) {
                template = template.replaceAll(valueToReplace, formattedValue);
            }

            template = template.replaceAll(
                valueToReplace,
                String(fieldMetadata[key as keyof typeof fieldMetadata] || ''),
            );
        });
    }

    return template;
}
