import { Layout } from '@cfra-nextgen-frontend/shared';
import { tooltipThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import { Divider, SxProps, Tab } from '@mui/material';
import { ThemeProvider, styled } from '@mui/material/styles';
import React from 'react';

const StyledChartTabs = styled(Layout.StyledTabs)(({ theme }) => ({
    '& div.MuiTabs-scroller': {
        '& .MuiTabs-flexContainer': {
            flexWrap: 'wrap',
            '& .MuiButtonBase-root': {
                paddingTop: '12px',
                paddingBottom: '12px',
            },
        },
    },
    '& .MuiTabs-indicator': {
        height: 2,
    },
    '& .MuiTab-root': {
        '&:not(last-child)': {
            paddingLeft: '12px',
            paddingRight: '12px',
        },
        minWidth: '0px',
    },
}));

const StyledChartTab = styled(Layout.StyledTab)(({ theme }) => ({
    marginRight: '0px',
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '14px',
}));

export type ChartTabsProps = {
    currentActiveTab?: number | boolean;
    tabs: Array<string>;
    handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
    tabsStyles?: SxProps;
};

export function ChartTabs({ currentActiveTab, tabs, handleTabChange, tabsStyles }: ChartTabsProps) {
    const tabsJSX: Array<React.ReactElement> = [];
    tabs.forEach((tabName, index) => {
        const styledTab = <StyledChartTab label={tabName} value={index} key={'Tab' + index} sx={tabsStyles} />;
        if (index === 0) {
            tabsJSX.push(styledTab);
        } else {
            tabsJSX.push(
                <Tab
                    label=''
                    className='divider-tab'
                    icon={<Divider orientation='vertical' flexItem sx={{ backgroundColor: '#0B2958' }} />}
                    sx={{ paddingLeft: '0px !important', paddingRight: '0px !important' }}
                    disabled
                />,
            );
            tabsJSX.push(styledTab);
        }
    });

    return (
        <ThemeProvider theme={tooltipThemeV2}>
            <StyledChartTabs
                value={currentActiveTab === undefined ? false : currentActiveTab}
                onChange={handleTabChange}
                aria-label='widget tabs'>
                {React.Children.toArray(tabsJSX)}
            </StyledChartTabs>
        </ThemeProvider>
    );
}
