import { getSliderValidator } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/utils';
import { CommonFormComponentProps } from '@cfra-nextgen-frontend/shared/src/components/Form/types/form';
import { Box, Slider, SliderProps } from '@mui/material';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormTextField } from './FormTextField';

type FormInputSliderProps = {
    selectedValueMin: number;
    selectedValueMax: number;
    formatTextInputValue: ({ rawValue, isFocused }: { rawValue: string; isFocused?: boolean }) => string;
} & CommonFormComponentProps;

function getFormTextField({
    inputWidth,
    errorMessage,
    formatTextInputValue,
    submitHandler,
    validateSlider,
}: {
    inputWidth: number;
    errorMessage: string;
    formatTextInputValue: ({ rawValue, isFocused }: { rawValue: string; isFocused?: boolean }) => string;
    submitHandler: () => void;
    validateSlider: () => void;
}) {
    return function ({
        index,
        value,
        onChange,
        onBlur,
        valuePostfix,
    }: {
        index: number;
        value: Array<string>;
        onChange: (value: any) => void;
        onBlur: (value: any) => void;
        valuePostfix?: string;
    }) {
        const inputName = index === 0 ? 'min' : 'max';
        const inputNameIndex = errorMessage.indexOf(inputName);
        const isError = inputNameIndex > -1;
        return (
            <FormTextField
                value={isError ? value[index] : formatTextInputValue({ rawValue: value[index] })}
                valueOnFocus={
                    isError
                        ? value[index]
                        : formatTextInputValue({
                              rawValue: value[index],
                              isFocused: true,
                          })
                }
                onBlur={(e) => {
                    onBlur(e);
                    submitHandler();
                }}
                onChange={(e) => {
                    const newValues = [...value];
                    newValues[index] = e.target.value;
                    onChange(newValues);
                    validateSlider();
                }}
                width={`${inputWidth}px`}
                minWidth={`${inputWidth}px`}
                valuePostfix={valuePostfix}
                isError={isError}
            />
        );
    };
}

export const FormInputSlider = ({
    control,
    size,
    min = 0,
    max = 0,
    selectedValueMin,
    selectedValueMax,
    name,
    formatTextInputValue,
    step,
    getValues,
    setValue,
    validate,
    submitHandler,
}: FormInputSliderProps & SliderProps) => {
    if (selectedValueMin < min || selectedValueMax > max) {
        throw new Error(
            `Pointed incorrect values for slider, default values shouldn't be out of range. Default values: [${selectedValueMin}, ${selectedValueMax}], Range: [${min}, ${max}].`,
        );
    }

    const [errorMessage, setErrorMessage] = useState('');

    const sliderValidator = useMemo(() => getSliderValidator(min, max, setErrorMessage), [min, max, setErrorMessage]);

    const inputWidth = 85;

    const debouncedSubmitHandler: () => void = debounce(() => {
        submitHandler?.();
    }, 300);

    const _getFormTextField = useMemo(() => {
        const validateSlider = () => validate?.(name);
        return getFormTextField({
            inputWidth,
            errorMessage,
            formatTextInputValue,
            validateSlider,
            submitHandler: debouncedSubmitHandler,
        });
    }, [errorMessage, formatTextInputValue, validate, name, debouncedSubmitHandler]);

    const defaultValues = useMemo(() => [selectedValueMin, selectedValueMax], [selectedValueMin, selectedValueMax]);

    useEffect(() => {
        const values = getValues?.(name);
        if (!values) {
            setValue?.(name, defaultValues);
        }
    }, [defaultValues, getValues, name, setValue]);

    function getSliderValues() {
        const values = getValues?.(name);

        if (!values) {
            return defaultValues;
        }

        const minIndex = errorMessage && errorMessage.indexOf('min');
        const maxIndex = errorMessage && errorMessage.indexOf('max');

        return [
            typeof minIndex === 'number' && minIndex > -1 ? defaultValues[0] : parseFloat(String(values[0])),
            typeof maxIndex === 'number' && maxIndex > -1 ? defaultValues[1] : parseFloat(String(values[1])),
        ];
    }

    return (
        <Controller
            control={control}
            name={name}
            defaultValue={getSliderValues()}
            rules={{
                validate: sliderValidator,
            }}
            render={({ field }) => {
                const getTextField = (index: number, valuePostfix?: string) =>
                    _getFormTextField({
                        index: index,
                        value: field.value,
                        onChange: field.onChange,
                        onBlur: (e) => {
                            // if click out of empty input box, the value should be replaced with default value
                            if (e.target.value === '') {
                                const newValues = [...field.value];
                                newValues[index] = defaultValues[index];
                                field.onChange(newValues);
                            }
                            field.onBlur();
                        },
                        valuePostfix: valuePostfix,
                    });

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                        }}>
                        {getTextField(0)}
                        <Box
                            sx={{
                                width: `calc(100% - ${2 * inputWidth - 2 * 12}px)`,
                                maxWidth: '150px',
                                paddingLeft: '12px',
                                paddingRight: '12px',
                                alignItems: 'center',
                                display: 'flex',
                            }}>
                            <Slider
                                {...field}
                                onChange={(e) => {
                                    field.onChange(e);
                                    debouncedSubmitHandler();
                                }}
                                value={getSliderValues()}
                                min={min}
                                max={max}
                                step={step}
                                size={size}
                            />
                        </Box>
                        {getTextField(1)}
                    </Box>
                );
            }}
        />
    );
};
