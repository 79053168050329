import { styled } from '@mui/material';
import Tab, { TabProps } from '@mui/material/Tab';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import * as React from 'react';
import { fontFamilies } from '../../../utils';

export function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface StyledTabsProps {
    children?: React.ReactNode;
    value: number | boolean;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
        sx={{
            minHeight: 'auto',
        }}
        {...props}
        TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        height: 4,
        backgroundColor: '#4375BB',
    },
    '& div.MuiTabs-scroller': {
        '& .MuiTabs-flexContainer': {
            '& .MuiButtonBase-root': {
                minHeight: 'auto',
                paddingTop: '12px',
                paddingBottom: '12px',
            },
        },
    },
});

export const CarouselTab = styled((props: TabProps) => <Tab {...props} />)(({ theme }) => ({
    padding: 0,
    marginRight: '15px',
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '14px',
    textTransform: 'none',
    color: '#57626A',
    width: '150px',
    height: '85px',
    '&.Mui-selected': {
        color: '#3078B5',
    },
}));

export const CarouselTabs = styled((props: TabsProps) => (
    <Tabs
        sx={{
            minHeight: 'auto',
        }}
        {...props}
    />
))({
    '& .MuiTabs-scrollButtons': {
        '&.Mui-disabled': { opacity: 0.4 },
    },
});

interface StyledTabProps {
    label: string | JSX.Element;
    value: any;
    onClick?: React.MouseEventHandler;
    icon?: string | React.ReactElement;
}

export const StyledTab = styled((props: StyledTabProps) => <Tab {...props} />)(({ theme }) => ({
    padding: 0,
    marginRight: '28px',
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '15px',
    textTransform: 'none',
    color: '#0B2958',
    '&.Mui-selected': {
        color: '#3078B5',
    },
}));
