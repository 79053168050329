import { CustomViewEditorContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/customViewEditor/CustomViewEditorContext';
import { ScreenerViewContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerViewContext/Context';
import {
    ActionTypes,
    ScreenerSaveView,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerViewContext/types';
import { saveCustomView, updateCustomView } from 'features/etfScreener/api/customViews';
import { useContext, useEffect, useState } from 'react';
import { SaveModal } from './SaveModal';

export function SaveViewModal(props: { existingViews: Array<string> }) {
    const [saveView, setSaveView] = useState<ScreenerSaveView | undefined>();
    const {
        customViewEditorState: { nameOverlayMode },
        customViewEditorStateDispatcher,
    } = useContext(CustomViewEditorContext);
    const {
        screenerViewState: { screenerSaveView, screenerActiveView },
        screenerViewActionDispatcher,
    } = useContext(ScreenerViewContext);

    const saveViewResult = saveCustomView({
        requestBody: {
            name: saveView?.label,
            value: saveView?.fieldsData,
        },
        config: {
            enabled: saveView !== undefined && nameOverlayMode === 'save',
        },
    });

    // handle save view
    useEffect(() => {
        if (saveViewResult && !saveViewResult.isLoading && saveViewResult.data && saveViewResult.data.data) {
            const screenerViewActionDispatcherTypes: Array<ActionTypes> = [
                'RemoveScreenerSaveView',
                'SetRefetchAllPending',
                'SetScreenerActiveView',
            ];
            if (saveView?.isCurrentView) {
                screenerViewActionDispatcherTypes.push('RemoveScreenerCurrentView');
            }
            setSaveView(undefined);
            screenerViewActionDispatcher({
                type: screenerViewActionDispatcherTypes,
                newState: {
                    screenerActiveView: {
                        key: 'custom',
                        label: saveViewResult.data.data.name,
                        fieldsData: saveViewResult.data.data.value,
                    },
                },
            });
            customViewEditorStateDispatcher({
                type: ['CloseNameViewModal', 'CloseCustomViewEditor', 'SetResetColumnsPending'],
            });
        }
    }, [saveViewResult, customViewEditorStateDispatcher, screenerViewActionDispatcher, saveView?.isCurrentView]);

    const renameViewResult = updateCustomView({
        savedItems: saveView?.savedItemId,
        requestBody: {
            name: saveView?.label,
        },
        config: {
            enabled: saveView !== undefined && nameOverlayMode === 'rename',
        },
    });

    // handle rename view
    useEffect(() => {
        if (renameViewResult && !renameViewResult.isLoading && renameViewResult.data && renameViewResult.data.data) {
            setSaveView(undefined);
            // remove save view state and refetch renamed tab data
            const actionTypes: Array<ActionTypes> = ['RemoveScreenerSaveView', 'SetRefetchPendingForSpecificView'];
            // set new active view only if rename active view
            if (screenerActiveView?.label === screenerSaveView?.label) {
                actionTypes.push('SetScreenerActiveView');
            }
            screenerViewActionDispatcher({
                type: actionTypes,
                newState: {
                    refetchPendingType: renameViewResult.data.data.id,
                    screenerActiveView: {
                        // will apply only if SetScreenerActiveView in actionTypes
                        key: 'custom',
                        label: renameViewResult.data.data.name,
                        fieldsData: renameViewResult.data.data.value,
                    },
                },
            });
            customViewEditorStateDispatcher({
                type: ['CloseNameViewModal'],
            });
        }
    }, [
        renameViewResult,
        customViewEditorStateDispatcher,
        screenerViewActionDispatcher,
        screenerActiveView?.label,
        screenerSaveView?.label,
    ]);

    const getTitle = () => {
        switch (nameOverlayMode) {
            case 'save':
                return 'Save Custom View';
            case 'rename':
                return 'Rename View';
        }
    };

    return (
        <SaveModal
            title={getTitle()}
            value={screenerSaveView?.label}
            openModal={Boolean(nameOverlayMode)}
            callbackOnClose={() => {
                customViewEditorStateDispatcher({ type: 'CloseNameViewModal' });
            }}
            saveType='Custom View'
            onPositiveAction={(data) => {
                setSaveView({
                    label: data,
                    fieldsData: screenerSaveView?.fieldsData,
                    savedItemId: screenerSaveView?.savedItemId,
                    isCurrentView: screenerSaveView?.isCurrentView,
                });
            }}
            existingNames={props.existingViews}
            positiveBtnText={nameOverlayMode === 'rename' ? 'Update' : undefined}
        />
    );
}
