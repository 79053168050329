import { tooltipTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { ThemeProvider } from '@mui/material';
import MUIIconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

type IconButtonProps = {
    tooltipText?: string;
    onClick?: () => void;
    icon: string | React.ReactNode;
};

export function IconButton(props: IconButtonProps) {
    const { icon, tooltipText, onClick } = props;

    const IconButtonComponent = <MUIIconButton onClick={onClick}>{icon}</MUIIconButton>;

    if (tooltipText) {
        return (
            <ThemeProvider theme={tooltipTheme}>
                <Tooltip title={tooltipText}>
                    <MUIIconButton onClick={onClick}>{icon}</MUIIconButton>
                </Tooltip>
            </ThemeProvider>
        );
    }

    return IconButtonComponent;
}
