import { isNumber } from '@cfra-nextgen-frontend/shared/src/utils/valuesFormatter';

export function getSliderValidator(min: number, max: number, setErrorMessage?: (result: string) => void) {
    if (min >= max) {
        throw new Error(`Pointed invalid min or max values for slider validator: min: ${min}, max: ${max}`);
    }

    const validatorFunction = (value: any): string | boolean => {
        const minIsNumber = isNumber(String(value[0]));
        const maxIsNumber = isNumber(String(value[1]));

        let errorMessage = '';

        function errorMin() {
            if (errorMessage.indexOf('min ') === -1) {
                errorMessage += 'min ';
            }
        }

        function errorMax() {
            if (errorMessage.indexOf('max ') === -1) {
                errorMessage += 'max ';
            }
        }

        if (!minIsNumber) {
            errorMin();
        }

        if (!maxIsNumber) {
            errorMax();
        }

        if (parseFloat(value[0]) < min || parseFloat(value[0]) > max) {
            errorMin();
        }

        if (parseFloat(value[1]) > max || parseFloat(value[0]) < min) {
            errorMax();
        }

        if (parseFloat(value[0]) > parseFloat(value[1])) {
            errorMin();
            errorMax();
        }

        setErrorMessage?.(errorMessage);

        return errorMessage || true;
    };

    return validatorFunction;
}
