import CheckMark from '@cfra-nextgen-frontend/shared/src/assets/images/CheckMark.svg';
import UncheckMark from '@cfra-nextgen-frontend/shared/src/assets/images/UncheckMark.svg';
import { CommonFormComponentProps } from '@cfra-nextgen-frontend/shared/src/components/Form/types/form';
import { Switch, SwitchProps, styled } from '@mui/material';
import { Controller } from 'react-hook-form';

type FormSwitchProps = {
    defaultValue?: boolean;
} & CommonFormComponentProps;

const StyledSwitch = styled(Switch)<SwitchProps>(({ theme, checked }) => ({
    padding: 2,
    width: '44px',
    height: '26px',
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 18,
        height: 18,
        margin: -5,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        transform: `translateX(${checked ? -2 : 0}px)`,
    },
    '& .MuiSwitch-track': {
        borderRadius: 11,
        backgroundColor: checked ? '#3078B5' : '#CBD5E0',
        opacity: '1 !important',
    },
    '.MuiSwitch-thumb': {
        backgroundColor: '#FFF',
        backgroundImage: checked ? `url(${CheckMark})` : `url(${UncheckMark})`,
    },
}));

export default function FormSwitch(props: FormSwitchProps) {
    const defaultValue = Boolean(props.getValues?.(props.name) || props.defaultValue);

    return (
        <Controller
            name={props.name}
            defaultValue={defaultValue}
            control={props.control}
            render={({ field }) => {
                return (
                    <StyledSwitch
                        {...field}
                        onChange={(_, data) => {
                            field.onChange(data);
                            props.submitHandler?.();
                        }}
                        checked={field.value}
                    />
                );
            }}
        />
    );
}
