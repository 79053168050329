import { Layout, Typography, Utils } from '@cfra-nextgen-frontend/shared';
import { ETFPageContainer } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFPageContainer';
import { appTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { Breadcrumbs, Link } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { invert } from 'lodash';
import { Outlet, Link as RouterLink, useLocation } from 'react-router-dom';

export function PageWithBreadcrumbsInHeader({
    pathMapping,
    includeHeader = true,
}: {
    pathMapping: Record<string, string>;
    includeHeader?: boolean;
}) {
    pathMapping = { Home: '', ...pathMapping };
    const pathNamesMapping = invert(pathMapping);

    const pathNames = useLocation().pathname.split('/');

    let theme = createTheme(appTheme, {
        components: {
            MuiBreadcrumbs: {
                styleOverrides: {
                    root: {
                        paddingTop: '12px',
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        fontFamily: Utils.fontFamilies.GraphikMedium,
                        fontSize: '13px',
                        display: 'flex',
                        color: '#0B2958',
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        fontFamily: Utils.fontFamilies.GraphikRegular,
                        fontSize: '13px',
                        color: '#3078B5',
                        display: 'flex',
                    },
                },
            },
        },
    });

    const isScreenDisplay = useMediaQuery('screen');

    return (
        <>
            <ThemeProvider theme={theme}>
                <Layout.ETFHeaderContainer
                    shadow
                    sx={{
                        zIndex: 1100,
                        position: isScreenDisplay ? 'sticky' : 'static',
                        top: '72px',
                    }}>
                    <Breadcrumbs aria-label='breadcrumb'>
                        {pathNames.map((value: string, index: number) => {
                            const to = `/${pathNames.slice(index ? 1 : 0, index + 1).join('/')}`;
                            if (index + 1 === pathNames.length)
                                return <Typography key={index}>{pathNamesMapping[value]}</Typography>;

                            return (
                                <Link
                                    underline='hover'
                                    component={RouterLink}
                                    to={to}
                                    key={index}
                                    onClick={() =>
                                        globalThis.analytics?.registerAction?.({
                                            action: `navigation breadcrumbs : ${pathNamesMapping[
                                                value
                                            ].toLocaleLowerCase()}`,
                                        })
                                    }>
                                    {pathNamesMapping[value]}
                                </Link>
                            );
                        })}
                    </Breadcrumbs>
                    <Layout.HeaderName>
                        {includeHeader ? (
                            pathNamesMapping[pathNames[pathNames.length - 1]]
                        ) : (
                            <div style={{ height: '26px' }}></div>
                        )}
                    </Layout.HeaderName>
                </Layout.ETFHeaderContainer>
            </ThemeProvider>
            <ETFPageContainer>
                <Layout.ETFTabContainer
                    style={{
                        display: 'flex',
                        flexFlow: 'row wrap',
                        justifyContent: 'center',
                    }}>
                    <Outlet />
                </Layout.ETFTabContainer>
            </ETFPageContainer>
        </>
    );
}
