import { Action, State } from './types';

export function saveScreenReducer(state: State, action: Action): State {
    switch (action.type) {
        case 'OpenSaveScreenModal':
            return { ...state, openModal: true };
        case 'CloseSaveScreenModal':
            return { ...state, openModal: false };
        case 'SaveScreen':
            return { ...state, saveScreenValue: action.payload };
        case 'UpdateScreen':
            return { ...state, updateScreenValue: action.payload };
        case 'SaveScreenAndClose':
            return { ...state, openModal: false, saveScreenValue: action.payload };
        case 'UpdateScreenAndClose':
            return { ...state, updateScreenValue: action.payload };
        case 'SetSelectedScreen':
            return {
                ...state,
                selectedScreen: action.payload,
                selectedScreenValue: undefined,
                screenAction: 'FETCH_SCREEN',
            };
        case 'SetSelectedScreenValue':
            return { ...state, selectedScreenValue: action.payload, screenAction: 'NONE' };
        case 'ClearSelectedScreen':
            return { ...state, selectedScreen: undefined, selectedScreenValue: undefined, screenAction: 'NONE' };
    }
}
