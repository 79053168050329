import { Dispatch, createContext, useMemo, useState } from 'react';

type FiltersModalState = {
    openFiltersModal: boolean;
    setOpenFiltersModal: Dispatch<boolean>;
    filtersPostData: Object | undefined;
    setFiltersPostData: Dispatch<Object | undefined>;
};

export const FiltersModalContext = createContext<FiltersModalState>({} as FiltersModalState);

export function FiltersModalContextProvider({ children }: { children: React.ReactNode }) {
    const memorizedChildren = useMemo(() => children, [children]);
    const [openFiltersModal, setOpenFiltersModal] = useState(false);
    const [filtersPostData, setFiltersPostData] = useState<Object | undefined>(undefined);

    return (
        <FiltersModalContext.Provider
            value={{
                openFiltersModal: openFiltersModal,
                setOpenFiltersModal: setOpenFiltersModal,
                filtersPostData: filtersPostData,
                setFiltersPostData: setFiltersPostData,
            }}>
            {memorizedChildren}
        </FiltersModalContext.Provider>
    );
}
