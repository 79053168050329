import {
    Label,
    OptionLabel,
    TextContainer,
} from '@cfra-nextgen-frontend/shared/src/components/Form/AutocompletePicklist';
import { Root } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/Autocomplete';
import { StringKeyValueItemWithViewdata } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { ScrollableColumn } from '@cfra-nextgen-frontend/shared/src/components/layout/ScrollableColumn';
import {
    RowWrapperBoxCommonStyles,
    RowWrapperGridCommonStyles,
} from '@cfra-nextgen-frontend/shared/src/components/layout/ScrollableColumn/ScrollableColumn';
import type { DraggableSyntheticListeners } from '@dnd-kit/core';
import {
    Announcements,
    DndContext,
    DragOverlay,
    DropAnimation,
    KeyboardSensor,
    MeasuringStrategy,
    MouseSensor,
    ScreenReaderInstructions,
    TouchSensor,
    closestCenter,
    defaultDropAnimationSideEffects,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    AnimateLayoutChanges,
    SortableContext,
    arrayMove,
    defaultAnimateLayoutChanges,
    rectSortingStrategy,
    sortableKeyboardCoordinates,
    useSortable,
} from '@dnd-kit/sortable';
import type { Transform } from '@dnd-kit/utilities';
import { Box } from '@mui/material';
import classNames from 'classnames';
import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import './Item.scss';
import { Handle, Remove } from './components';
interface Props {
    label: string;
    options: Array<StringKeyValueItemWithViewdata>;
    setOptions: Dispatch<Array<StringKeyValueItemWithViewdata>>;
    isPinned: (id: string) => boolean;
}

const dropAnimationConfig: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
        styles: {
            active: {
                opacity: '0.5',
            },
        },
    }),
};

const screenReaderInstructions: ScreenReaderInstructions = {
    draggable: `
    To pick up a sortable item, press the space bar.
    While sorting, use the arrow keys to move the item.
    Press space again to drop the item in its new position, or press escape to cancel.
  `,
};

export function Sortable({ label, options, setOptions, isPinned }: Props) {
    const measuring = { droppable: { strategy: MeasuringStrategy.Always } };

    const [activeId, setActiveId] = useState<string | null>(null);
    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            // Disable smooth scrolling in Cypress automated tests
            scrollBehavior: 'Cypress' in window ? 'auto' : undefined,
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );
    const isFirstAnnouncement = useRef(true);
    const getIndex = (id: string) => options.map((item) => item.key).indexOf(id);
    const activeIndex = activeId ? getIndex(activeId) : -1;
    const handleRemove = (id: string) => setOptions(options.filter((item) => item.key !== id));
    const announcements: Announcements = {
        onDragStart({ active: { id } }) {
            return `Picked up sortable item ${String(id)}. Sortable item ${id} is in position ${getIndex(
                String(id),
            )} of ${options.length}`;
        },
        onDragOver({ active, over }) {
            // In this specific use-case, the picked up item's `id` is always the same as the first `over` id.
            // The first `onDragOver` event therefore doesn't need to be announced, because it is called
            // immediately after the `onDragStart` announcement and is redundant.
            if (isFirstAnnouncement.current === true) {
                isFirstAnnouncement.current = false;
                return;
            }

            if (over) {
                return `Sortable item ${active.id} was moved into position ${getIndex(String(over.id))} of ${
                    options.length
                }`;
            }

            return;
        },
        onDragEnd({ active, over }) {
            if (over) {
                return `Sortable item ${active.id} was dropped at position ${getIndex(String(over.id))} of ${
                    options.length
                }`;
            }

            return;
        },
        onDragCancel({ active: { id } }) {
            return `Sorting was cancelled. Sortable item ${id} was dropped and returned to position ${getIndex(
                String(id),
            )} of ${options.length}.`;
        },
    };

    useEffect(() => {
        if (!activeId) {
            isFirstAnnouncement.current = true;
        }
    }, [activeId]);

    return (
        <DndContext
            accessibility={{
                announcements,
                screenReaderInstructions,
            }}
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={({ active }) => {
                if (!active) {
                    return;
                }

                setActiveId(String(active.id));
            }}
            onDragEnd={({ over }) => {
                setActiveId(null);

                if (over) {
                    const overIndex = getIndex(String(over.id));
                    if (activeIndex !== overIndex) {
                        const result = arrayMove(options, activeIndex, overIndex).map((item, index) => ({
                            ...item,
                            order: index + 1,
                        }));
                        setOptions(result);
                    }
                }
            }}
            onDragCancel={() => setActiveId(null)}
            measuring={measuring}>
            <Root style={{ paddingTop: '59px', boxSizing: 'border-box' }}>
                <SortableContext items={options.map((item) => item.key)} strategy={rectSortingStrategy}>
                    <ScrollableColumn
                        label={
                            <TextContainer>
                                <Label>{label}</Label>
                            </TextContainer>
                        }>
                        {options
                            .filter((item) => isPinned(item.key))
                            .map((value) => (
                                <Item value={value.value} key={value.key} />
                            ))}
                        {options
                            .filter((item) => !isPinned(item.key))
                            .map((value, index) => {
                                return (
                                    <SortableItem
                                        key={value.key}
                                        id={value.key}
                                        value={value.value}
                                        index={index}
                                        onRemove={() => handleRemove(value.key)}
                                    />
                                );
                            })}
                    </ScrollableColumn>
                </SortableContext>
            </Root>
            {createPortal(
                <DragOverlay adjustScale={false} dropAnimation={dropAnimationConfig} zIndex={9999}>
                    {activeId ? <Item value={options[activeIndex].value} handle={true} dragOverlay /> : null}
                </DragOverlay>,
                document.body,
            )}
        </DndContext>
    );
}

interface SortableItemProps {
    id: string;
    value: string;
    index: number;
    onRemove?: () => void;
}

export function SortableItem({ id, value, index, onRemove }: SortableItemProps) {
    const animateLayoutChanges: AnimateLayoutChanges = (args) =>
        defaultAnimateLayoutChanges({ ...args, wasDragging: true });
    const { attributes, isDragging, isSorting, listeners, setNodeRef, setActivatorNodeRef, transform, transition } =
        useSortable({
            id,
            animateLayoutChanges,
        });

    return (
        <Item
            ref={setNodeRef}
            value={value}
            dragging={isDragging}
            sorting={isSorting}
            handle={true}
            handleProps={{
                ref: setActivatorNodeRef,
            }}
            index={index}
            onRemove={onRemove}
            transform={transform}
            transition={transition}
            listeners={listeners}
            data-index={index}
            data-id={id}
            {...attributes}
        />
    );
}

interface ItemProps {
    dragOverlay?: boolean;
    dragging?: boolean;
    handle?: boolean;
    handleProps?: any;
    index?: number;
    transform?: Transform | null;
    listeners?: DraggableSyntheticListeners;
    sorting?: boolean;
    transition?: string | null;
    value: React.ReactNode;
    onRemove?(): void;
}

export const Item = React.memo(
    React.forwardRef<HTMLDivElement, ItemProps>(
        (
            {
                dragOverlay,
                dragging,
                handle,
                handleProps,
                index,
                listeners,
                onRemove,
                sorting,
                transition,
                transform,
                value,
            },
            ref,
        ) => {
            useEffect(() => {
                if (!dragOverlay) {
                    return;
                }

                document.body.style.cursor = 'grabbing';

                return () => {
                    document.body.style.cursor = '';
                };
            }, [dragOverlay]);

            return (
                <Grid
                    item
                    xs={12}
                    className={classNames('Wrapper', sorting && 'sorting', dragOverlay && 'dragOverlay')}
                    sx={{
                        transition: transition,
                        '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
                        '--index': index,
                        ...RowWrapperGridCommonStyles,
                    }}
                    ref={ref}>
                    <Box
                        className={classNames(
                            'Item',
                            dragging && 'dragging',
                            handle && 'withHandle',
                            dragOverlay && 'dragOverlay',
                        )}
                        sx={{
                            ...RowWrapperBoxCommonStyles,
                            minWidth: '0px',
                            justifyContent: 'space-between',
                        }}
                        data-cypress='draggable-item'
                        {...(!handle ? listeners : undefined)}
                        tabIndex={!handle ? 0 : undefined}>
                        <TextContainer>
                            <OptionLabel>{value}</OptionLabel>
                        </TextContainer>
                        <span className={'Actions'}>
                            <Box sx={{ paddingLeft: '15px', paddingRight: '25px' }}>
                                {onRemove ? (
                                    <Remove
                                        className={'Remove'}
                                        onClick={onRemove}
                                        tooltipText={'Remove column from the view'}
                                    />
                                ) : null}
                            </Box>
                            {handle ? (
                                <Handle
                                    className={'Handle'}
                                    {...handleProps}
                                    {...listeners}
                                    tooltipText={'Drag and drop to rearrange column order'}
                                />
                            ) : null}
                        </span>
                    </Box>
                </Grid>
            );
        },
    ),
);
