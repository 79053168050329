import Axios, { AxiosRequestConfig } from 'axios';
import { syncAuth } from '../lib/authentication';
import { storage } from './storage';

type ApiOptions = {
    showQueries?: boolean;
};

const EndpointsWithResRestructured = [
    '/etf-insights/etf-data/aggregation/flows',
    '/etf-insights/etf-data/aggregation/performance',
    '/etf-insights/etf-data/category-level-2/flows',
    '/etf-insights/etf-data/etfs',
    '/etf-insights/etf-data/flows/cfra_id/',
    '/etf-insights/etf-data/performance/ticker-exchanges',
    '/etf-insights/etf-data/performance/cfra_ids',
];

function requestInterceptor(config: AxiosRequestConfig, apiOptions?: ApiOptions) {
    const token = storage.getToken();
    if (token && config.headers) {
        config.headers['Authorization'] = `${token.tokenType} ${token.idToken}`;
    }

    if (apiOptions?.showQueries) {
        const show_queries = new URLSearchParams(window.location.search).get('show_queries');
        if (show_queries === '1') {
            config.params['show_queries'] = show_queries;
        }
    }

    config.params['version'] = 'v2';
    return config;
}

export const axiosAuth = (url: string, apiOptions?: ApiOptions) => {
    const axios = Axios.create({
        baseURL: url,
        headers: {
            'Content-Type': 'application/json',
        },
        params: {},
    });

    axios.interceptors.request.use(
        (config: AxiosRequestConfig) => requestInterceptor(config, apiOptions),
        (error) => {
            return Promise.reject(error);
        },
    );

    axios.interceptors.response.use(
        (res) => {
            //below block is to be removed once DS-4629 is in prod
            if (res?.data && Array.isArray(res.data)) {
                if (res.config.url && EndpointsWithResRestructured.some((e) => res.config.url?.startsWith(e))) {
                    res.data = { data: res.data };
                }
            }
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    await syncAuth();
                    return axios(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
            return Promise.reject(err);
        },
    );

    return axios;
};
