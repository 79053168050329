import { ETFCard, ETFLinkButton, Layout } from '@cfra-nextgen-frontend/shared';
import ETFModal from '@cfra-nextgen-frontend/shared/src/components/ETFModal/index';
import React, { useCallback } from 'react';

export default function FundDetail(props: { detail: string }) {
    const getOpenModalButton = useCallback((handleOpen: () => void) => {
        return <ETFLinkButton onClick={handleOpen} text='... Read More' />;
    }, []);

    const getCardDetails = (fundDetail: string): React.ReactNode => {
        return (
            <ETFCard.ItemDescription>
                {fundDetail.length <= 500 ? (
                    fundDetail
                ) : (
                    <>
                        {fundDetail.substring(0, fundDetail.lastIndexOf(' ', 500) + 1)}
                        <ETFModal
                            getOpenComponent={getOpenModalButton}
                            title={'Fund Details'}
                            description={fundDetail}
                        />
                    </>
                )}
            </ETFCard.ItemDescription>
        );
    };

    return (
        <ETFCard.ETFCard cardLabel='Fund Details'>
            <Layout.Grid item xs={12}>
                {getCardDetails(props.detail)}
            </Layout.Grid>
        </ETFCard.ETFCard>
    );
}
