import { PageWithBreadcrumbsInHeader } from '@cfra-nextgen-frontend/shared/src/components/PageWithBreadcrumbsInHeader';
import { Page } from 'analytics/Page';
import { Route, Routes, useLocation } from 'react-router-dom';
import { CFRAInsightsHome } from '../components/CFRAInsightsHome';
import { CFRAInsight } from '../components/CFRAInsight';

export function CFRAInsightsRoutes() {
    const pathSegments = useLocation().pathname.split('/').reverse();

    const pathMapping: Record<string, string> = {
        'CFRA Insights': 'thematic',
        'CFRA Insight': pathSegments[0] !== 'thematic' ? pathSegments[0] : 'None',
    };
    return (
        <Routes>
            <Route element={<PageWithBreadcrumbsInHeader pathMapping={pathMapping} includeHeader={false} />}>
                <Route
                    path='/'
                    element={
                        <Page name='CFRA Insights'>
                            <CFRAInsightsHome />
                        </Page>
                    }
                />
                <Route
                    path={pathMapping['CFRA Insight']}
                    element={
                        <Page name='CFRA Insight'>
                            <CFRAInsight themeID={pathSegments[0]} />
                        </Page>
                    }
                />
            </Route>
        </Routes>
    );
}
