import { Layout } from '@cfra-nextgen-frontend/shared';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import { styled } from '@mui/material';

export const ResultRow = styled(Layout.Grid)(({ theme }) => ({
    flexWrap: 'nowrap',
}));

export const ResultItem = styled(Layout.Grid)(({ theme }) => ({
    '&:not(:last-of-type)': {
        paddingRight: '24px',
    },
    font: fontFamilies.GraphikRegular,
    fontSize: 14,
    color: '#57626a',
}));
