export type MarketTrendsDetailsProps = {
    label: MarketTrend;
    children?: React.ReactNode;
    isLoading?: boolean;
};

export enum MarketTrend {
    FlowsToAssetsDetails = 'Flows to Assets Details',
    PerformanceDetails = 'Performance Details',
    KeyAssetClassesDetails = 'Key Asset Class Details',
    SectorPerformanceDetails = 'Sector Performance Details',
}
