import { ETFLinkButton } from '@cfra-nextgen-frontend/shared';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { SaveScreenContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/saveScreenerContext/Context';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import { Box, styled } from '@mui/material';
import { useContext, useEffect } from 'react';
import { getSavedScreenById } from '../api/savedScreens';

type SelectedScreenProps = {
    id: number;
    name: string;
};

export function SelectedScreenContainer() {
    const {
        saveScreenState: { selectedScreen },
    } = useContext(SaveScreenContext);

    if (selectedScreen === undefined) {
        return <></>;
    }

    return <SelectedScreen id={selectedScreen.id} name={selectedScreen.name} />;
}

function SelectedScreen(props: SelectedScreenProps) {
    const { id, name } = props;

    const {
        saveScreenState: { selectedScreenValue, screenAction },
        saveScreenActionDispatcher,
    } = useContext(SaveScreenContext);

    const {
        chipStateManager: { chipStateDispatcher },
    } = useContext(ResultsContext);

    const getScreenByIdQry = getSavedScreenById({ savedItems: id });
    const selectedScreenData = getScreenByIdQry?.data?.data;

    useEffect(() => {
        if (
            !getScreenByIdQry?.isLoading &&
            !getScreenByIdQry?.isError &&
            selectedScreenValue === undefined &&
            screenAction === 'FETCH_SCREEN' &&
            selectedScreenData?.id
        ) {
            getScreenByIdQry.refetch();
            saveScreenActionDispatcher({ type: 'SetSelectedScreenValue', payload: selectedScreenData.value });
        }
    }, [getScreenByIdQry, saveScreenActionDispatcher, screenAction, selectedScreenData, selectedScreenValue]);

    function handleRemoveClick() {
        chipStateDispatcher({ type: 'ClearChips' });
        saveScreenActionDispatcher({ type: 'ClearSelectedScreen' });
    }

    const ScreenName = styled(Box)(({ theme }) => ({
        color: '#0B2958',
        fontFamily: fontFamilies.GraphikRegular,
        fontWeight: '400',
        fontSize: '16px',
        fontStyle: 'normal',
        lineHeight: 'normal',
        marginRight: '9px',
    }));

    const RemoveScreenLinkBtn = styled(ETFLinkButton)(({ theme }) => ({
        textDecorationLine: 'underline',
        color: '#3078B5',
        lineHeight: 'normal',
        fontWeight: 400,
        ':hover': {
            textDecorationLine: 'underline',
            backgroundColor: '#fff',
        },
    }));

    return (
        <Box sx={{ display: 'flex' }}>
            <ScreenName>{name}</ScreenName>
            {selectedScreenData?.id && <RemoveScreenLinkBtn onClick={handleRemoveClick} text='Remove' />}
        </Box>
    );
}
