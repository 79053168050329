import {
    Components,
    FilterMetadata,
    FiltersData,
    FiltersMetadata,
    Item,
    StringKeyValueItemWithViewdata,
} from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { SectionWithChildren } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/containers/types';
import { IMetadataFields, IViewDataFields } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import {
    applyFieldMetadataToValue,
    sliderApplyScaleToValue,
    sliderUnApplyScaleFromNumericValue,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/valueFormatters';
import { MetadataFieldDefinition, Scale } from '@cfra-nextgen-frontend/shared/src/components/types/fieldMetadata';
import { scaleCharacterToNumber, standardDateFormat } from '@cfra-nextgen-frontend/shared/src/utils';
import dayjs, { Dayjs } from 'dayjs';
import { invert, isEqual, uniq } from 'lodash';
import { ChipItem } from './types';

export function splitArrayByLevel(input: SectionWithChildren[]): SectionWithChildren[][] {
    const result: SectionWithChildren[][] = [];
    let currentArray: SectionWithChildren[] = [];

    input.forEach((sectionWithChildren, index) => {
        if (index === 0) {
            currentArray.push(input[0]);
            return;
        }

        if (sectionWithChildren.section.level === currentArray[0].section.level) {
            currentArray.push(sectionWithChildren);
        } else {
            result.push(currentArray);
            currentArray = [sectionWithChildren];
        }
    });
    result.push(currentArray);

    return result;
}

// sort section keys by sections order asc
export function sortSectionsWithChildrenAsc(
    sectionsWithChildren: Array<SectionWithChildren>,
): Array<SectionWithChildren> {
    return sectionsWithChildren.sort((item1, item2) => item1.section.order - item2.section.order);
}

// sort filter metadata keys by filter_metadata[x].sections[sectionKey].order asc
export function sortFilterMetadataKeysAsc(
    filtersMetadataKeys: Array<string>,
    filtersMetadata: FiltersMetadata,
    sectionKey: string,
): Array<string> {
    return filtersMetadataKeys.sort((key1, key2) => {
        const filterMetadata1Sections = filtersMetadata[key1 as keyof typeof filtersMetadata].sections;
        const filterMetadata2Sections = filtersMetadata[key2 as keyof typeof filtersMetadata].sections;
        const section1 = filterMetadata1Sections[sectionKey as keyof typeof filterMetadata1Sections];
        const section2 = filterMetadata2Sections[sectionKey as keyof typeof filterMetadata2Sections];
        return section1.order - section2.order;
    });
}

export function replaceAllDotsWithTabs(value: string) {
    return value.replaceAll('.', '\t');
}

export function replaceAllTabsWithDots(value: string) {
    return value.replaceAll('\t', '.');
}

export const componentToFilterDivider = '___';

type PostDataValues = Array<string | number | null>;

type PostData = {
    [key: string]: { values?: PostDataValues; excluded_values?: PostDataValues };
};

type ChipsData = Record<string, ChipItem>;

const getChipItem = (
    chipLabel: string,
    chipValues: string,
    filterStateData: Array<number | string | Item | null> | boolean | undefined,
    controlID: string,
) => {
    return {
        chip: {
            label: chipLabel,
            values: chipValues,
        },
        stateData: {
            controlID: controlID,
            values: filterStateData,
        },
    };
};

const addChipAdditionalLabels = (filtersData: FiltersData, key: string) => {
    let section = filtersData.filter_metadata[key].sections;

    return (
        Object.keys(section).reduce((label, sectionKey, index) => {
            return filtersData.section_mapping[sectionKey].name + (index > 0 ? ':' + label : '');
        }, '') + ': '
    );
};

type FilterTypeToArrayFiltersStateData = Partial<
    Record<
        Components,
        Array<{
            [key: string]: {
                filterStateData: Array<number | string | Item | Dayjs | null> | boolean | undefined;
                filtersMetadataKey: string;
            };
        }>
    >
>;

function getFilterTypeToArrayFiltersStateData(
    formState: {
        [x: string]: any;
    },
    filtersData: FiltersData,
): FilterTypeToArrayFiltersStateData {
    const filterTypeToArrayFiltersStateData: FilterTypeToArrayFiltersStateData = {};

    // group state of the form by components - slider, checkbox, autocomplete, etc.
    Object.keys(formState).forEach((formElementName) => {
        const splitFormElementName = formElementName.split(componentToFilterDivider);

        if (splitFormElementName.length < 2) {
            throw new Error(`Invalid form element name - ${formElementName}`);
        }

        const filterName = replaceAllTabsWithDots(splitFormElementName[1]);

        if (!Object.keys(filtersData.filter_metadata).includes(filterName)) {
            return;
        }

        const requestMappingValue =
            filtersData.filter_metadata[filterName as keyof typeof filtersData.filter_metadata].request_mapping;

        const filterType = splitFormElementName[0];

        if (filterTypeToArrayFiltersStateData[filterType as Components] === undefined) {
            filterTypeToArrayFiltersStateData[filterType as Components] = [];
        }

        if (!(Object.values(Components) as Array<string>).includes(filterType)) {
            throw new Error(`Unknown filter type ${filterType}`);
        }
        let filtersStateData = [];
        if (typeof requestMappingValue === 'object') {
            filtersStateData = Object.keys(requestMappingValue).map((filterKey) => ({
                [filterKey]: { filterStateData: formState[formElementName], filtersMetadataKey: filterName },
            }));
        } else {
            filtersStateData = [
                {
                    [requestMappingValue]: {
                        filterStateData: formState[formElementName],
                        filtersMetadataKey: filterName,
                    },
                },
            ];
        }
        filterTypeToArrayFiltersStateData[filterType as Components]?.push(...filtersStateData);
    });

    return filterTypeToArrayFiltersStateData;
}

export function getCombinedSliderFilterItemMetadata(filterMetadata: FilterMetadata): MetadataFieldDefinition {
    const getFormat = () => {
        switch (filterMetadata.item_metadata.symbol) {
            case '$':
                return '#0';
            case '%':
                return '0.00';
            default:
                return filterMetadata.item_metadata.format;
        }
    };

    return { ...filterMetadata.item_metadata, format: getFormat() };
}

export function getDynamicScale(rawValue: number) {
    const { h, b, ...rest } = scaleCharacterToNumber; // exclude hundreds and billions
    const valueToLetterRepresentation = invert({ ...rest });
    const dividers = Object.keys(valueToLetterRepresentation)
        .map((valueItem) => Number(valueItem))
        .filter((valueItem) => rawValue / valueItem > 1 || rawValue / valueItem < -1)
        .sort((n1, n2) => n2 - n1);

    if (dividers.length > 0) {
        return valueToLetterRepresentation[dividers[0]].toUpperCase() as Scale;
    }

    return undefined;
}

const throwMultipleReferenceError = (component: Components, key: string) => {
    throw new Error(
        `Detected ${component} component with reference to the same db field with another component - ${key}`,
    );
};

export function getPostAndChipsData(
    formState: {
        [x: string]: any;
    },
    filtersData: FiltersData,
): { postData?: PostData; chipItems: ChipsData } {
    const filterTypeToArrayFiltersStateData = getFilterTypeToArrayFiltersStateData(formState, filtersData);

    let labels = Object.values(filtersData.filter_metadata).map((metaData) => metaData.label);
    const chipItems: ChipsData = {};
    const postData: PostData = {};

    Object.keys(filterTypeToArrayFiltersStateData).forEach((filterType) => {
        const filtersStateData = filterTypeToArrayFiltersStateData[filterType as Components];

        switch (filterType) {
            case Components.Slider:
                filtersStateData?.forEach((filterStateData) => {
                    Object.keys(filterStateData).forEach((key) => {
                        if ((filterStateData[key].filterStateData as Array<number>).length === 0) {
                            return;
                        }

                        if (Object.keys(postData).includes(key)) {
                            throwMultipleReferenceError(Components.Slider, key);
                        }

                        const data = filterStateData[key];
                        const filterData = data.filterStateData as Array<number | string>;
                        const defaultRange = filtersData.filter_metadata[key].range;
                        const realRange = filtersData.data[key];

                        if (
                            !Array.isArray(filterData) ||
                            filterData.length < 2 ||
                            !Array.isArray(defaultRange) ||
                            defaultRange.length < 2
                        ) {
                            throw new Error(
                                `Invalid range or selected values for slider with db field reference to ${key}`,
                            );
                        }

                        const isDollarDataPoint = filtersData.filter_metadata[key].item_metadata.symbol === '$';
                        const isPercentageDataPoint = filtersData.filter_metadata[key].item_metadata.symbol === '%';

                        const filterDataMin = Number(filterData[0]);
                        const filterDataMax = Number(filterData[1]);

                        const sliderApplyScaleToValueLocal = (value: number) =>
                            sliderApplyScaleToValue({
                                value: value,
                                isPercentageDataPoint: isPercentageDataPoint,
                                itemMetadata: filtersData.filter_metadata[key].item_metadata,
                            });

                        const sliderUnApplyScaleFromValueLocal = (value: string) =>
                            sliderUnApplyScaleFromNumericValue(filtersData.filter_metadata[key].item_metadata, value);

                        let defaultMin = typeof realRange?.min === 'number' ? realRange?.min : defaultRange[0];
                        let defaultMax = typeof realRange?.max === 'number' ? realRange?.max : defaultRange[1];

                        // expect slider values with already applied scale for not dollar data points, so need to apply scale to default values to compare them
                        if (!isDollarDataPoint) {
                            defaultMin = sliderApplyScaleToValueLocal(defaultMin);
                            defaultMax = sliderApplyScaleToValueLocal(defaultMax);
                        }

                        let min = filterDataMin === defaultMin ? null : filterDataMin;
                        let max = filterDataMax === defaultMax ? null : filterDataMax;

                        // expect slider values with already applied scale for not dollar data points, so need to apply scale to default values to compare them
                        if (!isDollarDataPoint) {
                            min = min === null ? null : sliderUnApplyScaleFromValueLocal(String(min));
                            max = max === null ? null : sliderUnApplyScaleFromValueLocal(String(max));
                        }

                        if (!min && typeof min !== 'number' && !max && typeof max !== 'number') {
                            return;
                        }

                        // fill slider post data
                        postData[key] = {
                            values: [min, max],
                        };

                        // fill slider chips data
                        chipItems[data.filtersMetadataKey] = getChipItem(
                            filtersData.filter_metadata[data.filtersMetadataKey].label,
                            (function (item_metadata: MetadataFieldDefinition, filterData: Array<number | string>) {
                                const formatValue = (value: number | string) => {
                                    return applyFieldMetadataToValue({
                                        fieldMetadata: {
                                            ...getCombinedSliderFilterItemMetadata(
                                                filtersData.filter_metadata[data.filtersMetadataKey],
                                            ),
                                            scale:
                                                item_metadata.symbol === '$'
                                                    ? getDynamicScale(Number(value))
                                                    : undefined,
                                            // eslint-disable-next-line no-template-curly-in-string
                                            value_template: '${value} ${scale}${symbol}',
                                        },
                                        rawValue: value,
                                    });
                                };

                                return `${formatValue(filterData[0])} to ${formatValue(filterData[1])}`;
                            })(filtersData.filter_metadata[data.filtersMetadataKey].item_metadata, filterData),
                            data.filterStateData as (number | null)[] | undefined,
                            `${filterType}${componentToFilterDivider}${replaceAllDotsWithTabs(
                                data.filtersMetadataKey,
                            )}`,
                        );

                        if (
                            labels.filter((label) => label === chipItems[data.filtersMetadataKey].chip.label).length > 1
                        ) {
                            chipItems[data.filtersMetadataKey].chip.additionalLabels = addChipAdditionalLabels(
                                filtersData,
                                key,
                            );
                        }
                    });
                });
                break;
            case Components.DateRangePicker:
                filtersStateData?.forEach((filterStateData) => {
                    Object.keys(filterStateData).forEach((key) => {
                        const values = (filterStateData[key].filterStateData as Array<Dayjs | null | undefined>).map(
                            (value) => {
                                if (value) {
                                    return (value instanceof dayjs ? value : dayjs(value)).format(standardDateFormat)
                                }
                                return null
                            },
                        );

                        if (values.filter((value) => value !== null).length > 0) {
                            // fill date range picker post data
                            postData[key] = {
                                values: values,
                            };

                            // fill date range picker chips data
                            let metaDataKey = filterStateData[key].filtersMetadataKey;

                            chipItems[metaDataKey] = getChipItem(
                                filtersData.filter_metadata[metaDataKey].label,
                                values.filter((value) => value !== null).join(' to '),
                                values as Array<string>,
                                `${filterType}${componentToFilterDivider}${replaceAllDotsWithTabs(metaDataKey)}`,
                            );
                        }
                    });
                });
                break;
            case Components.Checkbox:
            case Components.Switch:
                filtersStateData?.forEach((filterStateData) => {
                    Object.keys(filterStateData).forEach((key) => {
                        if (filterStateData[key].filterStateData === true) {
                            if (!Object.keys(postData).includes(key)) {
                                postData[key] = {};
                            }
                            const requestMappingValue =
                                filtersData.filter_metadata[filterStateData[key].filtersMetadataKey].request_mapping;
                            if (typeof requestMappingValue === 'object') {
                                Object.keys(requestMappingValue).forEach((filterKey) => {
                                    if (!postData[filterKey]) {
                                        postData[filterKey] = {};
                                    }

                                    const { values, excluded_values } = requestMappingValue[filterKey]['request_value'];

                                    if (values) {
                                        postData[filterKey]['values'] = uniq([
                                            ...(postData[filterKey]['values'] || []),
                                            ...values,
                                        ]);
                                    }
                                    if (excluded_values) {
                                        postData[filterKey]['excluded_values'] = uniq([
                                            ...(postData[filterKey]['excluded_values'] || []),
                                            ...excluded_values,
                                        ]);
                                    }
                                });
                            }

                            const requestValue =
                                filtersData.filter_metadata[filterStateData[key].filtersMetadataKey].request_value;

                            // fill checkbox and switch post data values
                            if (requestValue && Object.keys(requestValue).includes('value') && requestValue.value) {
                                postData[key] = { values: [], ...postData[key] };
                                if (!postData[key].values?.includes(requestValue.value)) {
                                    postData[key].values?.push(requestValue.value);
                                }
                            }

                            // fill checkbox and switch post data excluded values
                            if (
                                requestValue &&
                                Object.keys(requestValue).includes('excluded_value') &&
                                requestValue.excluded_value
                            ) {
                                postData[key] = { excluded_values: [], ...postData[key] };
                                if (!postData[key].excluded_values?.includes(requestValue.excluded_value)) {
                                    postData[key].excluded_values?.push(requestValue.excluded_value);
                                }
                            }

                            // fill checkbox and switch chips data
                            let metaDataKey = filterStateData[key].filtersMetadataKey;

                            chipItems[metaDataKey] = getChipItem(
                                filtersData.filter_metadata[metaDataKey].label,
                                '',
                                filterStateData[key].filterStateData as boolean | undefined,
                                `${filterType}${componentToFilterDivider}${replaceAllDotsWithTabs(metaDataKey)}`,
                            );
                        }
                    });
                });
                break;
            case Components.Autocomplete:
            case Components.AutocompleteSearchTable:
                filtersStateData?.forEach((filterStateData) => {
                    Object.keys(filterStateData).forEach((key) => {
                        const data = filterStateData[key];
                        if ((data.filterStateData as Array<Item>).length === 0 || !data.filterStateData) {
                            return;
                        }

                        if (!Object.keys(postData).includes(key)) {
                            postData[key] = {};
                        }

                        postData[key] = { values: [], ...postData[key] };

                        // fill autocomplete post data values
                        postData[key].values = postData[key].values?.concat(
                            (data.filterStateData as Array<Item>).map((item) => item.key),
                        );

                        // fill autocomplete chips data
                        (data.filterStateData as Array<Item>).reduce((chips, state) => {
                            chipItems[data.filtersMetadataKey + state.key] = getChipItem(
                                filtersData.filter_metadata[data.filtersMetadataKey].label,
                                state.value,
                                [state],
                                `${filterType}${componentToFilterDivider}${replaceAllDotsWithTabs(
                                    data.filtersMetadataKey,
                                )}`,
                            );

                            return chips;
                        }, chipItems);
                    });
                });
                break;
        }
    });

    return {
        postData: Object.keys(postData).length > 0 ? postData : undefined,
        chipItems: chipItems,
    };
}

const getViewObj = (key: string, viewdataFields: Array<IViewDataFields>) => {
    return viewdataFields.filter((field) => {
        const viewObjKeys = Object.keys(field);

        if (viewObjKeys.length === 0) {
            return false;
        }

        return viewObjKeys[0] === key;
    });
};

export function sortMetadataFieldsByViewOrder(
    metadataFields: Array<IMetadataFields>,
    viewdataFields: Array<IViewDataFields>,
): Array<IMetadataFields> {
    return metadataFields.sort((value1, value2) => {
        const metadataObjKeys1 = Object.keys(value1);
        const metadataObjKeys2 = Object.keys(value2);

        if (metadataObjKeys1.length === 0 || metadataObjKeys2.length === 0) {
            throw new Error('metadataObjKeys1 or metadataObjKeys2 is empty.');
        }

        let key1 = metadataObjKeys1[0];
        let key2 = metadataObjKeys2[0];
        const viewObj1 = getViewObj(key1, viewdataFields);
        const viewObj2 = getViewObj(key2, viewdataFields);

        if (viewObj1.length === 0 || viewObj2.length === 0) {
            throw new Error("Can't find order value for viewObj1 or viewObj2");
        }

        const viewObj1First = viewObj1[0];
        const viewObj2First = viewObj2[0];

        return viewObj1First[Object.keys(viewObj1First)[0]].order - viewObj2First[Object.keys(viewObj2First)[0]].order;
    });
}

export function extractOrExcludeDefaultSelectedColumns(
    exclude: boolean, // if true exclude default selected, otherwise extract default selected
    metadataFields: Array<IMetadataFields>,
    viewdataFields: Array<IViewDataFields>,
): Array<IMetadataFields> {
    return metadataFields.filter((value1) => {
        const metadataObjKeys = Object.keys(value1);

        if (metadataObjKeys.length === 0) {
            throw new Error('metadataObjKeys is empty.');
        }

        const viewObj = getViewObj(metadataObjKeys[0], viewdataFields);

        if (viewObj.length === 0) {
            throw new Error("Can't find order value for viewObj");
        }

        const viewObj1First = viewObj[0];
        const defaultSelected = viewObj1First[Object.keys(viewObj1First)[0]].default_selected;

        return exclude ? !defaultSelected : defaultSelected;
    });
}

export function sortByOrder(items: Array<StringKeyValueItemWithViewdata>) {
    return items.sort((obj1, obj2) => obj1.order - obj2.order);
}

export function handleDefaultSelected(mode: 'exclude' | 'extract', items: Array<StringKeyValueItemWithViewdata>) {
    return items.filter((item) => (mode === 'exclude' ? !item.default_selected : item.default_selected));
}

export function getViewDataForColumn(key: string, viewdataFields: Array<IViewDataFields>) {
    const viewObj = getViewObj(key, viewdataFields);

    if (viewObj.length === 0) {
        throw new Error("Can't find order value for viewObj");
    }

    const viewObj1First = viewObj[0];
    const viewObj1FirstData = viewObj1First[Object.keys(viewObj1First)[0]];

    return viewObj1FirstData;
}

export function formatSavedFilterToDirtyData(savedFilters: { [x: string]: any }, filtersData: FiltersData) {
    type ExtendedComponents = Components | 'dropdown';
    let dirtyData: any = {};

    Object.keys(savedFilters).forEach((filterKey) => {
        const filterMetadata = filtersData.filter_metadata[filterKey];
        if (filterMetadata) {

            let filterType = filterMetadata.component as ExtendedComponents;
            if (filterType == 'dropdown') {
                filterType = Components.Autocomplete;
            }

            const dirtyFieldKey = `${filterType}${componentToFilterDivider}${replaceAllDotsWithTabs(filterKey)}`;

            if ([Components.Autocomplete, Components.AutocompletePicklist, Components.AutocompleteSearchTable]
                .includes(filterType as Components)) {

                const dropdownOptions = filtersData.data[filterKey]?.items;

                if (Array.isArray(dropdownOptions) && dropdownOptions.length > 0) {
                    dirtyData[dirtyFieldKey] = savedFilters[filterKey]
                        .reduce((selectedOptions: any, curr: { [x: string]: any }) => {
                            const matchedItem = dropdownOptions.find((item) => item.key === curr['key']);
                            if (matchedItem) {
                                selectedOptions.push(matchedItem);
                            }
                            return selectedOptions;
                        }, []);
                }

            } else if ([Components.DateRangePicker].includes(filterType as Components)) {
                dirtyData[dirtyFieldKey] = (savedFilters[filterKey] as Array<Dayjs | null | undefined>)
                    .map((value) => value ? dayjs(value) : null);
            } else if ([Components.Checkbox, Components.Switch, Components.Slider].includes(filterType as Components)) {
                dirtyData[dirtyFieldKey] = savedFilters[filterKey];
            }
        }
    });
    return dirtyData;
}

export function getSaveFiltersFromDirtyData(dirtyFields: { [x: string]: any }, filtersData: FiltersData) {
    let saveFilters: { [x: string]: any } = {}

    Object.keys(dirtyFields).forEach((dirtyFilterKey) => {
        if (dirtyFields[dirtyFilterKey as keyof typeof dirtyFields]) {
            const splitFormElementName = dirtyFilterKey.split(componentToFilterDivider);
            if (splitFormElementName.length < 2) {
                throw new Error(`Invalid form element name - ${dirtyFilterKey}`);
            }

            let [filterType, filterKey] = splitFormElementName;
            filterKey = replaceAllTabsWithDots(filterKey);

            if (!Object.keys(filtersData.filter_metadata).includes(filterKey)) {
                throw new Error(`Unknown filter type ${filterKey}`);
            }

            if (!(Object.values(Components) as Array<string>).includes(filterType)) {
                throw new Error(`Unknown filter type ${filterType}`);
            }


            if ([Components.Autocomplete, Components.AutocompletePicklist, Components.AutocompleteSearchTable]
                .includes(filterType as Components)) {

                const values = dirtyFields[dirtyFilterKey].map((option: any) => ({ key: option['key'] }));

                if (values.length > 0) {
                    saveFilters[filterKey] = values;
                }
            } else if ([Components.DateRangePicker].includes(filterType as Components)) {
                saveFilters[filterKey] = (dirtyFields[dirtyFilterKey] as Array<Dayjs | null | undefined>)
                    .map((value) => value ? (value instanceof dayjs ? value : dayjs(value)).format(standardDateFormat) : null);
            } else if ([Components.Checkbox, Components.Switch, Components.Slider].includes(filterType as Components)) {
                saveFilters[filterKey] = dirtyFields[dirtyFilterKey];
            }
        }
    })
    return saveFilters
}

export function compareFilters(filter1: { [x: string]: any }, filter2: { [x: string]: any }) {
    try {
        const sortFilterArrayValues = (inputObj: any) => {
            let obj = inputObj;
            for (const key in obj) {
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    sortFilterArrayValues(obj[key]);

                    if (Array.isArray(obj[key])) {
                        obj[key] = obj[key].sort();
                    } else if (typeof obj[key] === 'object') {
                        for (const nestedKey in obj[key]) {
                            if (Array.isArray(obj[key][nestedKey])) {
                                obj[key][nestedKey] = obj[key][nestedKey].sort();
                            }
                        }
                    }
                }
            }
            return obj;
        }

        const sortedFilter1 = sortFilterArrayValues(filter1);
        const sortedFilter2 = sortFilterArrayValues(filter2);
        return isEqual(sortedFilter1, sortedFilter2);
    } catch (error) {
        return false
    }
}