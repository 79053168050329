import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { FiltersContainer } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/containers/FilterContainers';
import { LevelToComponentDetails } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/containers/mappings';
import { SectionWithChildren } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/containers/types';
import { FilterComponentNameToComponent } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/filters/mappings';
import {
    sortFilterMetadataKeysAsc,
    sortSectionsWithChildrenAsc,
    splitArrayByLevel,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import React from 'react';
import { Control, FieldValues, UseFormGetValues, UseFormSetValue } from 'react-hook-form';

// starts with no parentSectionKey (for root section), and than determine current section using parentSectionKey
export function getFiltersReactNodes({
    filtersData,
    control,
    parentSectionKey,
    analyticsCardName,
    getValues,
    submitHandler,
    validate,
    setValue,
}: {
    filtersData: FiltersData;
    control: Control<any>;
    analyticsCardName: string;
    parentSectionKey?: string;
    getValues: UseFormGetValues<FieldValues>;
    validate: (fieldName: string) => Promise<boolean | undefined>;
    submitHandler: () => void;
    setValue: UseFormSetValue<FieldValues>;
}): Array<React.ReactNode> {
    const resultNodes: Array<React.ReactNode> = [];

    const currentLevelSectionKeys = parentSectionKey
        ? Object.keys(filtersData.section_mapping).filter(
              (key) => filtersData.section_mapping[key].parent === parentSectionKey,
          )
        : Object.keys(filtersData.section_mapping).filter(
              (key) => !filtersData.section_mapping[key].hasOwnProperty('parent'),
          );

    const sectionsWithChildren: Array<SectionWithChildren> = currentLevelSectionKeys.map((currentLevelSectionKey) => ({
        section: filtersData.section_mapping[currentLevelSectionKey],
        sectionKey: currentLevelSectionKey,
        // move to next section
        children: getFiltersReactNodes({
            filtersData: filtersData,
            getValues: getValues,
            validate: validate,
            control: control,
            parentSectionKey: currentLevelSectionKey,
            analyticsCardName: analyticsCardName,
            submitHandler: submitHandler,
            setValue: setValue,
        }),
    }));
    // handle cases with multiple level components on the same nesting level
    splitArrayByLevel(sectionsWithChildren).forEach((sectionsWithChildren) => {
        if (sectionsWithChildren.length > 0) {
            const level = sectionsWithChildren[0].section.level;
            if (!LevelToComponentDetails.hasOwnProperty(level)) {
                throw new Error(`Invalid level value inside sections mapping - "${level}"`);
            }

            const Component = LevelToComponentDetails[level];

            resultNodes.push(
                <Component
                    key={resultNodes.length}
                    sectionsWithChildren={sortSectionsWithChildrenAsc(sectionsWithChildren)}
                    parentSection={parentSectionKey ? filtersData.section_mapping[parentSectionKey] : undefined}
                    analyticsCardName={analyticsCardName}
                />,
            );
        }
    });

    // assume to insert filters after sections in case we have both filters and sections inside section
    // add filters
    if (!parentSectionKey) {
        // don't add any filters if in sections root
        return resultNodes;
    }

    const currentSectionFilterMetadataKeys = Object.keys(filtersData.filter_metadata).filter((filterMetadataKey) =>
        Object.keys(filtersData.filter_metadata[filterMetadataKey].sections).includes(parentSectionKey),
    );

    // order by filterMetadataItem.sections[parentSectionKey].order
    const orderedCurrentSectionFilterMetadataKeys = sortFilterMetadataKeysAsc(
        currentSectionFilterMetadataKeys,
        filtersData.filter_metadata,
        parentSectionKey,
    );

    const containers = filtersData.section_mapping[parentSectionKey].containers;

    function getFilterReactNode(filterMetadataKey: string, filterIndex: number, containerKey?: string) {
        const filterMetadata = filtersData.filter_metadata[filterMetadataKey];
        const section = filterMetadata.sections[parentSectionKey!];

        if (containerKey && containerKey !== section.container) {
            return null;
        }

        const component = filterMetadata.component;
        if (!FilterComponentNameToComponent.hasOwnProperty(component)) {
            throw new Error(`No component with name "${component}" in the FilterComponentNameToComponent.`);
        }
        const Filter = FilterComponentNameToComponent[component];
        // add the bottom level section with filters inside of it
        return (
            <Filter
                control={control}
                getValues={getValues}
                submitHandler={submitHandler}
                validate={validate}
                key={filterIndex}
                filtersData={filtersData}
                parentSectionKey={parentSectionKey!}
                filterMetadataKey={filterMetadataKey}
                layoutProps={section.layout}
                setValue={setValue}
            />
        );
    }

    if (!containers || Object.keys(containers).length === 0) {
        orderedCurrentSectionFilterMetadataKeys.forEach((filterMetadataKey) => {
            const filterReactNode = getFilterReactNode(filterMetadataKey, resultNodes.length);
            if (!filterReactNode) {
                return;
            }
            resultNodes.push(filterReactNode);
        });
    } else {
        Object.keys(containers)
            .sort((key1, key2) => {
                const result = Number(key1) - Number(key2);
                if (Number.isNaN(result)) {
                    throw new Error(`Found invalid container key in section_mapping - ${key1} or ${key2}`);
                }
                return result;
            })
            .forEach((containerKey) => {
                const currentContainer = containers[containerKey];
                const filtersReactNodes: Array<React.ReactNode> = [];

                orderedCurrentSectionFilterMetadataKeys.forEach((filterMetadataKey, index) => {
                    filtersReactNodes.push(getFilterReactNode(filterMetadataKey, index, containerKey));
                });

                resultNodes.push(
                    <FiltersContainer key={resultNodes.length} {...currentContainer.layout}>
                        <>{React.Children.toArray(filtersReactNodes.filter((node) => Boolean(node)))}</>
                    </FiltersContainer>,
                );
            });
    }

    return resultNodes;
}
