import React, { useEffect } from 'react';

type PageProps = AnalyticsPageProps & {
    children?: React.ReactNode;
    clearPreviousSection?: boolean;
};

export const Page = ({ children, clearPreviousSection, ...props }: PageProps) => {
    useEffect(() => {
        globalThis.analytics?.registerPageView?.(props, clearPreviousSection);
    }, [props, clearPreviousSection]);

    return <>{children}</>;
};
