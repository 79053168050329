import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import { getFontsBase64String } from './base64';

export function createSvgNode(tag: string, attrs?: { [key: string]: string }): SVGElement {
    const element: SVGElement = document.createElementNS('http://www.w3.org/2000/svg', tag);
    for (const k in attrs) {
        if (attrs.hasOwnProperty(k)) {
            element.setAttribute(k, attrs[k]);
        }
    }
    return element;
}

export function createTextNode(text: string, attrs?: { [key: string]: string }): SVGElement {
    const textNode = createSvgNode('text', attrs);
    textNode.textContent = text;
    return textNode;
}

export async function addFontsToSvg(svgString: string, fonts: string[]) {
    try {
        //fetch fontfiles
        const fontBase64 = await getFontsBase64String(fonts);

        //add font-family support to svg
        if (fontBase64) {
            const svg = new DOMParser().parseFromString(svgString, 'image/svg+xml').documentElement;
            const defs = svg.querySelector('defs') || createSvgNode('defs');

            const style = createSvgNode('style');
            style.textContent = `
                        @font-face {
                            font-family: ${fontFamilies.GraphikRegular};
                            src: url('${fontBase64[0]}') format('woff2'),
                                url('${fontBase64[1]}') format('woff');
                        }
                        @font-face {
                            font-family: ${fontFamilies.GraphikMedium};
                            src: url('${fontBase64[2]}') format('woff2'),
                                url('${fontBase64[3]}') format('woff');
                        }`;
            defs.appendChild(style);
            svg.appendChild(defs);
            return svg.outerHTML;
        }

        return svgString;
    } catch {
        console.log('something went wrong while adding font to svg');
        return svgString;
    }
}
