import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import styled from '@emotion/styled';
import { Paper } from '@mui/material';

export const ItemAsOf = styled(Paper)(() => ({
    paddingTop: '16px',
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '11px',
    color: '#76818C',
    boxShadow: 'none',
    lineHeight: '1',
}));

export const AsteriskItemAsOf = styled(ItemAsOf)(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
}));
