import { ETFDownloadButton, ETFMenu } from '@cfra-nextgen-frontend/shared';
import { ValueTypes } from '@cfra-nextgen-frontend/shared/src/utils/valuesFormatter';
import HighchartsReact from 'highcharts-react-official';
import * as React from 'react';
import { MultipleExport, exportChart, exportMultipleCharts } from './Export';

export type CustomExportsProps = {
    type: string;
    callback: (props?: any) => void;
}[];

type ExportMenusProps = {
    chartRef: React.RefObject<HighchartsReact.RefObject | null>;
    analyticsCallback?: (type: string) => void;
    customExports?: CustomExportsProps;
    headers?: {
        title?: string;
        asOfDate?: string;
        ticker?: string;
        etfName?: string;
        columns?: Map<string, ValueTypes>;
    };
};

export const getMenuItemName = (format: string) => {
    switch (format.toLocaleLowerCase()) {
        case 'csv':
        case 'xlsx':
            return 'Download Data';
        default:
            return `Download ${format}`;
    }
};

const ExportMenusBase = ({
    menuItemsProps,
    useOriginalItemsNames,
    analyticsCallback,
}: {
    menuItemsProps: Map<string, () => void>;
    useOriginalItemsNames?: boolean;
    analyticsCallback?: (type: string) => void;
}) => {
    const menuItems: Array<ETFMenu.MenuItemProps> = [];
    menuItemsProps.forEach((callback, item) => {
        menuItems.push({
            itemName: useOriginalItemsNames ? item : getMenuItemName(item),
            callback: () => {
                callback();
                analyticsCallback?.(item);
            },
        });
    });
    return (
        <ETFMenu.ETFMenu menuItems={menuItems}>
            <ETFDownloadButton />
        </ETFMenu.ETFMenu>
    );
};

export default function ExportMenus(exportMenusProps: ExportMenusProps) {
    const defaultExportTypes = ['XLSX', 'JPEG'];
    const menuItemsProps = new Map<string, () => void>();

    defaultExportTypes.forEach((type) =>
        menuItemsProps.set(type, () =>
            exportChart({
                chartRef: exportMenusProps.chartRef,
                type: type.toLocaleLowerCase(),
                headers: exportMenusProps.headers,
            }),
        ),
    );

    exportMenusProps.customExports?.forEach((_export) => {
        menuItemsProps.set(_export.type, () =>
            _export.callback({ chartRef: exportMenusProps.chartRef, type: _export.type.toLocaleLowerCase() }),
        );
    });

    return ExportMenusBase({ menuItemsProps: menuItemsProps, analyticsCallback: exportMenusProps.analyticsCallback });
}

export function ExportMenusForMultipleCharts({
    multipleExport,
    analyticsCallback,
    customExports,
}: {
    multipleExport: MultipleExport;
    analyticsCallback?: (type: string) => void;
    customExports?: CustomExportsProps;
}) {
    const defaultExportTypes = ['XLSX', 'JPEG'];
    const menuItemsProps = new Map<string, () => void>();

    defaultExportTypes.forEach((type) =>
        menuItemsProps.set(type, () =>
            exportMultipleCharts({ multipleExport: multipleExport, type: type.toLocaleLowerCase() }),
        ),
    );

    customExports?.forEach((_export) => {
        menuItemsProps.set(_export.type, () => _export.callback());
    });

    return ExportMenusBase({ menuItemsProps, analyticsCallback });
}

export function TableExportMenus({
    exportCallback,
    menuItemsProps,
    analyticsCallback,
}: {
    exportCallback: () => void;
    menuItemsProps?: Map<string, () => void>;
    analyticsCallback?: (type: string) => void;
}) {
    const useOriginalItemsNames = Boolean(menuItemsProps);

    if (!menuItemsProps) {
        menuItemsProps = new Map<string, () => void>([['XLSX', exportCallback]]);
    }

    return ExportMenusBase({ menuItemsProps, useOriginalItemsNames, analyticsCallback });
}
