import { ETFCard } from '@cfra-nextgen-frontend/shared';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout/Grid';
import { LayoutProps } from '@cfra-nextgen-frontend/shared/src/components/layout/types/types';
import { scrollbarThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { Box } from '@mui/material';
import React from 'react';
import { ContainerProps } from './types';

export const FiltersSection: React.FC<ContainerProps> = (props) => {
    return (
        <>
            <Box
                sx={{
                    flexGrow: 1,
                    overflow: 'auto',
                    paddingBottom: '3px',
                    ...scrollbarThemeV2,
                }}>
                {React.Children.toArray(
                    props.sectionsWithChildren?.map((sectionWithChildren, index) => {
                        return (
                            <Grid
                                item
                                container
                                sx={{ paddingTop: '12px' }}
                                key={index}
                                {...sectionWithChildren.section.layout}>
                                <ETFCard.ItemHeader
                                    sx={{
                                        width: '100%',
                                        paddingBottom: '12px',
                                        paddingLeft: '30px',
                                        boxShadow: 'none',
                                        fontSize: '14px',
                                        color: '#0B2958',
                                        borderBottom: '1px solid #EDF0F6',
                                    }}>
                                    {sectionWithChildren.section.name}
                                </ETFCard.ItemHeader>
                                <Grid item container xs={12}>
                                    {sectionWithChildren.children}
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            borderBottom: '1px solid #EDF0F6',
                                            marginTop: '-1px',
                                        }}></Grid>
                                </Grid>
                            </Grid>
                        );
                    }),
                )}
            </Box>
        </>
    );
};

type FiltersContainerProps = {
    children: React.ReactNode;
} & LayoutProps;

export const FiltersContainer: React.FC<FiltersContainerProps> = ({ children, ...rest }) => {
    return (
        <>
            <Grid item {...rest}>
                {children}
            </Grid>
            <Grid item xs={12} sx={{ borderBottom: '1px solid #EDF0F6', marginTop: '-1px' }}></Grid>
        </>
    );
};
