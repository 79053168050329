export const getSumOfAllValues = (array: Array<number | null>) => {
    let result = 0;
    array.forEach((value) => {
        if (value !== null) result += value;
    });
    return result;
};

export function sortByDateString(key: string, direction: 'asc' | 'desc') {
    const validDirections = ['asc', 'desc'];

    if (!validDirections.includes(direction)) {
        throw new Error(`Invalid direction: ${direction}. Use 'asc' or 'desc'.`);
    }

    return (a: any, b: any) => {
        const dateA = new Date(a[key]).getTime();
        const dateB = new Date(b[key]).getTime();

        return direction === 'asc' ? dateA - dateB : dateB - dateA;
    };
}
