import { ETFCard } from '@cfra-nextgen-frontend/shared';
import { ValueTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { Box } from '@mui/material';
import { getDownloadAction } from 'analytics/utils';
import { ColumnChartOptions } from 'components/Chart/Options';
import Highcharts, { TooltipFormatterContextObject } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_rounded from 'highcharts-rounded-corners-fixed-negative';
import Boost from 'highcharts/modules/boost';
import BoostCanvas from 'highcharts/modules/boost-canvas';
import exportdata from 'highcharts/modules/export-data';
import exporting from 'highcharts/modules/exporting';
import * as React from 'react';
import { ChartDescription, ChartTitle } from './Chart';
import ExportMenus, { CustomExportsProps } from './ExportMenus';

exporting(Highcharts);
exportdata(Highcharts);
HC_rounded(Highcharts);
Boost(Highcharts);
BoostCanvas(Highcharts);

const primaryChartColors = ['#002b5a', '#007bb8', '#5f9ea0', '#815493', '#366691', '#6fa6c8', '#78b7bf', '#5a0058'];

const secondaryChartColors = [
    '#002b5a',
    '#366691',
    '#6fa6c8',
    '#007bb8',
    '#7399d1',
    '#b0bae8',
    '#ad98cb',
    '#815493',
    '#5a0058',
    '#94d1df',
    '#78b7bf',
    '#5f9ea0',
    '#b2eaff',
    '#e2deff',
];

type HistoricalChartProps = {
    title: string;
    series: any[];
    categories: string[];
    subTitle?: string;
    useHTML?: boolean;
    tooltipFormatter?: (this: TooltipFormatterContextObject) => string;
    exportingFileName: string;
    plotOptionsAnimations?: boolean;
    customExports?: CustomExportsProps;
    exports: {
        asOfDate: string;
        ticker: string;
        etfName: string;
        columns: Map<string, ValueTypes>;
    };
};

export function getUniqueKeys(data: any[], key: string) {
    return Array.from(new Set(data.map((item) => item[key]))).sort();
}

function groupValueBy(data: any[], by: string[], value: string) {
    let grouped = {};
    data.forEach(function (a: any) {
        by.reduce(function (o: any, g, i) {
            // take existing object,
            o[a[g]] = o[a[g]] || (i + 1 === by.length ? [] : {}); // or generate new obj, or
            return o[a[g]]; // at last, then an array
        }, grouped).push(a[value]);
    });

    return sortDict(grouped);
}

function fillEmptyGroups(groupedData: any, groups: string[]) {
    for (const [key] of Object.entries(groupedData)) {
        groups.forEach((group) => {
            groupedData[key][group] = groupedData[key][group] || [null];
        });
    }
}

function sortDict(unordered: any) {
    return Object.keys(unordered)
        .sort()
        .reduce((obj: any, key) => {
            obj[key] = unordered[key];
            return obj;
        }, {});
}

function convertToSeries(groupedData: any) {
    const data = Object.entries(groupedData);
    const seriesColor = data.length <= primaryChartColors.length ? primaryChartColors : secondaryChartColors;

    return data.map(([key, data], index) => {
        // if key is null, it means Other
        return {
            name: key,
            data: Object.values(data as Object).flat(),
            dataGrouping: {
                enabled: false,
            },
            color: index < seriesColor.length ? seriesColor[index] : undefined,
            legendIndex: index,
            ////////////////////////////////////////////////////////////////////
            // NEVER USE INDICES FOR SERIES                                   //
            // For example, setAnchorEl method can shuffle all keys and data  //
            // index: legendKeys.indexOf(key) // order of label on legend     //
            ////////////////////////////////////////////////////////////////////
        };
    });
}

export function getSeries(data: any[], by: string[], valueKey: string) {
    let groupedData = groupValueBy(data, by, valueKey);
    let uniqueKeys = getUniqueKeys(data, by[by.length - 1]);
    // example: if sector is 0 for specific year, it's not available in database.
    // it will break chart history, we need to fill it by 0
    fillEmptyGroups(groupedData, uniqueKeys);
    return convertToSeries(groupedData);
}

export function sortSeriesByList(data: any[], sortOrder: string[]) {
    sortOrder = sortOrder.reverse();
    data.sort((a, b) => {
        return sortOrder.indexOf(b.name) - sortOrder.indexOf(a.name);
    });
}

export function HistoricalStackedPercentage({
    title,
    series,
    categories,
    subTitle = '',
    useHTML = true,
    tooltipFormatter = () => '',
    exportingFileName,
    plotOptionsAnimations,
    customExports,
    exports,
}: HistoricalChartProps) {
    const options: Highcharts.Options = ColumnChartOptions({
        series,
        categories,
        useHTML,
        tooltipFormatter,
        exportingFileName,
        plotOptionsAnimations,
    });

    const chartRef = React.useRef<HighchartsReact.RefObject>(null);

    return (
        <Box sx={{ width: '100%', margin: 'auto' }}>
            <ETFCard.HorizontalPanel>
                <ChartTitle>{title}</ChartTitle>
                <ExportMenus
                    chartRef={chartRef}
                    analyticsCallback={(type: string) => {
                        globalThis.analytics?.registerAction?.({
                            action: getDownloadAction(type),
                            cardName: title,
                            reportType: type,
                            reportName: exportingFileName,
                        });
                    }}
                    customExports={customExports}
                    headers={{ title, ...exports }}
                />
            </ETFCard.HorizontalPanel>
            {subTitle?.trim() === '' ? null : (
                <ETFCard.HorizontalPanel>
                    <ChartDescription>{subTitle}</ChartDescription>
                </ETFCard.HorizontalPanel>
            )}
            <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} />
        </Box>
    );
}
