import {
    CreateSavedItemResponseTypes,
    SavedItemListResponseTypes,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedScreens';
import { ApiNames, RequestTypes, UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';
import { getRequestQuery } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ETFSearchByParams, UseData } from 'utils';

export function getAllSavedScreen({ types = [UserSavedItemsLookupID.ETFScreenerSavedScreen] }: ETFSearchByParams) {
    return UseData<SavedItemListResponseTypes>({
        requestQuery: getRequestQuery({ types: types }, 'saved-items'),
        queryKey: ['getAllSavedScreen', types],
        apiName: ApiNames.UserManagement,
    });
}

export function getSavedScreenById({ savedItems, config }: ETFSearchByParams) {
    if (typeof savedItems != 'number') {
        throw new Error(`getSavedScreenById received unexpected type of savedItems - ${typeof savedItems}`);
    }
    return UseData<any>({
        requestQuery: getRequestQuery({ savedItems: savedItems }),
        queryKey: ['getSavedScreenById', savedItems],
        apiName: ApiNames.UserManagement,
        requestType: RequestTypes.GET,
        config,
    });
}

export function deleteSavedScreen({ savedItems, config }: ETFSearchByParams) {
    if (typeof savedItems != 'number') {
        throw new Error(`deleteSavedScreen received unexpected type of savedItems - ${typeof savedItems}`);
    }
    return UseData<any>({
        requestQuery: getRequestQuery({ savedItems: savedItems }),
        queryKey: ['deleteSavedScreen', savedItems],
        apiName: ApiNames.UserManagement,
        requestType: RequestTypes.DELETE,
        config,
    });
}

export function saveScreen({ requestBody, config }: ETFSearchByParams) {
    return UseData<CreateSavedItemResponseTypes>({
        requestQuery: 'saved-items',
        queryKey: ['saveScreen', requestBody],
        apiName: ApiNames.UserManagement,
        requestType: RequestTypes.POST,
        requestBody,
        config,
    });
}

export function updateSavedScreenById({ savedItems, requestBody, config }: ETFSearchByParams) {
    if (typeof savedItems != 'number') {
        throw new Error(`updateSavedScreenById received unexpected type of savedItems - ${typeof savedItems}`);
    }

    return UseData<CreateSavedItemResponseTypes>({
        requestQuery: getRequestQuery({ savedItems: savedItems }),
        queryKey: ['updateSavedScreenById', requestBody],
        apiName: ApiNames.UserManagement,
        requestType: RequestTypes.PUT,
        requestBody,
        config,
    });
}
