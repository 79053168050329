import { ReactComponent as DragAndDropIcon } from '@cfra-nextgen-frontend/shared/src/assets/images/DragAndDropIcon.svg';
import { CSSProperties, forwardRef } from 'react';
import { Action, ActionProps } from '../Action';

export const Handle = forwardRef<HTMLButtonElement, ActionProps>((props, ref) => {
    return (
        <Action
            ref={ref}
            cursor='grab'
            data-cypress='draggable-handle'
            {...props}
            style={
                {
                    height: '22px',
                    width: '7px',
                    fill: 'var(--row-hover-fill, #57626A)',
                    background: 'transparent',
                } as CSSProperties
            }>
            <DragAndDropIcon />
        </Action>
    );
});
