import { ANALYTICS_URL } from 'config';
import useScript from 'hooks/useScript';
import './App.scss';
import { AppProvider } from './providers/app';

function App() {
    useScript(ANALYTICS_URL);
    return (
        <div className='App'>
            <AppProvider>
                <div />
            </AppProvider>
        </div>
    );
}

export default App;
