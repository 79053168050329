import { ReactComponent as RemoveIcon } from '@cfra-nextgen-frontend/shared/src/assets/images/RemoveIcon.svg';
import { Action, ActionProps } from '../Action';

export function Remove(props: ActionProps) {
    return (
        <Action
            {...props}
            style={{
                height: '22px',
                width: '9px',
                fill: '#75757A',
            }}
            active={{
                fill: 'red',
                background: 'transparent',
            }}>
            <RemoveIcon />
        </Action>
    );
}
