import { waitUntil } from 'utils';

class PrefetchHelper {
    private callbackArray: Array<() => void>;
    private runningStatus: boolean;

    constructor() {
        this.callbackArray = [];
        this.runningStatus = false;
    }

    private runWaitingHandler() {
        this.runningStatus = true;
        waitUntil(
            () =>
                document.getElementsByClassName('analytics-cfra-skeleton').length === 0 &&
                document.getElementsByClassName('ag-loading-text').length === 0,
            [
                () => {
                    const callbackArray = this.callbackArray;
                    this.callbackArray = [];
                    this.runningStatus = false;

                    callbackArray.forEach((func) => func());
                },
            ],
        );
    }

    public runWhenNoSkeletons(callback: () => void) {
        this.callbackArray.push(callback);
        if (!this.runningStatus) {
            this.runWaitingHandler();
        }
    }
}

export const prefetchHelper = new PrefetchHelper();

export interface PrefetchApiDataProps {
    requestArgsList: Array<any>;
    requestCallback: (args: any) => any;
}

export function prefetchApiData({ requestArgsList, requestCallback }: PrefetchApiDataProps) {
    requestArgsList.forEach((reqArgs: any) => {
        prefetchHelper.runWhenNoSkeletons(() => requestCallback(reqArgs));
    });
}
