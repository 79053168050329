import { Item } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { CommonFormComponentProps } from '@cfra-nextgen-frontend/shared/src/components/Form/types/form';
import { Controller } from 'react-hook-form';
import AutocompleteSearchTable from './AutocompleteSearchTable';

type FormAutocompleteSearchTableProps = {
    options: Array<Item>;
    placeholder: string;
    defaultValues: Array<number> | null;
    label: string;
} & CommonFormComponentProps;

export default function FormAutocompleteSearchTable(props: FormAutocompleteSearchTableProps) {
    const defaultValues = props.defaultValues
        ? props.options.filter((option) => props.defaultValues?.includes(option.key))
        : [];

    return (
        <Controller
            name={props.name}
            control={props.control}
            defaultValue={defaultValues}
            render={({ field }) => (
                <AutocompleteSearchTable
                    id={props.name}
                    options={props.options}
                    placeholder={props.placeholder}
                    defaultValues={defaultValues}
                    value={field.value}
                    label={props.label}
                    onChange={(_, data) => {
                        field.onChange(data);
                        props.submitHandler?.();
                    }}
                />
            )}
        />
    );
}
