import { getRequestQuery } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ETFSearchByParams, UseData } from 'utils';
import {
    CreditExposureHistoricalData,
    ETFDataFlows,
    EtfDataFlowsAndPerformance,
    EtfDataHoldingsAndExposure,
    EtfDetailsData,
    SectorExposureHistoricalData,
} from '../types/research';

export function getEtfDataOverview({ cfraId, ticker, exchange }: ETFSearchByParams) {
    return UseData<EtfDetailsData>({
        requestQuery: getRequestQuery({ cfraId, ticker, exchange }),
        queryKey: ['getEtfDataOverview', cfraId, ticker, exchange],
    });
}

export function getEtfDataHoldingsAndExposure({ cfraId, ticker, exchange, top = 10, config }: ETFSearchByParams) {
    return UseData<EtfDataHoldingsAndExposure>({
        requestQuery: getRequestQuery({ cfraId, ticker, exchange, top }, 'top-holdings/'),
        queryKey: ['etfDataHoldingsAndExposure', cfraId, ticker, exchange, top],
        config: {
            ...config,
        },
    });
}

export function getEtfDataFlowsAndPerformance({ cfraId, ticker, exchange }: ETFSearchByParams) {
    return UseData<EtfDataFlowsAndPerformance>({
        requestQuery: getRequestQuery({ cfraId, ticker, exchange }, 'performance/'),
        queryKey: ['etfDataFlowsAndPerformance', cfraId, ticker, exchange],
    });
}

export function getSectorExposureHistorical({ cfraId, ticker, exchange, numberOfYears = 5 }: ETFSearchByParams) {
    return UseData<SectorExposureHistoricalData>({
        requestQuery: getRequestQuery({ cfraId, ticker, exchange, numberOfYears }, 'sector-exposure-history/'),
        queryKey: ['getSectorExposureHistorical', cfraId, ticker, exchange, numberOfYears],
    });
}

export function getEtfHoldingsAndExposure<T>({ dataType, cfraId }: { cfraId: string; dataType: string }) {
    const requestQuery = getRequestQuery({ cfraId }, `${dataType}/`);
    const queryKey = ['getEtfHoldingsAndExposure', `cfraId ${cfraId}`, `dataType ${dataType}`];

    return UseData<T>({
        requestQuery: requestQuery,
        queryKey: queryKey,
    });
}

export function getCreditExposureHistorical({ cfraId, ticker, exchange, numberOfYears = 5 }: ETFSearchByParams) {
    return UseData<CreditExposureHistoricalData>({
        requestQuery: getRequestQuery({ cfraId, ticker, exchange, numberOfYears }, 'credit-exposure-history/'),
        queryKey: ['getCreditExposureHistorical', cfraId, ticker, exchange, numberOfYears],
    });
}

export function getETFFlows({ cfraId, ticker, exchange, dateRange }: ETFSearchByParams) {
    return UseData<{ data: Array<ETFDataFlows> }>({
        requestQuery: getRequestQuery({ cfraId, ticker, exchange, dateRange }, 'flows/'),
        queryKey: ['getETFFrowsFor', cfraId, ticker, exchange, dateRange],
    });
}
