/* eslint react/jsx-props-no-spreading: off */ // --> eslint says prop spreading fine for HOC

import MUIGrid, { GridProps } from '@mui/material/Grid';
import React from 'react';

const Grid = React.forwardRef<HTMLDivElement, GridProps>((props, ref) => {
    return <MUIGrid ref={ref} {...props} />;
});

export default Grid;
