import { getRequestQuery } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ETFSearchByParams, prefetchQuery, UseData } from 'utils';
import {
    EtfData,
    ETFFlowsToAssetThemesAndFactorsData,
    ETFPerformanceThemesAndFactorsData,
    KeyAssetClassesData,
} from '../types/research';

export function getKeyAssetClassesData({ cfraIds, tickerExchanges }: ETFSearchByParams) {
    return UseData<{ data: Array<KeyAssetClassesData> }>({
        requestQuery: getRequestQuery({ cfraIds, tickerExchanges }, 'performance/'),
        queryKey: ['getKeyAssetClassesData', `${JSON.stringify(cfraIds)}`, `${JSON.stringify(tickerExchanges)}`],
    });
}

export function getAggregationFlows({ top, orderBy, sortDirection, aggregateBy, usePrefetchQuery }: ETFSearchByParams) {
    const requestQuery = getRequestQuery({ top, orderBy, sortDirection, aggregateBy }, 'aggregation/flows/');
    const queryKey = ['getAggregationFlows', top, orderBy, sortDirection, aggregateBy];

    if (usePrefetchQuery) {
        return prefetchQuery({ requestQuery, queryKey });
    }

    return UseData<{ data: Array<ETFFlowsToAssetThemesAndFactorsData> }>({
        requestQuery,
        queryKey,
    });
}

export function getAggregationPerformance({
    top,
    orderBy,
    sortDirection,
    aggregateBy,
    usePrefetchQuery,
}: ETFSearchByParams) {
    const requestQuery = getRequestQuery({ top, orderBy, sortDirection, aggregateBy }, 'aggregation/performance/');
    const queryKey = ['getAggregationPerformance', top, sortDirection, orderBy, aggregateBy];

    if (usePrefetchQuery) {
        return prefetchQuery({ requestQuery, queryKey });
    }

    return UseData<{ data: Array<ETFPerformanceThemesAndFactorsData> }>({
        requestQuery,
        queryKey,
    });
}

export function getEtfs({ fields, filterBy, orderBy, sortDirection }: ETFSearchByParams) {
    return UseData<{ data: Array<EtfData> }>({
        requestQuery: getRequestQuery({ fields, filterBy, orderBy, sortDirection }, 'etfs/'),
        queryKey: ['getEtfs', fields, filterBy, orderBy, sortDirection],
    });
}
