import { AgGridSelectedRowsContext } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';
import { getSnapshot, subscribe } from '@cfra-nextgen-frontend/shared/src/hooks/windowDimensions';
import { ValueTypes, formatValue } from '@cfra-nextgen-frontend/shared/src/utils/valuesFormatter';
import { ColDef, IServerSideDatasource, ValueFormatterParams } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import {
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
    useSyncExternalStore,
} from 'react';
import './AgGrid.scss';

export function getAgGridFormatter(formattingType: ValueTypes) {
    return function (params: ValueFormatterParams) {
        return String(
            formatValue({
                value: params.value,
                formattingType: formattingType,
            }),
        );
    };
}

type AgGridProps = {
    columnDefs: Array<ColDef>;
    rowsData?: Array<any>;
    getResizableMinWidthForColumn: (key: string) => number;
    updateCustomColumnsWidths?: (gridRef: React.RefObject<AgGridReact>) => void;
    maxNumberOfRowsToDisplay?: number;
    maxGridContainerHeightPercentage?: number; // the value in percentage from window height [1;100], needed for cases when grid container is centered, for example if grid is placed inside modal window
    useSSRMode?: boolean;
    SSRDataSource?: IServerSideDatasource;
    SSRrowsToFetch?: number;
    rowMultiSelectWithClick?: boolean;
    rowSelection?: 'single' | 'multiple';
    getRowID?: (params: any) => string;
    suppressRowClickSelection?: boolean;
};

export const defaultMinWidth = 120;

export const AgGrid = forwardRef<AgGridReact, AgGridProps>((props, ref) => {
    const { setSelectedRowsCount } = useContext(AgGridSelectedRowsContext);
    const { height } = useSyncExternalStore(subscribe, getSnapshot);
    const [gridTopPosition, setGridTopPosition] = useState<number>();
    const gridContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const gridContainerRefCurrent = gridContainerRef.current;
        if (gridContainerRefCurrent) {
            const { top } = gridContainerRefCurrent.getBoundingClientRect();
            setGridTopPosition(top);
        }
    }, [gridContainerRef, props]);

    const {
        rowsData = [],
        getResizableMinWidthForColumn,
        updateCustomColumnsWidths,
        maxNumberOfRowsToDisplay = 20,
        maxGridContainerHeightPercentage,
        useSSRMode = false,
    } = props;

    let columnDefs = props.columnDefs?.map((obj) => ({
        minWidth: getResizableMinWidthForColumn(String(obj.headerName)),
        ...obj,
    }));

    const gridRef = useRef<AgGridReact>(null);
    useImperativeHandle(ref, () => gridRef.current!);

    const defaultMaxWidth = 350;
    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo(
        (): ColDef => ({
            sortable: true,
            filter: true,
            resizable: true,
            autoHeight: true,
            maxWidth: defaultMaxWidth,
            menuTabs: [],
        }),
        [],
    );

    const defaultRowHeight = 46;

    function setMaxResizeWidthForAllColumnsTo(maxWidth: number) {
        const columnDefs: Array<ColDef> = gridRef.current?.api.getColumnDefs() as Array<ColDef>;
        if (!columnDefs) return;
        gridRef.current?.api.setColumnDefs(columnDefs.map((columnDef) => ({ ...columnDef, maxWidth: maxWidth })));
    }

    const setMinResizeWidthForAllColumns = useCallback(() => {
        const columnDefs = gridRef.current?.api.getColumnDefs();
        if (!columnDefs) return;
        gridRef.current?.api.setColumnDefs(
            columnDefs.map((columnDef) => ({
                ...columnDef,
                minWidth: getResizableMinWidthForColumn(String(columnDef.headerName)),
            })),
        );
    }, [getResizableMinWidthForColumn]);

    const setInitiallColumnDefsForAllColumns = useCallback(
        (keys: Array<string>) => {
            const localColumnDefs: Array<ColDef> = gridRef.current?.api.getColumnDefs() as Array<ColDef>;
            if (!localColumnDefs) return;
            gridRef.current?.api.setColumnDefs(
                localColumnDefs.map((localColumnDef) => {
                    keys.forEach((key) => {
                        const colDefProp = columnDefs.filter((item) => item.field === localColumnDef.field)[0];

                        localColumnDef = {
                            ...localColumnDef,
                            [key]: colDefProp.hasOwnProperty(key)
                                ? colDefProp[key as keyof typeof colDefProp]
                                : defaultColDef[key as keyof typeof defaultColDef],
                        };
                    });
                    return localColumnDef;
                }),
            );
        },
        [columnDefs, defaultColDef],
    );

    function calculateAllColumnsWidths() {
        return (
            gridRef.current?.columnApi
                .getColumns()
                ?.reduce((prevResult, current) => (prevResult += current.getActualWidth()), 0) || 0
        );
    }

    const autoSizeAllColumns = useCallback(() => {
        if (!gridRef.current) return;
        const keys = ['maxWidth', 'minWidth'];
        setInitiallColumnDefsForAllColumns(keys);
        gridRef.current?.columnApi.autoSizeAllColumns(false);
        const right = gridRef.current.api.getHorizontalPixelRange().right;
        const actualWidthExceededViewableWidth = calculateAllColumnsWidths() > right;
        if (actualWidthExceededViewableWidth) {
            gridRef.current?.columnApi.autoSizeAllColumns(true);
        }

        if (updateCustomColumnsWidths) {
            updateCustomColumnsWidths(gridRef);
        }

        setMaxResizeWidthForAllColumnsTo(right);
        setMinResizeWidthForAllColumns();
    }, [setInitiallColumnDefsForAllColumns, setMinResizeWidthForAllColumns, updateCustomColumnsWidths]);

    function calculateHeight() {
        const windowHeight = height || 0;

        let rowsToDisplay = useSSRMode ? props.SSRrowsToFetch || 0 : rowsData.length;

        const numberOfRowsToShow = rowsToDisplay > maxNumberOfRowsToDisplay ? maxNumberOfRowsToDisplay : rowsToDisplay; // show vertical scroll if more than maxNumberOfRowsToDisplay rows

        const staticGridHeight = (numberOfRowsToShow + 1) * defaultRowHeight; // 1 for header row, 0.1 for preventing unnecessary vertical scroll
        const _gridTopPosition = gridTopPosition || 0;

        if (maxGridContainerHeightPercentage) {
            const maxGridContainerHeight = (windowHeight * maxGridContainerHeightPercentage) / 100;
            const gridContainerSpaceAboveTheGrid = _gridTopPosition - (windowHeight - maxGridContainerHeight) / 2;
            const maxGridHeight = maxGridContainerHeight - gridContainerSpaceAboveTheGrid;

            return staticGridHeight < maxGridHeight ? staticGridHeight : maxGridHeight;
        }

        return staticGridHeight < windowHeight - _gridTopPosition ? staticGridHeight : windowHeight - _gridTopPosition;
    }
    const calculatedHeight = calculateHeight();
    const minGridHeight = 5 * defaultRowHeight; // 4 rows + header
    const gridHeight = calculatedHeight < minGridHeight ? minGridHeight : calculatedHeight;

    return (
        <div ref={gridContainerRef} style={{ width: '100%' }} className='cfra-ag-grid'>
            <div
                className='ag-theme-alpine'
                style={{
                    height: gridHeight,
                }}>
                <AgGridReact
                    ref={gridRef}
                    {...(useSSRMode
                        ? {
                              rowModelType: 'serverSide',
                              serverSideDatasource: props.SSRDataSource,
                              cacheBlockSize: props.SSRrowsToFetch,
                              getRowId: props.getRowID,
                              rowMultiSelectWithClick: props.rowMultiSelectWithClick,
                          }
                        : { rowData: rowsData })}
                    columnDefs={columnDefs}
                    suppressRowClickSelection={props.suppressRowClickSelection || false}
                    defaultColDef={defaultColDef} // Default Column Properties
                    animateRows={true}
                    rowSelection={props.rowSelection || 'single'}
                    getRowHeight={() => defaultRowHeight}
                    headerHeight={defaultRowHeight}
                    suppressPropertyNamesCheck={true} // This is not ideal, but see: https://github.com/ag-grid/ag-grid/issues/2320
                    onGridReady={() => autoSizeAllColumns()}
                    onGridSizeChanged={() => autoSizeAllColumns()}
                    onRowSelected={() =>
                        setSelectedRowsCount !== undefined
                            ? setSelectedRowsCount(gridRef.current?.api.getSelectedRows().length || 0)
                            : () => {}
                    }
                />
            </div>
        </div>
    );
});
