import http from '../../../microservices-common';

export async function getUserDetails(): Promise<UserDetailsResponse> {
    return await http
        .get('SP/msa/user/details')
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return null;
        });
}
