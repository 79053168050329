import { DataPointsDisplayNames } from './enums';
import { getMomentObjectFrom } from './time';

export type Percentage = `${number}%`;

export function removeInaccuracy(value: number) {
    return parseFloat(value.toFixed(10).toString());
}

function isFloat(n: number) {
    return Number(n) === n && n % 1 !== 0;
}

export function formatNumberTo2DecimalPlaces(value: any, alwaysFloat = false) {
    if (typeof value !== 'number') {
        //console.error(`formatNumberTo2DecimalPlaces received not number value - ${value}, returned without changes.`);
        return value;
    }

    const makeToFixed2WithoutRounding = (value: number) => (Math.trunc(removeInaccuracy(value * 100)) / 100).toFixed(2);

    if (alwaysFloat) {
        return makeToFixed2WithoutRounding(value);
    }

    return isFloat(value) ? makeToFixed2WithoutRounding(value) : value;
}

function formatValuesMillionsScale(value: number) {
    if (value > -1_000_000 && value < 1_000_000) return formatNumber(value);
    else return `${formatNumber(value / 1_000_000)}\u00a0M`;
}

function formatValueWithCurrencyType(value: string | number, Currency: string) {
    return `${Currency} ${value}`;
}

export function getNumberWithCommas(x: string | number) {
    return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function formatNumber(x: number | string, alwaysFloat = false) {
    return getNumberWithCommas(formatNumberTo2DecimalPlaces(x, alwaysFloat));
}

function formatValueDateType({ value, additionalConfig }: { value: string; additionalConfig?: { [id: string]: any } }) {
    const defaultDateFormat = 'MMM D, YYYY';

    if (typeof additionalConfig !== 'undefined' && additionalConfig.hasOwnProperty('dateFormat'))
        return getMomentObjectFrom(value).format(additionalConfig.dateFormat).toString();
    else return getMomentObjectFrom(value).format(defaultDateFormat).toString();
}

export const enum ValueTypes {
    Text = 'Text',
    Date = 'Date',
    Percentage = 'Percentage',
    ConvertedPercentage = 'ConvertedPercentage',
    PercentageOnlyNumber = 'PercentageOnlyNumber',
    Numeral = 'Numeral',
    NumeralAlwaysFloat = 'NumeralAlwaysFloat',
    Currency = 'Currency',
    Special = 'Special',
    Integer = 'Integer',
    MillionsScale = 'MillionsScale',
}

export type formatValueParams = {
    value: string | number;
    formattingType: ValueTypes;
    showNumberIfZero?: boolean;
    additionalConfig?: { [id: string]: any };
};

export class FormatValueParams {
    private source: { [id: string]: any };
    private dataPointDisplayNameToFieldName: Record<DataPointsDisplayNames, string>;
    private dataPointsDisplayNameToFormattingType: Record<DataPointsDisplayNames, ValueTypes>;

    constructor({
        source,
        dataPointDisplayNameToFieldName,
        dataPointsDisplayNameToFormattingType,
    }: {
        source: { [id: string]: any };
        dataPointDisplayNameToFieldName: Record<DataPointsDisplayNames, string>;
        dataPointsDisplayNameToFormattingType: Record<DataPointsDisplayNames, ValueTypes>;
    }) {
        this.source = source;
        this.dataPointDisplayNameToFieldName = dataPointDisplayNameToFieldName;
        this.dataPointsDisplayNameToFormattingType = dataPointsDisplayNameToFormattingType;
    }

    public create({ key }: { key: DataPointsDisplayNames }): formatValueParams {
        const sourceValue = this.source[this.dataPointDisplayNameToFieldName[key]];
        return {
            value: sourceValue instanceof Date ? sourceValue.toString() : sourceValue,
            formattingType: this.dataPointsDisplayNameToFormattingType[key],
        };
    }
}

export function formatValue({ value, formattingType, additionalConfig, showNumberIfZero = false }: formatValueParams) {
    if (
        value === null ||
        typeof value === 'undefined' ||
        value === '' ||
        (!showNumberIfZero && value === 0 && formattingType !== ValueTypes.Special)
    ) {
        //console.error(`requirements for formatting value ${value} not mached, returning dash.`);
        return '-';
    }

    if (formattingType === ValueTypes.Text) return value.toString();
    if (formattingType === ValueTypes.Date) value = value.toString();

    if ([ValueTypes.Special, ValueTypes.Currency].includes(formattingType) && typeof additionalConfig === 'undefined') {
        //console.error(`configuration for formatting type - Special or Currency not set for value ${value}`);
        return '-';
    }

    if (formattingType === ValueTypes.Special) {
        if (additionalConfig?.valueName === '52-Week Range') {
            if (
                typeof additionalConfig.high_split_dividend_adjusted_52_week !== 'number' ||
                typeof additionalConfig.low_split_dividend_adjusted_52_week !== 'number' ||
                typeof additionalConfig.listing_currency !== 'string'
            ) {
                //console.error('requirements for calculating 52-Week Range not mached, returning dash.');
                return '-';
            }
            return formatValueWithCurrencyType(
                `${formatNumber(additionalConfig.high_split_dividend_adjusted_52_week)} - ${formatNumber(
                    additionalConfig.low_split_dividend_adjusted_52_week,
                )}`,
                additionalConfig.listing_currency,
            );
        }
        return '-';
    }

    if (
        [
            ValueTypes.MillionsScale,
            ValueTypes.Percentage,
            ValueTypes.ConvertedPercentage,
            ValueTypes.PercentageOnlyNumber,
            ValueTypes.Currency,
            ValueTypes.Numeral,
            ValueTypes.NumeralAlwaysFloat,
        ].includes(formattingType) &&
        typeof value !== 'number'
    ) {
        //console.error(`can't apply numeric formatting for not a number.`);
        return '-';
    }

    if (formattingType === ValueTypes.Date) {
        return formatValueDateType({ value: value.toString(), additionalConfig: additionalConfig });
    }

    if (formattingType === ValueTypes.MillionsScale) {
        return formatValuesMillionsScale(value as number);
    }

    if (formattingType === ValueTypes.Percentage || formattingType === ValueTypes.PercentageOnlyNumber) {
        value = removeInaccuracy((value as number) * 100);
    }

    if (
        [
            ValueTypes.Currency,
            ValueTypes.Numeral,
            ValueTypes.NumeralAlwaysFloat,
            ValueTypes.Percentage,
            ValueTypes.ConvertedPercentage,
            ValueTypes.PercentageOnlyNumber,
        ].includes(formattingType)
    ) {
        value = formatNumber(value, formattingType === ValueTypes.NumeralAlwaysFloat);
    }

    if (formattingType === ValueTypes.Currency) {
        if (typeof additionalConfig?.listing_currency === 'string') {
            return `${additionalConfig.listing_currency} ${value}`;
        } else {
            //console.error(`typeof listing_currency is not string for Currency formatting.`);
            return '-';
        }
    }

    if ([ValueTypes.Percentage, ValueTypes.ConvertedPercentage].includes(formattingType)) {
        return `${value}%`;
    }

    return value;
}

export function getPercentageValue(percentageValueString: Percentage) {
    return parseInt(percentageValueString.replace(/\D/g, '')) / 100;
}

export function isCloseOrEqualToZero(value: number, x: number = 0.001) {
    // uses for hide Other and Unclassified weightings with too small values
    return Math.abs(value) < x;
}

export function isNumeric(n: string) {
    return !isNaN(parseFloat(n)) && isFinite(parseFloat(n));
}

export function isNumber(str: string) {
    const numberPattern = /^(-?0|-?[1-9]\d*(\.\d+)?|-?0\.\d+)$/;
    return numberPattern.test(str);
}

export function extractTickerFromParenthesis(inputString: string): string {
    const tickerMatch = inputString.match(/\(([^)]+)\)/g);
    if (tickerMatch && tickerMatch.length > 0) {
        return tickerMatch[0].replace('(', '').replace(')', '');
    }
    return inputString; // return original string if no ticket in parenthesis found
}

export function calcPercentChange(value1: number, value2: number, precision: number = 2): number {
    return +(((value2 - value1) / Math.abs(value1)) * 100).toFixed(precision);
}

export function getExportingFileName(title: string, ticker: string, exchange: string) {
    return `${title.replaceAll(' ', '-').toLocaleLowerCase()}-${ticker}-${exchange}-chart`;
}

export function formatPercentages(value: number | null) {
    if (value === null || value === undefined) return value;
    return Number(formatNumberTo2DecimalPlaces(removeInaccuracy(value * 100)));
}
