import { AgGrid, ETFCard, Layout } from '@cfra-nextgen-frontend/shared';
import { BreadcrumbConfig, IServerSideDatasource } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/types';
import { breakpointsTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { Categories, fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Grid, SxProps, Typography, styled, useMediaQuery } from '@mui/material';
import { ColDef, SortModelItem } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { getDownloadAction } from 'analytics/utils';
import { Link } from 'components/AgGrid/utils';
import { ChartDescription } from 'components/Chart/Chart';
import { ChartTabs, ChartTabsProps } from 'components/Chart/ChartTabs';
import { TableExportMenus } from 'components/Chart/ExportMenus';
import { exportAgGrid, exportSSRAgGrid } from 'components/excelExport/export';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { CategoriesInfo } from './CategoriesInfo';
import { setCustomFlexWidths } from './utils';
export type TopPanelProps = {
    label?: string;
    description?: string;
    dateRangesPanelConfig?: Array<Layout.ETFButtonsPannelButtonProps>;
    categoriesPanelConfig?: ChartTabsProps;
    exportRef?: React.RefObject<AgGridReact>;
    breadcrumbsConfig?: Array<BreadcrumbConfig>;
    excelExportUseMaxAsOfDate?: boolean;
    excelExportAsOfDateField?: string;
    topLeftActionPanelStyles?: SxProps;
    topLeftActionPanelItems?: React.ReactNode[];
    topRightActionPanelItems?: React.ReactNode[];
    topPanelSlot1Content?: React.ReactNode;
    topPanelSlot2Content?: React.ReactNode;
    topPanelSlot3Content?: React.ReactNode;
    labelProps?: any;
    SSRDataExportFn?: (sortColumns: SortModelItem[]) => Promise<Array<any>>;
};

type AgGirdCardProps = {
    columnDefs: Array<ColDef>;
    getResizableMinWidthForColumn: (key: string) => number;
    customFlexibleColumns?: Array<string>;
    rowsData?: Array<any>;
    maxNumberOfRowsToDisplay?: number;
    maxGridContainerHeightPercentage?: number;
    useSSRMode?: boolean;
    SSRDataSource?: IServerSideDatasource;
    SSRrowsToFetch?: number;
    rowSelection?: 'single' | 'multiple';
    getRowID?: (params: any) => string;
    rowMultiSelectWithClick?: boolean;
    suppressRowClickSelection?: boolean;
} & TopPanelProps;

export function TopPanelRow(props: { children: React.ReactNode }) {
    return (
        <Grid
            item
            container
            xs={12}
            sx={{ display: 'flex', justifyContent: 'space-between', padding: '0px 28px', paddingTop: '0px' }}>
            {props.children}
        </Grid>
    );
}

export function AgGirdCardTopPanel(props: TopPanelProps) {
    const {
        label,
        description,
        dateRangesPanelConfig,
        categoriesPanelConfig,
        breadcrumbsConfig,
        exportRef,
        excelExportUseMaxAsOfDate,
        excelExportAsOfDateField,
        labelProps,
    } = props;
    const [showExportButton, setShowExportButton] = useState(false);
    const isBelowSm = useMediaQuery(breakpointsTheme.breakpoints.down('sm'));

    useEffect(() => {
        if (exportRef && exportRef.current) {
            setShowExportButton(true);
        }
    }, [exportRef]);

    const agGridExportProps = {
        fileName: label,
        sheetName: 'Data Details',
        metadata: {
            cardName: label || '',
        },
        asOfDateField: excelExportAsOfDateField,
        useMaxAsOfDate: excelExportUseMaxAsOfDate,
        autoSizeColumns: true,
    };

    let selectedCategory: string | undefined = undefined;
    if (categoriesPanelConfig) {
        if (categoriesPanelConfig.currentActiveTab === false) {
            selectedCategory = 'no categories selected';
        }
        if (typeof categoriesPanelConfig.currentActiveTab === 'number') {
            selectedCategory = Object.values(Categories)[categoriesPanelConfig.currentActiveTab];
        }
    }

    const TopRightActionGrid = styled(Grid)(() => ({
        display: 'flex',
        alignItems: 'center',
        gap: '25px',
        flexWrap: 'wrap-reverse',
        justifyContent: 'right',
        rowGap: '8px',
    }));

    const TopLeftActionGrid = styled(Grid)(() => ({
        display: 'flex',
        alignItems: 'center',
        gap: '21px',
        flexWrap: 'wrap',
        rowGap: '8px',
    }));

    return (
        <>
            {label || description ? (
                <Grid container sx={{ padding: '28px 28px 0px 28px' }}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <TopLeftActionGrid item sx={props.topLeftActionPanelStyles}>
                            {label && <ETFCard.ItemHeader sx={{ ...(labelProps || {}) }}>{label}</ETFCard.ItemHeader>}
                            {props.topLeftActionPanelItems}
                        </TopLeftActionGrid>
                        <TopRightActionGrid item>
                            {props.topRightActionPanelItems}
                            {label && showExportButton ? (
                                <TableExportMenus
                                    exportCallback={() => {
                                        if (!props.SSRDataExportFn) {
                                            exportAgGrid(exportRef, agGridExportProps);
                                        } else {
                                            exportSSRAgGrid(exportRef, props.SSRDataExportFn, agGridExportProps);
                                        }
                                    }}
                                    analyticsCallback={(type: string) => {
                                        globalThis.analytics?.registerAction?.({
                                            action: getDownloadAction(type),
                                            cardName: label,
                                            dateRange: dateRangesPanelConfig?.find((element) =>
                                                Boolean(element.isDefault),
                                            )?.name,
                                            reportType: type,
                                            reportName: agGridExportProps.fileName,
                                            selectedCategory: selectedCategory,
                                        });
                                    }}
                                />
                            ) : null}
                        </TopRightActionGrid>
                    </Grid>
                    <Grid item xs={12}>
                        {description ? <ChartDescription>{description}</ChartDescription> : null}
                    </Grid>
                </Grid>
            ) : null}
            {dateRangesPanelConfig || categoriesPanelConfig ? (
                <TopPanelRow>
                    {categoriesPanelConfig ? (
                        <Grid item sx={{ paddingBottom: '36px' }} xs={isBelowSm ? 12 : undefined}>
                            <Grid container>
                                <ChartTabs
                                    {...categoriesPanelConfig}
                                    tabsStyles={{
                                        fontFamily: fontFamilies.GraphikMedium,
                                        fontSize: '15px',
                                    }}
                                />
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <CategoriesInfo
                                        buttonFontSize={14}
                                        analyticsOpenModalProps={{
                                            cardName: label,
                                            dateRange: dateRangesPanelConfig?.find((element) =>
                                                Boolean(element.isDefault),
                                            )?.name,
                                            selectedCategory: selectedCategory,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : null}
                    {dateRangesPanelConfig ? (
                        <Grid item sx={{ paddingBottom: '36px' }} xs={isBelowSm ? 12 : undefined}>
                            <Layout.ETFButtonsPannel
                                buttonsConfig={dateRangesPanelConfig}
                                buttonsStyles={{ fontSize: '15px' }}
                            />
                        </Grid>
                    ) : null}
                </TopPanelRow>
            ) : null}
            {breadcrumbsConfig ? (
                <Breadcrumbs
                    aria-label='breadcrumb'
                    separator={<NavigateNextIcon fontSize='small' />}
                    sx={{ paddingLeft: '28px', paddingBottom: '12px' }}>
                    {breadcrumbsConfig.map((config, index) => {
                        if (index + 1 === breadcrumbsConfig.length) {
                            return <Typography key={index}>{config.text}</Typography>;
                        }
                        return <Link key={index} value={config.text} handleOpen={config.callback} />;
                    })}
                </Breadcrumbs>
            ) : null}
            {props.topPanelSlot1Content}
            {props.topPanelSlot2Content}
            {props.topPanelSlot3Content}
        </>
    );
}

export const AgGirdCard = forwardRef<AgGridReact, AgGirdCardProps>((props, ref) => {
    const isBelowLg = useMediaQuery(breakpointsTheme.breakpoints.down('lg'));

    const gridRef = useRef<AgGridReact>(null);
    useImperativeHandle(ref, () => gridRef.current!);

    return (
        <ETFCard.ETFCard containerStyles={{ padding: '0px' }}>
            <AgGirdCardTopPanel {...props} exportRef={gridRef} />
            <AgGrid.AgGrid
                {...props}
                ref={gridRef}
                updateCustomColumnsWidths={
                    props.customFlexibleColumns
                        ? setCustomFlexWidths(isBelowLg, props.customFlexibleColumns)
                        : undefined
                }
            />
        </ETFCard.ETFCard>
    );
});
