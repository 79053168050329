import { ScreenerViewReducer } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerViewContext/Reducer';
import { ScreenerDefaultViews } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/views';
import { getDataFromLocalStorage } from '@cfra-nextgen-frontend/shared/src/utils/storage';
import { createContext, useMemo, useReducer } from 'react';
import { DispatcherState, ScreenerView, State } from './types';

export const ScreenerViewContext = createContext<DispatcherState>({} as DispatcherState);

export const ScreenerActiveViewLocalStorageKey = 'screener-active-view';
export const ScreenerCurrentViewLocalStorageKey = 'screener-current-view';

export function ScreenerViewContextProvider({ children }: { children: React.ReactNode }) {
    const memorizedChildren = useMemo(() => children, [children]);

    const InitialState: State = {
        screenerActiveView:
            (getDataFromLocalStorage(ScreenerActiveViewLocalStorageKey) as ScreenerView | undefined) ||
            ScreenerDefaultViews.Overview,
        screenerCurrentView: getDataFromLocalStorage(ScreenerCurrentViewLocalStorageKey),
    };
    const [screenerViewState, screenerViewActionDispatcher] = useReducer(ScreenerViewReducer, InitialState);

    return (
        <ScreenerViewContext.Provider value={{ screenerViewState, screenerViewActionDispatcher }}>
            {memorizedChildren}
        </ScreenerViewContext.Provider>
    );
}
