import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout/Grid';
import { scrollbarThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { Box, SxProps } from '@mui/material';
import React, { forwardRef } from 'react';

export const BorderHeight = 1;
export const BorderStyle = `${BorderHeight}px solid #EDF0F6`;

export const RowWrapperGridCommonStyles = {
    borderBottom: BorderStyle,
    paddingLeft: '0px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
        backgroundColor: '#FAFAFA',
    },
};

export const RowWrapperBoxCommonStyles = {
    paddingRight: '10px',
    paddingLeft: '0px',
    display: 'flex',
    alignItems: 'center',
};

export function RowWrapper(props: {
    rowProps?: React.HTMLAttributes<HTMLDivElement>;
    children: React.ReactNode;
    childrenContainerSx?: SxProps;
}) {
    return (
        <Grid item xs={12} {...props.rowProps} sx={RowWrapperGridCommonStyles}>
            <Box
                sx={{
                    ...RowWrapperBoxCommonStyles,
                    ...props.childrenContainerSx,
                }}>
                {props.children}
            </Box>
        </Grid>
    );
}

export const ScrollableColumn = forwardRef<HTMLDivElement, { label: React.ReactNode; children: React.ReactNode }>(
    ({ children, label }, ref) => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    overflowY: 'auto',
                    flexDirection: 'column',
                    borderRadius: '6px',
                    border: '1px solid #DCDCDC',
                    boxSizing: 'border-box',
                }}>
                <Box sx={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                    <Box sx={{ borderBottom: BorderStyle }}>{label}</Box>
                    <Grid container sx={{ overflowY: 'auto', overflowX: 'hidden', ...scrollbarThemeV2 }} ref={ref}>
                        {children}
                    </Grid>
                </Box>
            </Box>
        );
    },
);
