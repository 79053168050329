import { ETFMenu, IconButton } from '@cfra-nextgen-frontend/shared';
import BookmarkIcon from '@cfra-nextgen-frontend/shared/src/assets/images/BookmarkIcon.svg';
import PencilIcon from '@cfra-nextgen-frontend/shared/src/assets/images/PencilIcon.svg';
import PlusIcon from '@cfra-nextgen-frontend/shared/src/assets/images/PlusIcon.svg';
import RedBinIcon from '@cfra-nextgen-frontend/shared/src/assets/images/RedBinIcon.svg';
import { LinkButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ETFLinkButton';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { TransparentButtonChip } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsPanelRowStyle';
import { SaveScreenContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/saveScreenerContext/Context';
import { UserSavedItemsLookupID, fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import { Box, styled } from '@mui/material';
import { useContext, useState } from 'react';
import { sortByDateString } from 'utils';
import { getAllSavedScreen } from '../api/savedScreens';
import { DeleteSavedScreenModal } from './DeleteSavedScreenModal';

type SavedScreensMenuProps = {};

type SavedItemTypes = {
    name: string;
    id: number;
    saved_item_lid: number;
    updated_date: string;
};

type ScreenerMenuItemProps = {
    label: string;
    id: number;
    onDeleteClick: (id: number) => void;
    onItemClick: (id: number) => void;
};

export function SavedScreenMenu(props: SavedScreensMenuProps) {
    const cardName = 'my screen menu';
    const {
        chipStateManager: { chipState },
        chipEventsManager: { onChipClearAllClick },
    } = useContext(ResultsContext);
    const {
        saveScreenState: { selectedScreen },
        saveScreenActionDispatcher,
    } = useContext(SaveScreenContext);

    const [deleteScreen, setDeleteScreen] = useState<SavedItemTypes>();
    const getSavedItemsQuery = getAllSavedScreen({ types: [UserSavedItemsLookupID.ETFScreenerSavedScreen] });
    const savedScreenerItems: SavedItemTypes[] = getSavedItemsQuery.data?.data || [];
    const sortedSavedScreens = savedScreenerItems.sort(sortByDateString('updated_date', 'desc'));
    const showChips = chipState?.chipItems && Object.keys(chipState?.chipItems).length > 0;
    const disableSaveScreenBtn = typeof selectedScreen?.id !== 'number' && !showChips;
    const selectedItemIndex = sortedSavedScreens.findIndex((e) => e.id === selectedScreen?.id);
    const disableMyScreenBtn = !getSavedItemsQuery?.data?.data?.length && getSavedItemsQuery.isLoading;

    const myScreenMenuItems = [
        ...sortedSavedScreens.map((screen) => ({
            itemName: (
                <ScreenerMenuItem
                    label={screen.name}
                    id={screen.id}
                    onDeleteClick={handleDeleteClick}
                    onItemClick={handleOnItemClick}
                />
            ),
            callback: () => {},
        })),
        {
            itemName: <SaveScreenMenuItemButton cardName={cardName} />,
            disabled: disableSaveScreenBtn,
            callback: () => {},
        },
    ];

    function handleOnItemClick(id: number) {
        onChipClearAllClick();
        const selectedItem = sortedSavedScreens.find((e) => e.id === id);
        saveScreenActionDispatcher({ type: 'SetSelectedScreen', payload: selectedItem });
    }

    function handleDeleteClick(id: number) {
        const deleteItem = sortedSavedScreens.find((e) => e.id === id);
        setDeleteScreen(deleteItem);
    }

    function handleDeleteCancelClick() {
        setDeleteScreen(undefined);
    }

    function handleDeleteConfirmClick() {
        refetchAllSavedScreens();
    }

    function resetSelectedScreen() {
        onChipClearAllClick();
        saveScreenActionDispatcher({ type: 'ClearSelectedScreen' });
    }

    async function refetchAllSavedScreens() {
        await getSavedItemsQuery.refetch();
        if (!getSavedItemsQuery.isFetching && !getSavedItemsQuery.isError) {
            if (selectedScreen?.id === deleteScreen?.id) {
                resetSelectedScreen();
            }
            setDeleteScreen(undefined);
        }
    }

    const menuItemStyles = {
        padding: '0px 13px 0px 0px',
        ':not(:last-child)': {
            borderBottom: 0,
        },
    };

    const paperProps = {
        borderTop: '11px solid white',
        borderBottom: '11px solid white',
        maxHeight: 'min(380px, 80vh)',

        '&::-webkit-scrollbar': {
            width: '15px',
        },
        '&::-webkit-scrollbar-button': {
            height: '0px',
        },
        '&::-webkit-scrollbar-thumb': {
            minHeight: '51%',
            border: '4px solid rgba(0, 0, 0, 0)',
            backgroundClip: 'padding-box',
            backgroundColor: '#BDBDBD',
            borderRadius: '25px',
        },
    };

    return (
        <>
            <ETFMenu.ETFMenu
                menuStyles={{ padding: '11px 0' }}
                menuItemStyles={menuItemStyles}
                menuItems={myScreenMenuItems}
                paperProps={paperProps}
                selectedItemIndex={selectedItemIndex}
                onHoverItemStyles={{
                    borderLeft: 'solid #fff 4px',
                }}>
                <TransparentButtonChip
                    disabled={disableMyScreenBtn}
                    key='My Screens'
                    text='My Screens'
                    startIcon={<Box component='img' src={BookmarkIcon} />}
                />
            </ETFMenu.ETFMenu>
            {typeof deleteScreen?.id === 'number' && (
                <DeleteSavedScreenModal
                    id={deleteScreen.id}
                    name={deleteScreen.name}
                    onCancel={handleDeleteCancelClick}
                    onConfirm={handleDeleteConfirmClick}
                />
            )}
        </>
    );
}

export function ScreenerMenuItem(props: ScreenerMenuItemProps) {
    const { label, id, onDeleteClick, onItemClick } = props;

    const MenuItemContainer = styled(Box)(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        paddingRight: '5px',
        cursor: 'default',
    }));

    const ItemLabelButton = styled(LinkButton)(({ theme }) => ({
        paddingTop: '7px',
        paddingBottom: '7px',
        paddingLeft: '21px',
        fontSize: '13px',
        fontFamily: fontFamilies.GraphikRegular,
        color: '#0B2958',
        fontWeight: '400',
        lineHeight: '22px',
        letterSpacing: '0.46px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '24ch',
        minWidth: '20.5ch',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        '&:hover': {
            color: '#007BB8',
            backgroundColor: '#FFF',
        },
    }));

    return (
        <MenuItemContainer>
            <ItemLabelButton onClick={() => onItemClick(id)}>{label}</ItemLabelButton>
            <IconButton icon={<Box component='img' src={PencilIcon} />} tooltipText='Edit Name' />
            <IconButton
                icon={<Box component='img' src={RedBinIcon} />}
                tooltipText='Delete'
                onClick={() => onDeleteClick(id)}
            />
        </MenuItemContainer>
    );
}

const SaveScreenMenuItemButton = ({ cardName }: { cardName: string }) => {
    const ButtonText = 'Save New Screen';
    const { saveScreenActionDispatcher } = useContext(SaveScreenContext);

    const SaveScreenButton = styled(LinkButton)(({ theme }) => ({
        paddingTop: '7px',
        paddingBottom: '7px',
        paddingLeft: '21px',
        fontFamily: fontFamilies.GraphikSemibold,
        fontSize: '13px',
        fontWeight: 600,
        lineHeight: '22px',
        letterSpacing: '0.46px',
        width: '100%',
        display: 'flex',
        gap: '8px',
        justifyContent: 'flex-start',
        minWidth: '260px',
    }));

    const handleOnClick = () => {
        globalThis.analytics?.registerAction?.({
            action: `click on ${ButtonText}`,
            cardName: cardName,
        });
        saveScreenActionDispatcher({ type: 'OpenSaveScreenModal' });
    };

    return (
        <SaveScreenButton onClick={handleOnClick}>
            <Box component='img' src={PlusIcon} />
            <Box sx={{ color: '#3078B5', pt: '1px', lineHeight: '21px' }}>{ButtonText}</Box>
        </SaveScreenButton>
    );
};
