import {
    Categories,
    MarketTrendsDateRanges,
    chartTypes,
    fontFamilies,
    getMomentObjectFrom,
} from '@cfra-nextgen-frontend/shared/src/utils';
import FUNDynamixLogo from 'assets/images/FUNDynamixLogoWithoutBG.png';
import { Orientations } from 'components/Chart/Export';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { ExportingOptions } from 'highcharts/highstock';
import { createRoot } from 'react-dom/client';
import { createSvgNode, createTextNode, getFontsBase64String, prefetchHelper } from 'utils';

export type sortDirection = 'desc' | 'asc';

export type prefetchFunctionProps = {
    category: Categories;
    dateRange: MarketTrendsDateRanges;
    sortDirection: sortDirection;
};

export type prefetchDataProps = {
    selectedCategory: Categories;
    selectedDateRange: MarketTrendsDateRanges;
    sortDirections: Array<sortDirection>;
    prefetchFunction: ({ category, dateRange, sortDirection }: prefetchFunctionProps) => void;
};

export type ThemesAndFactorExportJpegChartProps = {
    charts: Array<HighchartsReact.RefObject>;
    chartName: chartTypes;
    exportFileName: string;
    title: string;
    subTitle: string;
    asOfDate: string;
    category: Categories;
    timeframe: MarketTrendsDateRanges;
};

export function getExportFileName(
    fileNameBegining: string,
    selectedCategory: Categories,
    selectedDateRange: MarketTrendsDateRanges,
) {
    return [fileNameBegining, selectedCategory, selectedDateRange].join(' ').toLocaleLowerCase().replaceAll(' ', '-');
}

export function renderWithContainer( // use this function ONLY if can't render the component in a different way, for example, if rendering the component under main root tree is not possible
    containerName: string,
    getChildren: (unmountCallback: () => void) => React.ReactNode,
) {
    let container: HTMLDivElement;
    const overlayModalCOntainers = document.getElementsByClassName(containerName);
    if (overlayModalCOntainers.length > 0) {
        container = overlayModalCOntainers[0] as HTMLDivElement;
    } else {
        container = document.createElement('div');
        container.classList.add(containerName);
        document.body.appendChild(container);
    }

    const root = createRoot(container);
    root.render(getChildren(() => root.unmount()));
}

export function prefetchData({
    selectedCategory,
    selectedDateRange,
    prefetchFunction,
    sortDirections = ['desc', 'asc'],
}: {
    selectedCategory: Categories;
    selectedDateRange: MarketTrendsDateRanges;
    prefetchFunction: ({ category, dateRange, sortDirection }: prefetchFunctionProps) => void;
    sortDirections?: Array<sortDirection>;
}) {
    Object.values(Categories).forEach((category) => {
        Object.values(MarketTrendsDateRanges).forEach((dateRange) => {
            sortDirections.forEach((sortDirection) => {
                //fetching data for only next possible paths for user.
                if ((category === selectedCategory) !== (dateRange === selectedDateRange)) {
                    prefetchHelper.runWhenNoSkeletons(() => prefetchFunction({ category, dateRange, sortDirection }));
                }
            });
        });
    });
}

export function getThemesAndFactorsChartTitle(chartName: string, category: string, chartIndex: number) {
    if (chartName === chartTypes.FlowsToAssetsDetails) {
        if (category === Categories.AssetClass) {
            return 'Flows to Assets Ratio';
        } else {
            return `${chartIndex === 0 ? 'Highest' : 'Lowest'} Ratio`;
        }
    } else if (chartName === chartTypes.PerformanceDetails) {
        if (category === Categories.AssetClass) {
            return 'Performance';
        } else {
            return `${chartIndex === 0 ? 'Best' : 'Worst'} Performing`;
        }
    }
    return '';
}

export async function downloadThemesAndFactorJpegCharts(props: ThemesAndFactorExportJpegChartProps) {
    const { charts, chartName, exportFileName, title, subTitle, asOfDate, category, timeframe } = props;

    const exportingOption: ExportingOptions = { type: 'image/jpeg', filename: exportFileName };

    const highchartSvgArr: Array<string> = charts.map((c) => {
        const width = 600;
        const xAxisOffset = category === Categories.AssetClass ? 220 : 300;
        const barChartWidth = width - xAxisOffset;
        const yAxisMinPadding = +(60 / barChartWidth).toFixed(2);
        const yAxisMaxPadding = +(60 / barChartWidth).toFixed(2);

        return c.chart.getSVG({
            chart: {
                width,
            },
            xAxis: {
                labels: {
                    formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
                        return `<div title="${String(this.value)}" style="width: ${
                            xAxisOffset - 20
                        }px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">${String(
                            this.value,
                        )}</div>`;
                    },
                },
                offset: xAxisOffset,
            },
            yAxis: {
                maxPadding: yAxisMaxPadding,
                minPadding: yAxisMinPadding,
            },
        });
    });

    let [chartWidth, chartHeight] = [0, 0];

    //create svg container
    const svg = createSvgNode('svg', {
        xmlns: 'http://www.w3.org/2000/svg',
        'xmlns:xlink': 'http://www.w3.org/1999/xlink',
        'font-family': fontFamilies.GraphikRegular,
    });
    svg.style.backgroundColor = '#ffffff';

    //fetch fontfiles
    const fontBase64 = await getFontsBase64String([
        '/fonts/GraphikRegular.woff2',
        '/fonts/GraphikRegular.woff',
        '/fonts/GraphikMedium.woff2',
        '/fonts/GraphikMedium.woff',
    ]);

    //add font-family support to svg
    if (fontBase64) {
        const defs = createSvgNode('defs');
        const style = createSvgNode('style');
        style.textContent = `
        @font-face {
            font-family: ${fontFamilies.GraphikRegular};
            src: url('${fontBase64[0]}') format('woff2'),
                url('${fontBase64[1]}') format('woff');
        }
        @font-face {
            font-family: ${fontFamilies.GraphikMedium};
            src: url('${fontBase64[2]}') format('woff2'),
                url('${fontBase64[3]}') format('woff');
        }`;
        defs.appendChild(style);
        svg.appendChild(defs);
    }

    //add container title
    chartWidth += 30;
    chartHeight += 40;
    svg.appendChild(
        createTextNode(title, {
            x: `${chartWidth}`,
            y: `${chartHeight}`,
            'font-size': '18px',
            fill: '#002B5A',
            'font-family': fontFamilies.GraphikMedium,
        }),
    );

    // add container subtitle
    chartHeight += 35;
    svg.appendChild(
        createTextNode(subTitle, {
            x: `${chartWidth}`,
            y: `${chartHeight}`,
            'font-size': '15px',
            fill: '#57626a',
        }),
    );

    chartHeight += 35;
    chartWidth += 10;
    //merge highchart charts as per orientation
    const orientation: Orientations = Orientations.vertical;
    const chartGroupDim = { x: 0, y: 0, width: 0, height: 0 };
    const chartGroup = createSvgNode('g', { transform: `translate(${chartWidth}, ${chartHeight})` });

    highchartSvgArr.forEach((chart, chartIndex) => {
        // Grab width/height from exported chart
        const svgWidthRExp: RegExp = /^<svg[^>]*width\s*=\s*"?(\d+)"?[^>]*>/;
        const svgresWidthMatch: RegExpMatchArray | null = chart.match(svgWidthRExp);
        if (svgresWidthMatch === null) return;
        const svgWidth: number = +svgresWidthMatch[1];

        const svgHeightRExp: RegExp = /^<svg[^>]*height\s*=\s*"?(\d+)"?[^>]*>/;
        const svgresHeightMatch: RegExpMatchArray | null = chart.match(svgHeightRExp);
        if (svgresHeightMatch === null) return;
        const svgHeight: number = +svgresHeightMatch[1];

        // add gap between charts
        if (chartIndex > 0 && chartIndex === highchartSvgArr.length - 1) {
            if (orientation === Orientations.vertical) {
                chartGroupDim.y += 25;
            } else {
                chartGroupDim.x += 40;
            }
        }

        const chartContainerDim = { x: 0, y: 0 };
        const chartContainer = createSvgNode('g', { transform: `translate(${chartGroupDim.x}, ${chartGroupDim.y})` });

        if (category) {
            // add chart title
            chartContainerDim.y += 15;
            const chartTitle = getThemesAndFactorsChartTitle(chartName, category, chartIndex);
            chartContainer.appendChild(
                createTextNode(chartTitle, {
                    x: `${chartContainerDim.x}`,
                    y: `${chartContainerDim.y}`,
                    'font-size': '15px',
                    fill: '#57626a',
                    'font-family': fontFamilies.GraphikMedium,
                }),
            );

            // add a margin line
            chartContainerDim.y += 10;
            chartContainer.appendChild(
                createSvgNode('line', {
                    x1: `${chartContainerDim.x}`,
                    y1: `${chartContainerDim.y}`,
                    x2: `${svgWidth}`,
                    y2: `${chartContainerDim.y}`,
                    stroke: '#74828D',
                    'stroke-width': '1',
                }),
            );
        }

        //add chart
        chartContainerDim.y += 10;
        const highchartSvgStr = chart
            .replace('<svg', `<g transform="translate(${chartContainerDim.x},${chartContainerDim.y})" `)
            .replace('</svg>', '</g>');
        const highchartSvgEle = new DOMParser().parseFromString(highchartSvgStr, 'image/svg+xml').documentElement;
        chartContainer.appendChild(highchartSvgEle);
        chartGroup.appendChild(chartContainer);

        if (orientation === Orientations.vertical) {
            chartGroupDim.y += chartContainerDim.y + svgHeight;
        } else {
            chartGroupDim.x += chartContainerDim.x + svgWidth;
        }

        if (chartIndex === highchartSvgArr.length - 1) {
            if (orientation === Orientations.vertical) {
                chartGroupDim.width += chartGroupDim.x + chartContainerDim.x + svgWidth + 10;
                chartGroupDim.height += chartGroupDim.y + 10;
            } else {
                chartGroupDim.width += chartGroupDim.x + 10;
                chartGroupDim.height += chartGroupDim.y + chartContainerDim.y + svgHeight + 10;
            }
        }
    });

    chartHeight += chartGroupDim.height + 40;
    chartWidth += chartGroupDim.width + 30;
    chartHeight += 25;
    chartWidth += 10;
    svg.appendChild(chartGroup);

    // add chart category data
    svg.appendChild(
        createTextNode(`Category: ${category}`, {
            x: `${chartWidth / 2 - 30}`,
            y: `${chartHeight - 50}`,
            'text-anchor': 'end',
            'font-size': '14px',
            fill: '##57626a',
        }),
    );

    // add chart timeframe data
    svg.appendChild(
        createTextNode(`Timeframe: ${timeframe}`, {
            x: `${chartWidth / 2 + 30}`,
            y: `${chartHeight - 50}`,
            'text-anchor': 'start',
            'font-size': '14px',
            fill: '##57626a',
        }),
    );

    // add chart as of date
    const formattedAsOfDate = getMomentObjectFrom(asOfDate || '').format('MM/DD/YYYY');
    svg.appendChild(
        createTextNode(`Data as of ${formattedAsOfDate}`, {
            x: `${chartWidth - 140}`,
            y: `${chartHeight - 16}`,
            'font-size': '11px',
            fill: 'rgb(118, 129, 140)',
        }),
    );

    // add fundynamix logo
    svg.appendChild(
        createSvgNode('image', {
            x: '30',
            y: `${chartHeight - 45}`,
            width: '125',
            height: '32',
            href: FUNDynamixLogo,
        }),
    );

    //set svg container dimensions
    svg.setAttribute('width', `${chartWidth}`);
    svg.setAttribute('height', `${chartHeight}`);

    Highcharts.downloadSVGLocal(svg.outerHTML, exportingOption, function () {
        console.log('Failed to export on client side');
    });
}
