import { MaterialICons } from '@cfra-nextgen-frontend/shared/src';
import SvgIcon from '@mui/material/SvgIcon';

export function GetWatchListIcon() {
    return (
        <SvgIcon viewBox='0 0 40 40' style={{ color: '#3078B5', fontSize: '15px' }}>
            <MaterialICons.Star />
        </SvgIcon>
    );
}
