import { RoundedTextButton } from '@cfra-nextgen-frontend/shared';
import { roundedTextButtonThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import { ETFCard, defaultEmptyCardText } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import FormAutocompletePicklist from '@cfra-nextgen-frontend/shared/src/components/Form/FormAutocompletePicklist';
import FormDraggablePicklist from '@cfra-nextgen-frontend/shared/src/components/Form/FormDraggablePicklist';
import {
    Components,
    StringKeyValueItemWithViewdata,
} from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { CustomViewEditorContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/customViewEditor/CustomViewEditorContext';
import {
    componentToFilterDivider,
    getViewDataForColumn,
    handleDefaultSelected,
    sortByOrder,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { ScreenerViewContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerViewContext/Context';
import { ScreenerEtfData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { scrollbarThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UseQueryResult } from 'react-query';
import { getScreenerData } from '../api/screener';

type CustomViewEditorFormProps = {
    analyticsCardName: string;
};

const gridSpacing = 28;

function Column(props: { children: React.ReactNode; sx?: SxProps }) {
    return (
        <Grid
            item
            sx={{
                height: '100%',
                width: `calc(100%/2 - ${gridSpacing / 2}px)`,
                ...props.sx,
            }}>
            {props.children}
        </Grid>
    );
}

export function CustomViewEditorForm({ analyticsCardName }: CustomViewEditorFormProps) {
    const { screenerViewActionDispatcher } = useContext(ScreenerViewContext);
    const {
        customViewEditorState: { isDirtyRightColumn, resetColumnsState },
        customViewEditorStateDispatcher,
    } = useContext(CustomViewEditorContext);
    const columnsDataUseQueryResult = getScreenerData({
        view: 'fields',
        includeData: false,
        includeMetadata: true,
    }) as UseQueryResult<ScreenerEtfData>;

    const [columnsData, setColumnsData] = useState<ScreenerEtfData | undefined>(undefined);
    const options: Array<StringKeyValueItemWithViewdata> = useMemo(() => {
        const _options: Array<StringKeyValueItemWithViewdata> = [];
        if (!columnsData) {
            return _options;
        }

        columnsData._metadata.fields.forEach((fieldObject) => {
            Object.keys(fieldObject).forEach((key) => {
                const viewData = getViewDataForColumn(key, columnsData._viewdata.fields);
                _options.push({
                    key: key,
                    value: fieldObject[key].label,
                    order: viewData.order,
                    default_selected: viewData.default_selected,
                    source_field: fieldObject[key].source_field,
                });
            });
        });

        return sortByOrder(_options);
    }, [columnsData]);

    const defaultRightColumnOptions: Array<StringKeyValueItemWithViewdata> = useMemo(() => {
        // get values with default_selected: true, sorted by view data order, and replace the order with new values starting from 1
        return handleDefaultSelected('extract', options).map((value, index) => ({ ...value, order: index + 1 }));
    }, [options]);

    // keep the comment here to avoid the hard to detect issue in the feature: if import isValid property from formState, it causes 900+ unnecessary validation triggers, it influence on performance
    const { control, getValues, handleSubmit, setValue } = useForm({
        reValidateMode: 'onSubmit',
    });
    const submitHandler: (fieldsData: Object) => void = useCallback(
        (fieldsData) =>
            handleSubmit(() => {
                screenerViewActionDispatcher({
                    type: 'SetScreenerSaveView',
                    newState: {
                        screenerSaveView: {
                            key: 'custom',
                            fieldsData: fieldsData,
                        },
                    },
                });
                customViewEditorStateDispatcher({
                    type: 'OpenNameViewModalInSaveMode',
                });
            })(),
        [handleSubmit, customViewEditorStateDispatcher, screenerViewActionDispatcher],
    );

    const rightColumnLabel = 'Active Columns';
    const rightColumnFormElementName = `${Components.DraggablePicklist}${componentToFilterDivider}${rightColumnLabel}`;
    const leftColumnLabel = 'Add Columns';
    const leftColumnFormElementName = `${Components.AutocompletePicklist}${componentToFilterDivider}${leftColumnLabel}`;

    useEffect(() => {
        if (resetColumnsState) {
            customViewEditorStateDispatcher({
                type: ['SetResetColumnsDone', 'SetRightColumnClean'],
            });
            setValue(leftColumnFormElementName, []);
            setValue(rightColumnFormElementName, defaultRightColumnOptions);
        }
    }, [
        resetColumnsState,
        customViewEditorStateDispatcher,
        setValue,
        defaultRightColumnOptions,
        leftColumnFormElementName,
        rightColumnFormElementName,
    ]);

    const leftColumn: React.ReactNode | null = useMemo(() => {
        return (
            <FormAutocompletePicklist
                label={leftColumnLabel}
                control={control}
                name={leftColumnFormElementName}
                options={handleDefaultSelected('exclude', options)}
                placeholder={'Search Filters'}
                onChange={(leftColumnValues: Array<StringKeyValueItemWithViewdata>) => {
                    const oldRightColumnValues = getValues(
                        rightColumnFormElementName,
                    ) as Array<StringKeyValueItemWithViewdata>;

                    const theGreatestRightColumnOrder = Math.max(...oldRightColumnValues.map((value) => value.order));

                    const newRightColumnValues = sortByOrder(
                        leftColumnValues.map((value) => {
                            const oldOrder =
                                oldRightColumnValues.find((oldValue) => oldValue.key === value.key)?.order ||
                                theGreatestRightColumnOrder + 1;
                            return { ...value, order: oldOrder };
                        }),
                    );

                    setValue(rightColumnFormElementName, defaultRightColumnOptions.concat(newRightColumnValues));

                    if (newRightColumnValues.length > 0 && !isDirtyRightColumn) {
                        customViewEditorStateDispatcher({
                            type: 'SetRightColumnDirty',
                        });
                    }

                    if (newRightColumnValues.length === 0 && isDirtyRightColumn) {
                        customViewEditorStateDispatcher({
                            type: 'SetRightColumnClean',
                        });
                    }
                }}
            />
        );
    }, [
        control,
        options,
        rightColumnFormElementName,
        defaultRightColumnOptions,
        getValues,
        setValue,
        leftColumnFormElementName,
        isDirtyRightColumn,
        customViewEditorStateDispatcher,
    ]);

    const rightColumn: React.ReactNode | null = useMemo(() => {
        return (
            <FormDraggablePicklist
                label={rightColumnLabel}
                control={control}
                name={rightColumnFormElementName}
                defaultValues={defaultRightColumnOptions}
                onChange={(newValue: Array<StringKeyValueItemWithViewdata>) => {
                    const newValuesDefaultExcluded = handleDefaultSelected('exclude', newValue);
                    setValue(leftColumnFormElementName, newValuesDefaultExcluded);
                    if (newValuesDefaultExcluded.length === 0 && isDirtyRightColumn) {
                        customViewEditorStateDispatcher({
                            type: 'SetRightColumnClean',
                        });
                    }
                }}
            />
        );
    }, [
        control,
        rightColumnFormElementName,
        defaultRightColumnOptions,
        leftColumnFormElementName,
        setValue,
        customViewEditorStateDispatcher,
        isDirtyRightColumn,
    ]);

    if (!columnsData && columnsDataUseQueryResult.isLoading) {
        return <ETFCard isLoading={columnsDataUseQueryResult.isLoading} />;
    }

    if (
        !columnsDataUseQueryResult.isLoading &&
        !(
            columnsDataUseQueryResult.data?._metadata &&
            columnsDataUseQueryResult.data._metadata.fields &&
            columnsDataUseQueryResult.data._metadata.fields.length > 0
        )
    ) {
        return <>{defaultEmptyCardText}</>;
    }

    if (!columnsData) {
        setColumnsData(columnsDataUseQueryResult.data);
        return <></>;
    }

    const viewNowButtonText = 'View Now';
    const saveViewButtonText = 'Save View';

    const getFieldsData = () =>
        (
            getValues(
                // get right column selections state
                rightColumnFormElementName,
            ) as Array<StringKeyValueItemWithViewdata>
        )
            .map((item) => ({
                // get api view data with new order values
                [item.source_field]: {
                    ...getViewDataForColumn(item.key, columnsData._viewdata.fields),
                    order: item.order,
                },
            }))
            .reduce(
                // transform data to API request body structure
                (accumulator, currentValue) => ({
                    ...accumulator,
                    ...currentValue,
                }),
                {},
            );

    return (
        <div style={{ overflow: 'auto', flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Grid container sx={{ overflow: 'auto', flexGrow: 1, ...scrollbarThemeV2 }}>
                <Column sx={{ marginRight: `${gridSpacing}px` }}>{leftColumn}</Column>
                <Column> {rightColumn}</Column>
            </Grid>
            <Box sx={{ flex: '0 0 auto', display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
                <Box sx={{ paddingTop: '18px' }}>
                    <RoundedTextButton
                        outlined
                        theme={roundedTextButtonThemeV2}
                        buttonText={viewNowButtonText}
                        onClickCallback={() => {
                            globalThis.analytics?.registerAction?.({
                                action: `click on ${viewNowButtonText}`,
                                cardName: analyticsCardName,
                            });
                            if (isDirtyRightColumn) {
                                const view = {
                                    key: 'custom',
                                    fieldsData: getFieldsData(),
                                    isCurrentView: true,
                                };
                                screenerViewActionDispatcher({
                                    type: ['SetScreenerActiveView', 'SetScreenerCurrentView'],
                                    newState: {
                                        screenerActiveView: view,
                                        screenerCurrentView: view,
                                    },
                                });
                                customViewEditorStateDispatcher({
                                    type: ['SetResetColumnsDone', 'SetRightColumnClean', 'CloseCustomViewEditor'],
                                });
                                // reset columns directly, to avoid additional re-render due to changing resetColumnsState
                                setValue(leftColumnFormElementName, []);
                                setValue(rightColumnFormElementName, defaultRightColumnOptions);
                            } else {
                                customViewEditorStateDispatcher({
                                    type: 'OpenNoColumnsSelectedModal',
                                });
                            }
                        }}
                    />
                </Box>
                <Box sx={{ paddingLeft: '15px', paddingTop: '18px' }}>
                    <RoundedTextButton
                        theme={roundedTextButtonThemeV2}
                        buttonText={saveViewButtonText}
                        onClickCallback={() => {
                            globalThis.analytics?.registerAction?.({
                                action: `click on ${saveViewButtonText}`,
                                cardName: analyticsCardName,
                            });
                            if (isDirtyRightColumn) {
                                submitHandler(getFieldsData());
                            } else {
                                customViewEditorStateDispatcher({
                                    type: 'OpenNoColumnsSelectedModal',
                                });
                            }
                        }}
                    />
                </Box>
            </Box>
        </div>
    );
}
