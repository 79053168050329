import { MSA_ATHENA_URL } from './config/index';
import { MSA_MICROSERVICES_URL } from './config';
import Axios from 'axios';
import { storage } from 'utils';

const axios = Axios.create({
    baseURL: MSA_MICROSERVICES_URL,
    headers: {
        'Content-type': 'application/json',
    },
    withCredentials: true,
});

axios.interceptors.response.use(
    (response) => {
        if (response.data.reload === true) return axios(response.config);
        return response;
    },
    (error) => {
        const originalConfig = error.config;
        if ([401, 403, 500].includes(error.response.status)) {
            storage.clearToken();
        }
        if (error.response.status === 401) {
            window.location.href = `${MSA_ATHENA_URL!}?redirectUrl=${window.location.href}`;
        }
        if (error.response.status === 403) {
            window.location.href = MSA_ATHENA_URL!;
        }
        if (error.response.status === 500 && !originalConfig._retry) {
            originalConfig._retry = true;
            console.warn('Refresh token was expired.');
            window.location.reload();
        }
        if (error.response.status === 500 && originalConfig._retry) {
            console.warn('500: retry');
            window.location.href = MSA_ATHENA_URL!;
        }
    },
);

export default axios;
