import { getCompanyDetailsLinkRenderer } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/renderers';
import {
    IViewDataFields,
    ScreenerEtfData,
    ViewdataFieldDefinition,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { AgGridColDefExcelFormat } from '@cfra-nextgen-frontend/shared/src/components/excelExport/type';
import { MetadataFieldDefinition } from '@cfra-nextgen-frontend/shared/src/components/types/fieldMetadata';
import { ColDef } from 'ag-grid-community';
import { getExcelNumberFormat } from './excelExport';
import { defaultHeaderTemplate, fillTemplate } from './templates';
import { getScreenerValueFormatter } from './valueFormatters';
import { getScreenerNumberFilterValueGetter } from './valueGetters';

function sortViewdataAsc(viewdataFields: Array<IViewDataFields>): Array<IViewDataFields> {
    return viewdataFields.sort((n1, n2) => Object.values(n1)[0].order - Object.values(n2)[0].order);
}

function getHeaderTemplate(header_template?: string, symbol?: string) {
    return !header_template && symbol === '$' ? defaultHeaderTemplate : header_template;
}

function getHeaderName(fieldMetadata: MetadataFieldDefinition, headerTemplate?: string) {
    if (headerTemplate) {
        return fillTemplate({
            template_name: 'header_template',
            template: headerTemplate,
            fieldMetadata: fieldMetadata,
        });
    }

    return fieldMetadata.label;
}

export function extractFromScreenerData(
    screenerData: ScreenerEtfData,
    cardName: string,
): {
    minWidths: Record<string, number>;
    customFlexibleColumns: Array<string>;
    columnDefs: Array<ColDef & AgGridColDefExcelFormat>;
} {
    const minWidths: Record<string, number> = {};
    const customFlexibleColumns: Array<string> = [];

    function fillMinWidth(fieldViewdata: ViewdataFieldDefinition, headerName: string) {
        if (fieldViewdata.min_width && typeof fieldViewdata.min_width === 'number') {
            minWidths[headerName] = fieldViewdata.min_width;
        }
    }

    function fillCustomFlexibleColumns(fieldViewdata: ViewdataFieldDefinition, headerName: string) {
        if (typeof fieldViewdata.flexible === 'boolean' && fieldViewdata.flexible) {
            customFlexibleColumns.push(headerName);
        }
    }

    const columnDefs = sortViewdataAsc(screenerData._viewdata.fields).map((fieldDict) => {
        const field = Object.keys(fieldDict)[0];
        const fieldViewdata = fieldDict[field];
        const fieldMetadata = screenerData._metadata.fields.filter((dict) => Object.keys(dict)[0] === field)[0][field];

        const headerTemplate = getHeaderTemplate(fieldMetadata.header_template, fieldMetadata.symbol);
        const headerName = getHeaderName(fieldMetadata, headerTemplate);

        fillMinWidth(fieldViewdata, headerName);
        fillCustomFlexibleColumns(fieldViewdata, headerName);

        const result = {
            headerName: headerName,
            field: field,
            filter: 'agTextColumnFilter',
            cellRenderer: fieldViewdata.link
                ? getCompanyDetailsLinkRenderer({
                      compositeTickerPath: 'fund_profile.composite_ticker',
                      exchangeCodePath: 'fund_profile.exchange_code',
                      cardName: cardName,
                      linkPath: 'url_link.pattern',
                  })
                : undefined,
            valueFormatter: getScreenerValueFormatter(fieldMetadata),
        };

        if (fieldMetadata.type === 'number') {
            return {
                ...result,
                type: 'rightAligned',
                filter: 'agNumberColumnFilter',
                filterValueGetter: getScreenerNumberFilterValueGetter(fieldMetadata),
                excelExportNumberFormat: getExcelNumberFormat(fieldMetadata),
            };
        }

        return result;
    });

    return { minWidths, customFlexibleColumns, columnDefs };
}
