import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ScreenerViewContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerViewContext/Context';
import {
    CustomViewBaseData,
    CustomViewExtendedData,
    ScreenerDefaultViews,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/views';
import { ssrmMaxRowsToFetch } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/Constants';
import { Tabs } from '@cfra-nextgen-frontend/shared/src/components/Tabs';
import { SxProps } from '@mui/material';
import { getAllCustomViews, getCustomViewsDetails } from 'features/etfScreener/api/customViews';
import { SavedViewMenu } from 'features/etfScreener/components/SavedViewsMenu';
import { useContext, useEffect } from 'react';
import { ETFSearchByParams, prefetchApiData, sortByDateString } from 'utils';
import { getScreenerData, getScreenerReqBody } from '../api/screener';
import { SaveViewModal } from './SaveViewModal';

function prefetchScreenerData(requestArgsList: Array<ETFSearchByParams>) {
    prefetchApiData({
        requestArgsList: requestArgsList,
        requestCallback: ({ requestBody, view }: ETFSearchByParams) => {
            getScreenerData({
                requestBody,
                view,
                ssrmStartRowIndex: 0,
                ssrmRowsToFetch: ssrmMaxRowsToFetch,
                usePrefetchQuery: true,
            });
        },
    });
}

export function ScreenerViews() {
    const {
        screenerViewState: { refetchPendingType, screenerCurrentView, screenerActiveView },
        screenerViewActionDispatcher,
    } = useContext(ScreenerViewContext);
    const { filtersPostData } = useContext(FiltersModalContext);
    const customViewsQueryResult = getAllCustomViews({});

    // refetch all custom views list after save new custom view
    useEffect(() => {
        if (refetchPendingType === 'all') {
            customViewsQueryResult.refetch();
            screenerViewActionDispatcher({ type: 'SetRefetchDone' });
        }
    }, [customViewsQueryResult, screenerViewActionDispatcher, refetchPendingType]);

    const defaultViews = Object.values(ScreenerDefaultViews);

    const customViews: Array<CustomViewBaseData> = [];
    // fill customViews only when general info about all views is loaded
    if (
        !customViewsQueryResult.isLoading &&
        customViewsQueryResult.data &&
        customViewsQueryResult.data.data &&
        customViewsQueryResult.data.data.length > 0
    ) {
        customViews.push(...customViewsQueryResult.data.data);
    }
    // fetch view details
    const customViewsDetailsQueryResults = getCustomViewsDetails({ savedItems: customViews.map((view) => view.id) });

    const isLoadedDetails = customViewsDetailsQueryResults.every(
        (customViewDetailsQueryResult) =>
            customViewDetailsQueryResult &&
            !customViewDetailsQueryResult.isLoading &&
            customViewDetailsQueryResult.data &&
            customViewDetailsQueryResult.data.data,
    );

    // refetch specific custom view after rename
    useEffect(() => {
        if (isLoadedDetails && typeof refetchPendingType === 'number') {
            customViewsDetailsQueryResults.find((query) => query.data?.data.id === refetchPendingType)?.refetch();
            screenerViewActionDispatcher({ type: 'SetRefetchDone' });
        }
    }, [
        customViewsQueryResult,
        screenerViewActionDispatcher,
        refetchPendingType,
        isLoadedDetails,
        customViewsDetailsQueryResults,
    ]);

    const customViewsDetails: Array<CustomViewExtendedData> = [];
    // fill customViewsDetails only after the details data requests is loaded
    if (isLoadedDetails) {
        customViewsDetailsQueryResults.forEach((customViewDetailsQueryResult) => {
            customViewsDetails.push(customViewDetailsQueryResult!.data!.data);
        });
        customViewsDetails.sort(sortByDateString('created_date', 'asc'));
    }

    // prefetch data for default views
    prefetchScreenerData(
        defaultViews.map((view) => ({
            requestBody: getScreenerReqBody(filtersPostData),
            view: view.key,
        })),
    );

    if (customViewsDetails.length > 0) {
        // prefetch custom views data
        prefetchScreenerData(
            customViewsDetails.map((view) => ({
                requestBody: getScreenerReqBody(filtersPostData, view.value),
                view: 'custom',
            })),
        );
    }

    if (screenerCurrentView?.fieldsData) {
        // prefetch current view data
        prefetchScreenerData([
            {
                requestBody: getScreenerReqBody(filtersPostData, screenerCurrentView.fieldsData),
                view: 'custom',
            },
        ]);
    }

    if (!screenerActiveView) {
        throw new Error('screenerActiveView is not set.');
    }

    const isCustomKey = screenerActiveView.key === 'custom';
    const typeofLabelIsString = typeof screenerActiveView.label === 'string';

    const isCurrentViewSelected = isCustomKey && !typeofLabelIsString;
    const isCustomViewSelected = isCustomKey && typeofLabelIsString;
    const isDefaultViewSelected = !isCustomKey && typeofLabelIsString;

    // get index of active tab based on screenerViewState
    function getActiveIndex(): number | false {
        if (!screenerActiveView) {
            throw new Error('screenerActiveView is not set.');
        }
        // don't set active tab until the custom views is loaded
        if (!isLoadedDetails) {
            return false;
        }
        // handle current view selected
        if (isCurrentViewSelected) {
            return defaultViews.length + customViewsDetails.length;
        }
        // handle custom view selected
        if (isCustomViewSelected) {
            const customViewIndex = customViewsDetails.map((view) => view.name).indexOf(screenerActiveView.label!);

            if (customViewIndex > -1) {
                return (
                    defaultViews.length + customViewsDetails.map((view) => view.name).indexOf(screenerActiveView.label!)
                );
            }
        }
        // handle default view selected
        if (isDefaultViewSelected) {
            return defaultViews.map((view) => view.label).indexOf(screenerActiveView.label!);
        }

        return false;
    }

    function onChange(e: any, value: number) {
        const target = e.target as Element;
        if (!target.classList.contains('MuiTab-root')) {
            return; // ignore click events from the 3 dots icon and all click events inside menu options and their ancestors
        }
        // handle default view selection
        if (value <= defaultViews.length - 1) {
            screenerViewActionDispatcher({
                type: 'SetScreenerActiveView',
                newState: {
                    screenerActiveView: defaultViews[value],
                },
            });
            return;
        }

        // handle current view selection
        if (value === defaultViews.length + customViewsDetails.length) {
            const view = {
                key: 'custom',
                fieldsData: screenerCurrentView?.fieldsData,
                isCurrentView: true,
            };
            screenerViewActionDispatcher({
                type: ['SetScreenerActiveView', 'SetScreenerCurrentView'],
                newState: {
                    screenerActiveView: view,
                    screenerCurrentView: view,
                },
            });
            return;
        }

        // handle custom view selection
        const customViewsIndex = value - defaultViews.length;
        screenerViewActionDispatcher({
            type: 'SetScreenerActiveView',
            newState: {
                screenerActiveView: {
                    key: 'custom',
                    label: customViewsDetails[customViewsIndex].name,
                    fieldsData: customViewsDetails[customViewsIndex].value,
                },
            },
        });
    }

    // add default and custom view tabs
    const tabItems: Array<string> = defaultViews
        .map((view) => view.label)
        .concat(customViewsDetails.map((item) => item.name));

    // add current view tab
    if (screenerCurrentView) {
        tabItems.push('Current View*');
    }

    // show current view tab label italic
    const currentViewTabStyles = screenerCurrentView
        ? ({
              '& .MuiTab-root:last-of-type': {
                  fontStyle: 'italic',
                  paddingRight: '2px',
              },
          } as SxProps)
        : undefined;

    return (
        <>
            <Tabs
                ariaLabel={'screener tabs'}
                activeTab={getActiveIndex()}
                onChange={onChange}
                tabItems={tabItems}
                tabWithRightDividerIndex={
                    // show divider only if present custom or current views
                    customViewsDetails.length > 0 || screenerCurrentView ? defaultViews.length - 1 : undefined
                }
                sx={currentViewTabStyles}
                getTabIcon={(tabName, index) => {
                    if (index < defaultViews.length) {
                        return;
                    }

                    return (
                        <SavedViewMenu
                            tabName={tabName}
                            tabIndex={index}
                            defaultViews={defaultViews}
                            customViewsDetails={customViewsDetails}
                        />
                    );
                }}
                indicatorWidthAfterDividerTab={'calc(100% - 24px)'}
            />
            <SaveViewModal existingViews={customViewsDetails.map((view) => view.name)} />
        </>
    );
}
