export const buildChartStacks = (series: Array<any>, analyticsCallback: (action: string) => void): Array<any> => {
    let roundingStyle = { borderRadiusTopLeft: '15%', borderRadiusTopRight: '15%' };
    let stackItems = [
        {
            key: '+',
            mapFn: function (dataItem: number | null) {
                return dataItem === null || dataItem >= 0 ? dataItem : null;
            },
        },
        {
            key: '-',
            mapFn: function (dataItem: number | null) {
                return dataItem !== null && dataItem < 0 ? dataItem : null;
            },
        },
    ];

    return series.reduce((stack, element, index) => {
        let series = stackItems
            .map((item) => {
                return {
                    ...element,
                    stack: item.key,
                    data: element.data.map(item.mapFn),
                    ...(index === 0 ? roundingStyle : {}),
                    ...(item.key === '-'
                        ? {
                              showInLegend: false,
                          }
                        : {}),
                    events: {
                        legendItemClick: function (
                            this: Highcharts.Series,
                            event: Highcharts.SeriesLegendItemClickEventObject,
                        ) {
                            event.preventDefault();
                            const isVisible = event.target.visible;
                            this.chart.series
                                .filter((serie) => serie.name === this.name)
                                .forEach((serie) => {
                                    serie.setVisible(!isVisible);
                                });
                            analyticsCallback(`legend clicks : ${this.name} : ${isVisible ? 'hide' : 'show'}`);
                        },
                    },
                };
            })
            .filter((chartElement: any) => chartElement.data.some((item: number | null) => item !== null));

        return stack.concat(series);
    }, []);
};
