import ETFChip from './CFRAChip';
import { ChipProps } from '@mui/material/Chip';

export type chipItem = {
    chipKey: string;
    chipLabel: string;
    chipProps: ChipProps;
};

type chipsProps = {
    chipItems: Array<chipItem>;
    maxLabelLength?: number;
};

const getDisplayLabel = (chipLabel: string, maxLength?: number): string => {
    if (maxLength) {
        return chipLabel.length > maxLength ? chipLabel.substring(0, maxLength) + '...' : chipLabel;
    }
    return chipLabel;
};

export function CFRAChips(props: chipsProps): JSX.Element {
    return (
        <>
            {props.chipItems.map((chip) => (
                <ETFChip
                    key={chip.chipKey}
                    {...chip.chipProps}
                    label={getDisplayLabel(chip.chipLabel, props.maxLabelLength)}
                />
            ))}
        </>
    );
}
