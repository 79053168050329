import Tooltip from '@cfra-nextgen-frontend/shared/src/components/dataDisplay/Tooltip';
import { tooltipTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { ThemeProvider } from '@mui/material';
import classNames from 'classnames';
import React, { CSSProperties, forwardRef } from 'react';
import './Action.scss';

export interface Props extends React.HTMLAttributes<HTMLButtonElement> {
    active?: {
        fill: string;
        background: string;
    };
    cursor?: CSSProperties['cursor'];
    tooltipText: string;
}

export const Action = forwardRef<HTMLButtonElement, Props>(
    ({ active, className, cursor, style, tooltipText, ...props }, ref) => {
        return (
            <ThemeProvider theme={tooltipTheme}>
                <Tooltip title={tooltipText}>
                    <button
                        ref={ref}
                        {...props}
                        className={classNames('Action', className)}
                        tabIndex={0}
                        style={
                            {
                                ...style,
                                cursor,
                                '--fill': active?.fill,
                                '--background': active?.background,
                            } as CSSProperties
                        }
                    />
                </Tooltip>
            </ThemeProvider>
        );
    },
);
