import { ValueTypes, formatPercentages } from '@cfra-nextgen-frontend/shared/src/utils';
import { AxisLabelsFormatterContextObject, Options, TooltipFormatterContextObject } from 'highcharts/highstock';
import { Chart, getMinAndMaxTick } from './Chart';
import { CustomExportsProps } from './ExportMenus';
import { BarChartOptions } from './Options';
import { ChartSeries } from './types';

export function BarChart({
    categories,
    series,
    title,
    subTitle,
    exportFileName,
    columnHeaderFormatter,
    enableExportMenusAndTitle,
    tooltipFormatter,
    xAxisLabelsFormatter,
    setChartRef,
    useMinValue = true,
    turnOnLegend = false,
    turnOnDataLabels = true,
    useHTML = false,
    plotOptionsBarGroupPadding,
    plotOptionsBarPointWidth,
    chartHeight,
    plotOptionsAnimations,
    customExports,
    exports,
}: {
    categories: Array<string>;
    series: Array<ChartSeries> | ChartSeries;
    title?: string;
    subTitle?: string;
    exportFileName?: string;
    columnHeaderFormatter?: (item: any, key: any) => string;
    tooltipFormatter: (this: TooltipFormatterContextObject) => string;
    xAxisLabelsFormatter?: (this: AxisLabelsFormatterContextObject) => string;
    useMinValue?: boolean; // if false min value is 0 by default
    turnOnLegend?: boolean;
    turnOnDataLabels?: boolean;
    useHTML?: boolean;
    enableExportMenusAndTitle?: boolean;
    setChartRef?: (value: any) => void;
    plotOptionsBarGroupPadding?: number;
    plotOptionsBarPointWidth?: number;
    chartHeight?: number;
    plotOptionsAnimations?: boolean;
    customExports?: CustomExportsProps;
    exports?: {
        asOfDate: string;
        ticker: string;
        etfName: string;
        columns: Map<string, ValueTypes>;
    };
}) {
    if (!Array.isArray(series)) series = [series];

    let resultSeries = series.map((value) => {
        return {
            ...value,
            dataGrouping: {
                enabled: false,
            },
            data: value.data.map((value) => formatPercentages(value as number | null)),
            type: 'bar',
            color: (series as Array<ChartSeries>).length > 1 ? value.color : '#366691',
            pointwidth: 16,
            borderRadiusTopLeft: '50%',
            borderRadiusTopRight: '50%',
        };
    });

    const dataLabelsFormatter = function (this: TooltipFormatterContextObject) {
        let result;
        if (this.y !== 0) result = this.y?.toFixed(2);
        else result = this.y;
        return `${result}%`;
    };

    let resultSeriesData: Array<number | null> = [];
    resultSeries.forEach((value) => {
        resultSeriesData = resultSeriesData.concat(value.data as Array<number | null>);
    });

    const tickInterval = 5;
    const [minTick, maxTick]: [number, number] = getMinAndMaxTick(tickInterval, resultSeriesData, useMinValue);

    const options: Options = BarChartOptions({
        categories,
        exportFileName,
        columnHeaderFormatter,
        tooltipFormatter,
        turnOnLegend,
        turnOnDataLabels,
        dataLabelsFormatter,
        xAxisLabelsFormatter,
        resultSeries,
        tickInterval,
        minTick,
        maxTick,
        useHTML,
        plotOptionsBarGroupPadding,
        plotOptionsBarPointWidth,
        chartHeight,
        plotOptionsAnimations,
    });

    return (
        <Chart
            title={title}
            subTitle={subTitle}
            options={options}
            enableExportMenusAndTitle={enableExportMenusAndTitle}
            setChartRef={setChartRef}
            exportFileName={exportFileName}
            customExports={customExports}
            exports={exports}
        />
    );
}
