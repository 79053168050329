import { Theme } from '@mui/material/styles';
import { CommonButton } from './CommonButton';
import { IButtonProps } from './types';
import Typography from '@mui/material/Typography';

type RoundedTextButtonTypes = IButtonProps & {
    theme: Theme;
    outlined?: boolean;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset';
};

export function RoundedTextButton(props: RoundedTextButtonTypes) {
    const { theme, buttonText, outlined, onClickCallback, ...rest } = props;

    return (
        <CommonButton
            theme={theme}
            content={buttonText}
            onClickCallback={onClickCallback}
            outlined={outlined}
            {...rest}
        />
    );
}

type RoundedIconTextButtonTypes = IButtonProps & {
    theme: Theme;
    outlined?: boolean;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset';
    icon?: JSX.Element;
};

export function RoundedIconTextButton(props: RoundedIconTextButtonTypes) {
    const { theme, buttonText, outlined, onClickCallback, icon, ...rest } = props;

    return (
        <CommonButton
            theme={theme}
            content={
                <>
                    {icon}
                    <Typography sx={{ paddingLeft: '8px' }} variant='button' display='inline'>
                        {buttonText}
                    </Typography>
                </>
            }
            onClickCallback={onClickCallback}
            outlined={outlined}
            {...rest}
        />
    );
}
