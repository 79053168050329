import {
    ExtractFnReturnType,
    QueryConfig,
    queryClient,
} from '@cfra-nextgen-frontend/shared/src/lib/react-query-client';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ApiNames, Environments, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { AxiosInstance } from 'axios';
import { API_URL, ENVIRONMENT_NAME, OPENSEARCH_URL, USER_MANAGEMENT_URL } from 'config';
import { QueryKey, UseQueryOptions, UseQueryResult, useQueries, useQuery } from 'react-query';
import { axiosAuth } from './api-common';

const axiosAPI = axiosAuth(API_URL, { showQueries: ENVIRONMENT_NAME !== Environments.Production });
const axiosOpenSearch = axiosAuth(OPENSEARCH_URL);
const axiosUserManagement = axiosAuth(USER_MANAGEMENT_URL);

async function getData<T>(
    requestQuery: string,
    requestType: RequestTypes,
    apiName: ApiNames,
    requestBody?: any,
): Promise<T> {
    function sendRequest(axiosInstance: AxiosInstance) {
        switch (requestType) {
            case RequestTypes.DELETE:
                return axiosInstance.delete(requestQuery);
            case RequestTypes.GET:
                return axiosInstance.get(requestQuery);
            case RequestTypes.POST:
                return axiosInstance.post(requestQuery, requestBody);
            case RequestTypes.PUT:
                return axiosInstance.put(requestQuery, requestBody);
        }
    }

    try {
        switch (apiName) {
            case ApiNames.Fundynamix:
                return (await sendRequest(axiosAPI)).data;
            case ApiNames.OpenSearch:
                return (await sendRequest(axiosOpenSearch)).data;
            case ApiNames.UserManagement:
                return (await sendRequest(axiosUserManagement)).data;
            default:
                throw new Error(`getData received unexpected API name - "${apiName}"`);
        }
    } catch (error) {
        console.error(requestQuery, error);
        return new Promise<T>((resolve, reject) => {
            resolve(undefined as T);
        });
    }
}

type QueryFnType = typeof getData;

export type ETFSearchByParams = {
    config?: QueryConfig<QueryFnType>;
} & SearchByParams;

export type UseDataOptions = {
    requestQuery: string;
    queryKey: QueryKey;
    config?: QueryConfig<QueryFnType>;
    useOpenSearch?: boolean;
    requestBody?: any;
    requestType?: RequestTypes;
    apiName?: ApiNames;
};

function getApiName({ apiName, useOpenSearch }: { apiName?: ApiNames; useOpenSearch?: boolean }) {
    if (apiName === undefined) {
        if (useOpenSearch) {
            return ApiNames.OpenSearch;
        } else {
            return ApiNames.Fundynamix;
        }
    }
    return apiName;
}

function getRequestType({ requestType, requestBody }: { requestType?: RequestTypes; requestBody?: any }) {
    if (requestType === undefined) {
        if (requestBody) {
            return RequestTypes.POST;
        } else {
            return RequestTypes.GET;
        }
    }
    return requestType;
}

function getFullRequestQuerry({ apiName, requestQuery }: { apiName: ApiNames; requestQuery: string }) {
    switch (apiName) {
        case ApiNames.Fundynamix:
            return `/etf-insights/etf-data/${requestQuery}`;
        case ApiNames.OpenSearch:
            return `/search/v1/${requestQuery}`;
        case ApiNames.UserManagement:
            return `/user/v1/${requestQuery}`;
        default:
            throw new Error(`getFullRequestQuerry received unexpected api name - "${apiName}"`);
    }
}

function getUseQueryOptions<T>(props: UseDataOptions): UseQueryOptions<T> {
    let { requestQuery, queryKey, config, useOpenSearch, requestBody, requestType, apiName } = props;

    apiName = getApiName({ apiName, useOpenSearch });
    requestType = getRequestType({ requestType, requestBody });
    requestQuery = getFullRequestQuerry({ apiName, requestQuery });

    return {
        queryKey: queryKey,
        queryFn: () => getData<T>(requestQuery, requestType!, apiName!, requestBody),
        notifyOnChangeProps: 'tracked',
        ...config,
    } as UseQueryOptions<T>;
}

export function UseMultipleData<T>(props: Array<UseDataOptions>): Array<UseQueryResult<T>> {
    return useQueries<Array<ExtractFnReturnType<QueryFnType>>>(
        props.map((props) => getUseQueryOptions<T>(props)),
    ) as Array<UseQueryResult<T>>;
}

export function UseData<T>(props: UseDataOptions): UseQueryResult<T> {
    return useQuery<ExtractFnReturnType<QueryFnType>>(
        getUseQueryOptions<T>(props) as UseQueryOptions,
    ) as UseQueryResult<T>;
}

export function prefetchQuery<T>({
    apiName,
    requestType,
    requestQuery,
    queryKey,
    useOpenSearch,
    requestBody,
}: UseDataOptions) {
    apiName = getApiName({ apiName, useOpenSearch });
    requestType = getRequestType({ requestType, requestBody });
    requestQuery = getFullRequestQuerry({ apiName, requestQuery });
    return queryClient.prefetchQuery({
        queryKey,
        queryFn: () => getData<T>(requestQuery, requestType!, apiName!, requestBody),
    });
}

export async function GetData<T>({
    apiName,
    requestType,
    requestQuery,
    useOpenSearch,
    requestBody,
}: UseDataOptions): Promise<T> {
    apiName = getApiName({ apiName, useOpenSearch });
    requestType = getRequestType({ requestType, requestBody });
    requestQuery = getFullRequestQuerry({ apiName, requestQuery });
    return getData<T>(requestQuery, requestType!, apiName!, requestBody);
}
