import { ReactComponent as PlusIcon } from '@cfra-nextgen-frontend/shared/src/assets/images/PlusIcon.svg';
import { IButtonProps } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/types';
import { SvgIcon } from '@mui/material';
import Typography from '@mui/material/Typography';
import { plusIconTextButtonTheme } from './ButtonsThemes';
import { CommonButton } from './CommonButton';

export function PlusIconTextButton(props: IButtonProps) {
    return (
        <CommonButton
            content={
                <>
                    <SvgIcon component={PlusIcon} viewBox='0 0 18 18' />
                    <Typography variant='button' display='inline'>
                        {props.buttonText}
                    </Typography>
                </>
            }
            onClickCallback={props.onClickCallback}
            theme={plusIconTextButtonTheme}
        />
    );
}
