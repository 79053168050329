import { ETFCard, NoInformationAvailable } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ScreenerFiltersChipPanel } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultPanelRow';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { getFiltersReactNodes } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/getFiltersReactNodes';
import {
    formatSavedFilterToDirtyData,
    getPostAndChipsData,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { SaveScreenContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/saveScreenerContext/Context';
import Grid from '@mui/material/Grid';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getFiltersData, getFiltersReqBody } from '../api/filters';

type FiltersFormProps = {
    analyticsCardName: string;
    formHeight: string;
};

export function FiltersForm({ analyticsCardName, formHeight }: FiltersFormProps) {
    const { filtersPostData, setFiltersPostData } = useContext(FiltersModalContext);
    const filtersDataUseQueryResult = getFiltersData({ requestBody: getFiltersReqBody(filtersPostData) });
    const [filtersData, setFiltersData] = useState<FiltersData | undefined>(undefined);
    const [submittingData, setSubmittingData] = useState<{ [key: string]: any } | null>(null);
    const {
        chipStateManager: { chipState, chipStateDispatcher },
    } = useContext(ResultsContext);
    const {
        saveScreenState: { selectedScreenValue },
        saveScreenActionDispatcher,
    } = useContext(SaveScreenContext);

    // keep the comment here to avoid the hard to detect issue in the feature: if import isValid property from formState, it causes 900+ unnecessary validation triggers, it influence on performance
    const {
        control,
        formState: { dirtyFields, errors, isValidating },
        getValues,
        setValue,
        handleSubmit,
        trigger,
        resetField,
        reset,
    } = useForm({
        reValidateMode: 'onSubmit',
    });

    const validate: (fieldName: string) => Promise<boolean | undefined> = useCallback(
        async (fieldName: string) => {
            return await trigger?.(fieldName);
        },
        [trigger],
    );

    useEffect(() => {
        if (chipState.action === 'Clear') {
            reset();
            setFiltersPostData(undefined);
            chipStateDispatcher({ type: 'ClearAction' });
        }
    }, [reset, setFiltersPostData, chipState.action, chipStateDispatcher]);

    useEffect(() => {
        if (chipState.action === 'Dirty' && filtersData) {
            let dirtyData = chipState.field.dirtyData;
            let controlID = chipState.field.controlID;

            if (dirtyData[controlID] === undefined) {
                resetField(controlID);
            } else {
                setValue(controlID, dirtyData[controlID]);
            }

            const { postData, chipItems } = getPostAndChipsData(dirtyData, filtersData);

            setFiltersPostData(postData);
            chipStateDispatcher({ type: 'SetChipsData', newState: { chipItems: chipItems } });
            chipStateDispatcher({ type: 'SetFiltersDirtyData', newState: dirtyData });
        }
    }, [resetField, setFiltersPostData, filtersData, setValue, chipState.field, chipStateDispatcher, chipState.action]);

    useEffect(() => {
        if (filtersData && selectedScreenValue?.filters) {
            const dirtyData = formatSavedFilterToDirtyData(selectedScreenValue.filters, filtersData);

            Object.keys(dirtyData).forEach((controlID) => {
                setValue(controlID, dirtyData[controlID], {
                    shouldTouch: true,
                    shouldDirty: true,
                    shouldValidate: true,
                });
            });
            const { postData, chipItems } = getPostAndChipsData(dirtyData, filtersData);
            setFiltersPostData(postData);
            chipStateDispatcher({ type: 'SetChipsData', newState: { chipItems: chipItems } });
            chipStateDispatcher({ type: 'SetFiltersDirtyData', newState: dirtyData });
            saveScreenActionDispatcher({ type: 'SetSelectedScreenValue', payload: undefined });
        }
    }, [
        selectedScreenValue,
        chipStateDispatcher,
        setFiltersPostData,
        saveScreenActionDispatcher,
        setValue,
        filtersData,
        chipState.action,
    ]);

    useEffect(() => {
        const onSubmit = (data: any) => {
            const dirtyData = Object.keys(dirtyFields).reduce((result, current) => {
                return dirtyFields[current as keyof typeof dirtyFields]
                    ? { ...result, [current]: data[current as keyof typeof data] }
                    : result;
            }, {});

            if (!filtersData) {
                return;
            }
            const { postData, chipItems } = getPostAndChipsData(dirtyData, filtersData);

            setFiltersPostData(postData);
            chipStateDispatcher({ type: 'SetChipsData', newState: { chipItems: chipItems } });
            chipStateDispatcher({ type: 'SetFiltersDirtyData', newState: dirtyData });
        };

        if (!isValidating && Object.keys(errors).length === 0 && submittingData) {
            onSubmit(submittingData);
            setSubmittingData(null);
        }
    }, [isValidating, errors, submittingData, dirtyFields, filtersData, setFiltersPostData, chipStateDispatcher]);

    const submitHandler: () => void = useCallback(
        () => handleSubmit((data) => setSubmittingData(data))(),
        [handleSubmit],
    );

    const reactNodes: Array<React.ReactNode> | null = useMemo(() => {
        if (!filtersData) {
            return null;
        }

        return getFiltersReactNodes({
            filtersData: filtersData,
            control: control,
            analyticsCardName: analyticsCardName,
            getValues: getValues,
            validate: validate,
            submitHandler: submitHandler,
            setValue: setValue,
        });
    }, [analyticsCardName, control, filtersData, getValues, validate, submitHandler, setValue]);

    if (!filtersData && filtersDataUseQueryResult.isLoading) {
        return <ETFCard isLoading={filtersDataUseQueryResult.isLoading} />;
    }

    if (
        !filtersDataUseQueryResult.isLoading &&
        !(
            filtersDataUseQueryResult.data &&
            filtersDataUseQueryResult.data.filter_metadata &&
            filtersDataUseQueryResult.data.section_mapping
        )
    ) {
        return <NoInformationAvailable />;
    }

    if (!filtersData) {
        setFiltersData(filtersDataUseQueryResult.data);
        return <></>;
    }

    return (
        <Grid sx={{ width: '100%', height: formHeight, display: 'flex', flexDirection: 'column' }}>
            <ScreenerFiltersChipPanel key='ScreenerFilterChips' />
            <form
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'hidden',
                }}>
                {reactNodes}
            </form>
        </Grid>
    );
}
