import SearchIcon from '@cfra-nextgen-frontend/shared/src/assets/images/SearchIcon.svg';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, styled } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createMixins';

export const Root = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
}));

const InputWrapper = styled('div')(() => ({
    marginTop: '17px',
    marginBottom: '10px',
    borderBottom: '2px solid #9E9E9E',
    display: 'flex',
    '& input': {
        color: '#9E9E9E',
        fontFamily: fontFamilies.GraphikRegular,
        letterSpacing: '0.15px',
        fontSize: '16px',
        lineHeight: '24px',
        boxSizing: 'border-box',
        padding: '0px 0px 6px 8px',
        flexGrow: 1,
        border: 0,
        outline: 0,
    },
}));

const StyledIcon = styled('img')({
    width: '24px',
    height: '24px',
});

export type AutocompleteSearchProps<T> = {
    id: string;
    options: Array<T>;
    placeholder: string;
    defaultValues: Array<T>;
    label: string;
    onChange: (...event: any[]) => void;
    value: any;
};

type SearchBarProps = {
    focused: boolean;
    inputProps: React.InputHTMLAttributes<HTMLInputElement> & {
        ref: React.Ref<HTMLInputElement>;
    };
    placeholder: string;
    inputValue: string;
    setInputValue: (value: string) => void;
    setAnchorEl: () => void;
    rootProps: React.HTMLAttributes<HTMLDivElement>;
    inputStyle: CSSProperties;
};

export function SearchBar({
    focused,
    inputProps,
    placeholder,
    inputValue,
    setInputValue,
    setAnchorEl,
    rootProps,
    inputStyle,
}: SearchBarProps) {
    return (
        <div {...rootProps}>
            <InputWrapper style={inputStyle} ref={setAnchorEl} className={focused ? 'focused' : ''}>
                <StyledIcon src={SearchIcon} />
                <input
                    {...inputProps}
                    placeholder={placeholder}
                    onKeyDown={(event: any) => {
                        if (event.key === 'Backspace') {
                            event.stopPropagation();
                        }
                    }}
                />
                <IconButton
                    size='small'
                    onClick={() => setInputValue('')}
                    sx={inputValue ? {} : { visibility: 'hidden' }}>
                    <ClearIcon fontSize='inherit' />
                </IconButton>
            </InputWrapper>
        </div>
    );
}
