import { Layout } from '@cfra-nextgen-frontend/shared';
import { ResultRow } from '@cfra-nextgen-frontend/shared/src/components/Dropdown/Dropdown';
import { inputFontStyle } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/StyledFormLabel';
import { Item } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Checkbox, ThemeProvider, createTheme } from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { fontFamilies } from '../../utils';
import { CommonFormComponentProps } from './types/form';

const LISTBOX_PADDING = 8; // px

const ResultItem = styled(Layout.Grid)(({ theme }) => ({
    lineHeight: '20px',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
}));

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: (style.top as number) + LISTBOX_PADDING,
    };

    const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
    const checkedIcon = <CheckBoxIcon fontSize='small' />;
    const count = (dataSet[1] as Item).count;

    return (
        <li {...dataSet[0]} style={inlineStyle}>
            <ResultRow container alignItems='left'>
                <ResultItem item>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        sx={{
                            '& .MuiSvgIcon-root': {
                                width: '23px',
                                height: '23px',
                            },
                            width: '48px',
                            height: '48px',
                        }}
                        checked={dataSet[0]['aria-selected']}
                    />
                </ResultItem>
                <ResultItem
                    item
                    xs={8}
                    sx={{
                        paddingRight: '24px',
                    }}>
                    <Grid
                        sx={{
                            display: '-webkit-box',
                            WebkitLineClamp: '2',
                            wordWrap: 'break-word',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            ...inputFontStyle,
                        }}>
                        {(dataSet[1] as Item).value}
                    </Grid>
                </ResultItem>
                {count ? (
                    <ResultItem
                        item
                        sx={{ justifyContent: 'end', flexGrow: 1, paddingRight: '12px', ...inputFontStyle }}>
                        {(dataSet[1] as Item).count}
                    </ResultItem>
                ) : null}
            </ResultRow>
        </li>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(
    props,
    ref,
) {
    const { children, ...other } = props;

    const itemData: Array<React.ReactNode> = [];

    (children as React.ReactNode[]).forEach((item) => {
        itemData.push(item);
        if (!item) {
            return;
        }
        itemData.push(...((item as React.ReactNode & { children: Array<React.ReactNode> }).children || []));
    });

    const itemCount = itemData.length;
    const itemSize = 48;
    const gridRef = useResetCache(itemCount);

    const height = (itemCount > 10 ? 10 : itemCount) * itemSize + 2 * LISTBOX_PADDING;
    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={height}
                    width='100%'
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType='ul'
                    itemSize={() => itemSize}
                    overscanCount={5}
                    itemCount={itemCount}>
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

let theme = createTheme({
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: '#3078B5',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&:not(.Mui-focused)': {
                        top: '-11.5px', // center inactive label
                    },
                    ...inputFontStyle,
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    textTransform: 'capitalize',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    padding: '3.5px 5px 3.5px !important',
                },
                input: {
                    padding: '0px !important',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                popper: {
                    width: '300px !important',
                },
                input: {
                    '&::placeholder': {
                        ...inputFontStyle,
                    },
                },
                paper: {
                    filter: 'drop-shadow(0px 0px 7.68px rgba(0, 0, 0, 0.3))',
                    boxShadow: 'none',
                },
                listbox: {
                    padding: `${LISTBOX_PADDING}px 0px ${LISTBOX_PADDING}px`,
                    '::-webkit-scrollbar': {
                        width: '20px',
                    },
                    '::-webkit-scrollbar-thumb': {
                        backgroundColor: '#d1d8e8',
                    },
                    '::-webkit-scrollbar-track-piece': {
                        backgroundColor: '#f2f5fc',
                    },
                },
                option: {
                    padding: '0px 12px 0px 0px !important',
                    ':hover': {
                        backgroundColor: '#f7f6f7',
                    },
                    textTransform: 'capitalize',
                },
                popupIndicator: {
                    width: '24px',
                    height: '24px',
                },
                endAdornment: {
                    top: '2px',
                },
            },
        },
    },
});

type VirtualizeFormAutocompleteProps = {
    options: Array<Item>;
    placeholder: string;
    defaultValues: Array<number> | null;
    label: string;
    defaultInputLabel?: string;
} & CommonFormComponentProps;

export default function FormVirtualizeAutocomplete(props: VirtualizeFormAutocompleteProps) {
    const defaultValues = props.defaultValues
        ? props.options.filter((option) => props.defaultValues?.includes(option.key))
        : [];

    const unfocusedTextInputLabel = props.defaultInputLabel || 'Any';
    const [textInputLabel, setTextInputLabel] = useState(unfocusedTextInputLabel);

    return (
        <Controller
            name={props.name}
            control={props.control}
            defaultValue={defaultValues}
            render={({ field }) => (
                <ThemeProvider theme={theme}>
                    <Autocomplete
                        {...field}
                        popupIcon={<KeyboardArrowDownIcon />}
                        isOptionEqualToValue={(option, value) => option.key === value.key}
                        options={props.options}
                        getOptionLabel={(option) => option.value}
                        slotProps={{
                            popper: {
                                sx: { zIndex: 3000 },
                            },
                        }}
                        renderTags={(value: Array<any>, getTagProps: any) => {
                            if (value.length === 0) return;

                            if (value.length === 1) {
                                return <Chip label={value[0].value} onDelete={getTagProps(field.ref).onDelete} />;
                            }
                            return (
                                <div
                                    style={{
                                        color: '#007bb8',
                                        fontSize: '15px',
                                        fontFamily: fontFamilies.GraphikRegular,
                                    }}>
                                    {value.length} Selections
                                </div>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={field.value.length === 0 ? textInputLabel : ''}
                                placeholder={field.value.length === 0 ? props.placeholder : ''}
                                onFocus={() => setTextInputLabel(props.label)}
                                onBlur={() => setTextInputLabel(unfocusedTextInputLabel)}
                            />
                        )}
                        onChange={(_, data) => {
                            field.onChange(data);
                            props.submitHandler?.();
                        }}
                        value={field.value}
                        multiple
                        disableCloseOnSelect
                        disableListWrap
                        PopperComponent={StyledPopper}
                        sx={{ width: '100%', maxWidth: '125px' }}
                        ListboxComponent={ListboxComponent}
                        renderOption={(props, option, state) => [props, option, state.index] as React.ReactNode}
                    />
                </ThemeProvider>
            )}
        />
    );
}
