const store: {
    size: {
        height: number | undefined;
        width: number | undefined;
    };
} = {
    size: {
        height: undefined,
        width: undefined,
    },
};

export function getSnapshot() {
    if (store.size.height !== window.innerHeight || store.size.width !== window.innerWidth) {
        store.size = { height: window.innerHeight, width: window.innerWidth };
    }
    return store.size;
}

export function subscribe(callback: any) {
    window.addEventListener('resize', callback);
    return () => {
        window.removeEventListener('resize', callback);
    };
}
