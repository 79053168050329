import { ScreenerFormAutocompleteSearchTable } from './ScreenerFormAutocompleteSearchTable';
import ScreenerFormCheckbox from './ScreenerFormCheckbox';
import { ScreenerFormDateRangePicker } from './ScreenerFormDateRangePicker';
import { ScreenerFormInputSlider } from './ScreenerFormInputSlider';
import ScreenerFormSwitch from './ScreenerFormSwitch';
import { ScreenerFormVirtualizeAutocomplete } from './ScreenerFormVirtualizeAutocomplete';
import { FilterProps } from './types';

export const FilterComponentNameToComponent: Record<string, React.FC<FilterProps>> = {
    slider: ScreenerFormInputSlider,
    autocomplete: ScreenerFormVirtualizeAutocomplete,
    checkbox: ScreenerFormCheckbox,
    dropdown: ScreenerFormVirtualizeAutocomplete,
    daterangepicker: ScreenerFormDateRangePicker,
    switch: ScreenerFormSwitch,
    autocomplete_with_search_and_table: ScreenerFormAutocompleteSearchTable,
};
