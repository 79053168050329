import { useEffect, useState } from "react";

export function useHeightByElementId(id: string) {
  const [elementHeight, setElementHeight] = useState(0);

  useEffect(() => {
    const element = document.getElementById(id);
    if (element) {

      const handleResize = () => {
        setElementHeight(element.clientHeight);
      };

      handleResize();

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    } else {
      console.error(`Element with ID ${id} not found`);
    }
  }, [id]);

  return elementHeight;
}