import { MetadataFieldDefinition } from '@cfra-nextgen-frontend/shared/src/components/types/fieldMetadata';
import { getValueByPath } from '@cfra-nextgen-frontend/shared/src/utils';
import { ValueGetterFunc } from 'ag-grid-community';
import { formatNumber } from './valueFormatters';

export function getScreenerNumberFilterValueGetter(fieldMetadata: MetadataFieldDefinition): ValueGetterFunc {
    return function (params): number {
        if (!params.colDef.field) {
            throw new Error(`No field defined for column - ${JSON.stringify(params.colDef)}`);
        }

        return parseFloat(
            formatNumber({
                fieldMetadata: fieldMetadata,
                value: getValueByPath(params.data, params.colDef.field),
                applyCommas: false,
            }),
        );
    };
}
