import { CustomCheckbox } from '@cfra-nextgen-frontend/shared/src/components/ETFButton';
import { CommonFormComponentProps } from '@cfra-nextgen-frontend/shared/src/components/Form/types/form';
import { Controller } from 'react-hook-form';

type FormCheckboxProps = {
    defaultValue?: boolean;
} & CommonFormComponentProps;

export default function FormCheckbox(props: FormCheckboxProps) {
    const defaultValue = Boolean(props.getValues?.(props.name) || props.defaultValue);

    return (
        <Controller
            name={props.name}
            defaultValue={defaultValue}
            control={props.control}
            render={({ field }) => {
                return (
                    <CustomCheckbox
                        sx={{ padding: '0px', width: '24px', height: '24px' }}
                        {...field}
                        onChange={(_, data) => {
                            field.onChange(data);
                            props.submitHandler?.();
                        }}
                        checked={field.value}
                    />
                );
            }}
        />
    );
}
