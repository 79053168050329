/* eslint react/jsx-props-no-spreading: off */ // --> eslint says prop spreading fine for HOC

import { clientSideLogger } from 'lib/aws-rum-web-client';
import React from 'react';
import { ErrorBoundary as ReactErrorBoundary, ErrorBoundaryProps } from 'react-error-boundary';

// declare function cwr(operation: string, payload: any): void;

export default function ErrorBoundary(props: React.PropsWithChildren<ErrorBoundaryProps>) {
    const handleOnError = (
        error: Error,
        info: {
            componentStack: string;
        },
    ) => {
        // Record Error in CloudWatch RUM
        clientSideLogger.recordError(error);

        const { onError } = props;
        // Handle Error that was originally passed in as a prop
        if (onError) {
            onError(error, info);
        }
    };

    return <ReactErrorBoundary {...props} onError={handleOnError} />;
}
