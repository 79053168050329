export function getValueByPath(source: any, valuePath?: string) {
    if (!source || !valuePath) return undefined;

    const delimiter = '.';
    let keys: Array<string> = [valuePath];

    if (valuePath.includes(delimiter)) {
        keys = valuePath.split(delimiter);
    }

    let result = source;

    for (let index in keys) {
        const key = keys[index];
        if (!result.hasOwnProperty(key)) {
            result = undefined;
            break;
        }

        result = result[key];
    }

    return result;
}
