import { Categories, MarketTrendsDateRanges, getValueByPath } from '@cfra-nextgen-frontend/shared/src/utils';
import { ICellRendererParams } from 'ag-grid-community';

function getCompanyDetailsHref({
    cfraId,
    compositeTicker,
    exchangeCode,
}: {
    cfraId?: string;
    compositeTicker?: string;
    exchangeCode?: string;
}) {
    if (!cfraId && !compositeTicker && !exchangeCode) {
        return;
    }

    if (cfraId) {
        return `/etf/cfra-id/${cfraId}`;
    }

    return `/etf/ticker/${compositeTicker}/exchange/${exchangeCode}`;
}

export function getCompanyDetailsLinkRenderer({
    cfraIdPath,
    compositeTickerPath,
    exchangeCodePath,
    cardName,
    categoryLevel,
    dateRange,
    linkPath,
}: {
    cfraIdPath?: string;
    compositeTickerPath?: string;
    exchangeCodePath?: string;
    cardName: string;
    categoryLevel?: Categories;
    dateRange?: MarketTrendsDateRanges;
    linkPath?: string;
}) {
    return (props: ICellRendererParams) => {
        const value = props.valueFormatted || props.value;

        const cfraId = getValueByPath(props.data, cfraIdPath);
        const compositeTicker = getValueByPath(props.data, compositeTickerPath);
        const exchangeCode = getValueByPath(props.data, exchangeCodePath);

        const link = getValueByPath(props.data, linkPath);

        if (!cfraId && !compositeTicker && !exchangeCode) return value;

        return (
            <a
                onClick={() =>
                    globalThis.analytics?.registerAction?.({
                        action: `open company details link : ${props.colDef?.headerName}`,
                        cardName: cardName,
                        dateRange: dateRange,
                        ticker: compositeTicker,
                        etfName: props.data.composite_name,
                        etfAssetClass: props.data.asset_class,
                        cfraId: cfraId,
                        selectedCategory: categoryLevel,
                        etfExchange: exchangeCode,
                    })
                }
                href={link ? link : getCompanyDetailsHref({ cfraId, compositeTicker, exchangeCode })}
                target={'_blank'}
                rel='noreferrer'
                className='company-details-url'>
                {value}
            </a>
        );
    };
}
