export function getDataFromLocalStorage(key: string) {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : undefined;
}

export function setDataToLocalStorage(key: string, value: Object) {
    window.localStorage.setItem(key, JSON.stringify(value));
}

export function removeDataFromLocalStorage(key: string) {
    window.localStorage.removeItem(key);
}
