import { ETFCard, Hooks } from '@cfra-nextgen-frontend/shared';
import {
    Categories,
    MarketTrendsDateRanges,
    ValueTypes,
    categoriesToAggregateField,
    categoriesToResponceCategoryField,
    chartTypes,
    dateRangeToFlowToAssetRatioDataPoint,
    formatPercentages,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { CustomExportsProps } from 'components/Chart/ExportMenus';
import HighchartsReact from 'highcharts-react-official';
import { Axis } from 'highcharts/highstock';
import React, { useMemo } from 'react';
import { UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getAggregationFlows } from '../../api/market';
import { MarketTrend } from '../../types/market';
import { ETFFlowsToAssetThemesAndFactorsData } from '../../types/research';
import { dataLimitForAssetCategory, getChangeEvents } from './shared/ThemeFilters';

import { Layout } from '@cfra-nextgen-frontend/shared';
import { ThemesAndFactorsChart, onBarClick } from './shared/ThemesAndFactors';
import {
    downloadThemesAndFactorJpegCharts,
    getExportFileName,
    prefetchData,
    prefetchFunctionProps,
    sortDirection,
} from './shared/utils';

const localStorageID = 'market-trends-etf-flows-themes-and-factors-';

export default function ETFFlowsToAssetThemesAndFactors() {
    const navigate = useNavigate();
    const [highestChartRef, setHighestChartRef] = React.useState<React.RefObject<HighchartsReact.RefObject>>();
    const [lowestChartRef, setLowestChartRef] = React.useState<React.RefObject<HighchartsReact.RefObject>>();

    const [selectedCategory, setSelectedCategory] = Hooks.useLocalStorage<Categories>(
        localStorageID + 'selected-category',
        Categories.CategoryOne,
    );

    const [selectedDateRange, setSelectedDateRange] = Hooks.useLocalStorage<MarketTrendsDateRanges>(
        localStorageID + 'selected-date-range',
        MarketTrendsDateRanges.ThreeMonth,
    );

    const cardTitle = 'Themes & Factors: Flows to Assets';
    const subTitle = 'View the highest and lowest ETF categories by Flows to Assets ratio';

    const registerAction = (action: string) =>
        globalThis.analytics?.registerAction?.({
            action: action,
            cardName: cardTitle,
            dateRange: selectedDateRange,
            selectedCategory: selectedCategory,
        });

    const { handleCategoryChange, handleDateRangeChange } = getChangeEvents({
        setSelectedCategory: setSelectedCategory,
        setSelectedDateRange: setSelectedDateRange,
        analyticsCategoryChangeCallback: (category: Categories) => {
            registerAction(`category selection : ${category}`);
        },
        analyticsDateRangeChangeCallback: (dateRange: MarketTrendsDateRanges) => {
            registerAction(`date range selection : ${dateRange}`);
        },
    });

    const dataMapFunction = function (item: string) {
        return getAggregationFlows({
            sortDirection: item as sortDirection,
            orderBy: dateRangeToFlowToAssetRatioDataPoint[selectedDateRange],
            aggregateBy: categoriesToAggregateField[selectedCategory],
            top: selectedCategory === Categories.AssetClass ? dataLimitForAssetCategory : 5,
        }) as UseQueryResult<{ data: ETFFlowsToAssetThemesAndFactorsData[] }>;
    };

    let chartData = ['desc', 'asc'].map(dataMapFunction);

    if (selectedCategory === Categories.AssetClass) chartData = [chartData[0]];

    const detailsPathName = MarketTrend.FlowsToAssetsDetails;

    const onBarClickCallback = useMemo(
        () =>
            onBarClick<ETFFlowsToAssetThemesAndFactorsData>({
                selectedCategory,
                selectedDateRange,
                chartData,
                detailsPathName,
                navigate,
                cardTitle,
            }),
        [selectedCategory, selectedDateRange, chartData, detailsPathName, navigate],
    );

    // show card loading if data still loading
    if (chartData.find((data) => data.isLoading)) {
        return <ETFCard.ETFCard isLoading={true} />;
    }

    // Show Nothing if no data
    if (!chartData.every((item) => item.data && item.data.data?.length > 0)) {
        return <ETFCard.ETFEmptyCard cardLabel={cardTitle}></ETFCard.ETFEmptyCard>;
    }

    const yAxisColumnName = selectedDateRange + ' Flows to Assets Ratio';

    const columnsToFormatting = new Map<string, ValueTypes>();
    columnsToFormatting.set(selectedCategory, ValueTypes.Text);
    columnsToFormatting.set(yAxisColumnName, ValueTypes.Percentage);

    const asOfDate =
        chartData
            .flatMap((item) => item.data?.data)
            .map((item) => item?.as_of_date)
            .sort()
            .reverse()[0] || '';

    const getChartData = () => {
        return chartData.map((item, index) => {
            let chartData = item.data?.data?.map((value) =>
                formatPercentages(
                    value[dateRangeToFlowToAssetRatioDataPoint[selectedDateRange] as keyof typeof value] as number,
                ),
            );

            return {
                chartHeader:
                    selectedCategory === Categories.AssetClass
                        ? 'Flows to Assets Ratio'
                        : (index === 0 ? 'Highest' : 'Lowest') + ' Ratio',
                categories:
                    item.data?.data?.map(
                        (value) =>
                            `${
                                value[
                                    categoriesToResponceCategoryField[selectedCategory] as keyof typeof value
                                ] as string
                            }`,
                    ) || [],
                categoriesData: chartData || [],
                asOfDates: item.data?.data?.map((value) => value.as_of_date) || [],
                chartRefObject: index === 0 ? highestChartRef : lowestChartRef,
                chartRefSetter: index === 0 ? setHighestChartRef : setLowestChartRef,
                columnsToFormatting: columnsToFormatting,
            };
        });
    };

    const charts = getChartData()
        .map((item) => item?.chartRefObject?.current)
        .filter(Boolean) as Array<HighchartsReact.RefObject>;
    const exportFileName = getExportFileName('flows-to-asset-themes-and-factors', selectedCategory, selectedDateRange);
    const customExportsProps: CustomExportsProps = [
        {
            type: 'JPEG',
            callback: () =>
                downloadThemesAndFactorJpegCharts({
                    charts,
                    exportFileName,
                    chartName: chartTypes.FlowsToAssetsDetails,
                    title: cardTitle,
                    subTitle,
                    asOfDate,
                    category: selectedCategory,
                    timeframe: selectedDateRange,
                }),
        },
    ];

    prefetchData({
        selectedCategory,
        selectedDateRange,
        prefetchFunction: ({ category, dateRange, sortDirection }: prefetchFunctionProps) =>
            getAggregationFlows({
                sortDirection: sortDirection,
                orderBy: dateRangeToFlowToAssetRatioDataPoint[dateRange],
                aggregateBy: categoriesToAggregateField[category],
                top: category === Categories.AssetClass ? dataLimitForAssetCategory : 5,
                usePrefetchQuery: true,
            }),
    });

    function getColumnHeaderFormatter() {
        return function (item: any, key: any): string {
            if (item instanceof Axis && item.isXAxis) return selectedCategory;
            return yAxisColumnName;
        };
    }

    return (
        <ETFCard.ETFCard containerStyles={{ paddingBottom: '16px', position: 'relative' }}>
            <ThemesAndFactorsChart
                title={cardTitle}
                subHeader={subTitle}
                tooltipFormatterValuePrefix={`${yAxisColumnName}:`}
                columnHeaderFormatter={getColumnHeaderFormatter()}
                exportFileName={getExportFileName(
                    'flows-to-asset-themes-and-factors',
                    selectedCategory,
                    selectedDateRange,
                )}
                exportHeaders={[selectedCategory, selectedDateRange + ' Flows to Asset Ratio']}
                asOfDate={asOfDate}
                detailsPathName={detailsPathName}
                subChartsProps={getChartData()}
                dateRangesDropdownConfig={{
                    selectedDateRange: selectedDateRange,
                    handleChange: handleDateRangeChange,
                }}
                categoriesPanelConfig={{
                    selectedCategory: selectedCategory,
                    handleChange: handleCategoryChange,
                }}
                onBarClick={onBarClickCallback}
                customExports={customExportsProps}
            />
            <Layout.DataAsOfDate date={asOfDate} />
        </ETFCard.ETFCard>
    );
}
