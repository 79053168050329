import { ConfirmationModal } from '@cfra-nextgen-frontend/shared/src/components/Screener/ConfirmationModal';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';

type DeleteModalProps = {
    id?: number;
    onCancel: () => void;
    onConfirm: () => void;
    title: string;
};

export function DeleteModal(props: DeleteModalProps) {
    const { id, onCancel = () => {}, onConfirm = () => {} } = props;

    const deleteButtonText = 'Confirm';
    const cancelButtonText = 'Cancel';
    const analyticsCardName = `${props.title} confirmation popup`;

    return (
        <ConfirmationModal
            openModal={typeof id === 'number'}
            modalText={props.title}
            modalBoxStyles={{
                maxWidth: '480px',
                padding: '32px',
            }}
            descriptionStyle={{
                fontSize: '28px',
                fontWeight: 500,
                fonStyle: 'normal',
                lineHeight: 'normal',
                fontFamily: fontFamilies.GraphikMedium,
                color: '#0B2958',
                textAlign: 'center',
            }}
            buttonContainerStyles={{ padding: '26px 0 8px 0' }}
            cancelCallback={() => {
                globalThis.analytics?.registerAction?.({
                    action: `click on ${cancelButtonText}`,
                    cardName: props.title,
                });
                onCancel();
            }}
            cancelButtonText={cancelButtonText}
            confirmCallback={() => {
                globalThis.analytics?.registerAction?.({
                    action: `click on ${deleteButtonText}`,
                    cardName: analyticsCardName,
                });
                onConfirm();
            }}
            confirmButtonText={deleteButtonText}
        />
    );
}
