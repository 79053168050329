import { RoundedIconTextButton } from '@cfra-nextgen-frontend/shared';
import { plusIconTextButtonTheme } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import { GetWatchListIcon } from './WatchListIcon';
import { createTheme } from '@mui/material/styles';

export function WatchListModalOpenButton() {
    const buttonTheme = createTheme(plusIconTextButtonTheme, {
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        width: 'auto',
                    },
                },
            },
        },
    });

    return (
        <RoundedIconTextButton
            theme={buttonTheme}
            icon={GetWatchListIcon()}
            buttonText={'Add to Watchlist'}
            onClickCallback={() => {}}></RoundedIconTextButton>
    );
}
