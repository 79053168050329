import { Sortable } from '@cfra-nextgen-frontend/shared/src/components/DraggablePicklist/Sortable';
import { StringKeyValueItemWithViewdata } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { CommonFormComponentProps } from '@cfra-nextgen-frontend/shared/src/components/Form/types/form';

import { Controller } from 'react-hook-form';

type FormPicklistProps = {
    defaultValues: Array<StringKeyValueItemWithViewdata>;
    label: string;
    onChange: (newValues: Array<StringKeyValueItemWithViewdata>) => void;
} & CommonFormComponentProps;

export default function FormDraggablePicklist(props: FormPicklistProps) {
    return (
        <>
            <Controller
                name={props.name}
                control={props.control}
                defaultValue={props.defaultValues}
                render={({ field }) => {
                    return (
                        <Sortable
                            label={props.label}
                            options={field.value}
                            setOptions={(newValues: Array<StringKeyValueItemWithViewdata>) => {
                                field.onChange(newValues);
                                props.onChange(newValues);
                            }}
                            isPinned={(id) =>
                                Boolean(
                                    (field.value as Array<StringKeyValueItemWithViewdata>)[
                                        (field.value as Array<StringKeyValueItemWithViewdata>)
                                            .map((item) => item.key)
                                            .indexOf(id)
                                    ].default_selected,
                                )
                            }
                        />
                    );
                }}
            />
        </>
    );
}
