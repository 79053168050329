import { DateRanges, Frequencies } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import moment from 'moment';

export type SearchByParams = {
    cfraId?: string;
    id?: string;
    ticker?: string;
    exchange?: string;
    top?: number;
    numberOfYears?: number;
    cfraIds?: Array<string>;
    tickerExchanges?: Array<{ ticker: string; exchange: string }>;
    dateRange?: DateRanges;
    startDate?: string;
    endDate?: string;
    frequency?: string;
    orderBy?: string;
    sortDirection?: 'asc' | 'desc';
    filterBy?: Array<{ fieldName: string; value: string }>;
    fields?: Array<string>;
    aggregateBy?: string;
    usePrefetchQuery?: boolean;
    securityType?: string;
    view?: string;
    requestBody?: any;
    ssrmStartRowIndex?: number;
    ssrmRowsToFetch?: number;
    includeData?: boolean;
    includeMetadata?: boolean;
    types?: Array<number>;
    savedItems?: number | Array<number>;
};

export function getFrequency(dateRange: DateRanges): Frequencies {
    if ([DateRanges.OneWeek, DateRanges.OneMonth].includes(dateRange)) return Frequencies.Daily;
    if ([DateRanges.ThreeMonth, DateRanges.SixMonth, DateRanges.OneYear].includes(dateRange)) return Frequencies.Weekly;
    return Frequencies.Monthly;
}

function getDateRangeParameters(dateRange: DateRanges): string {
    const getDateString = (date: Date, dateFormat: string = 'YYYY-MM-DD') => moment.utc(date).format(dateFormat); // format date from Date type to string with format YYYY-MM-DD
    const todaysDate = new Date();
    todaysDate.setDate(todaysDate.getDate() - 1); // change to yesterdays date
    const endDate = getDateString(todaysDate);
    const frequency = getFrequency(dateRange);

    if ([DateRanges.OneWeek, DateRanges.OneMonth].includes(dateRange)) {
        todaysDate.setMonth(todaysDate.getMonth() - 1); // get 1 month before yesterday date
        const startDate = getDateString(todaysDate);
        const top = 5;
        return dateRange === DateRanges.OneWeek
            ? getKeyValuePairs({ endDate, top, frequency })
            : getKeyValuePairs({ startDate, endDate, frequency });
    }
    if ([DateRanges.ThreeMonth, DateRanges.SixMonth].includes(dateRange)) {
        let numberOfMonthBefore = dateRange === DateRanges.ThreeMonth ? 3 : 6;
        todaysDate.setMonth(todaysDate.getMonth() - numberOfMonthBefore); // get 3 or 6 month before yesterday date
        const startDate = getDateString(todaysDate);
        return getKeyValuePairs({ startDate, endDate, frequency });
    }
    if ([DateRanges.OneYear, DateRanges.ThreeYears, DateRanges.FiveYears].includes(dateRange)) {
        let numberOfYearsBefore = dateRange === DateRanges.OneYear ? 1 : dateRange === DateRanges.ThreeYears ? 3 : 5;
        todaysDate.setFullYear(todaysDate.getFullYear() - numberOfYearsBefore); // get 1 or 3 or 5 year before yesterday date
        const startDate = getDateString(todaysDate);
        return getKeyValuePairs({ startDate, endDate, frequency });
    }
    throw new Error('Invalid dateRange specified');
}

function getKeyValuePairs(params: SearchByParams): string {
    const {
        top,
        numberOfYears,
        startDate,
        endDate,
        frequency,
        cfraIds,
        tickerExchanges,
        dateRange,
        orderBy,
        sortDirection,
        filterBy,
        fields,
        aggregateBy,
        securityType,
        view,
        ssrmStartRowIndex,
        ssrmRowsToFetch,
        includeData,
        includeMetadata,
        types,
    } = params;
    let keyValuePairsQuery: string = '';
    const setKeyValuePair = (keyValuePair: string): void => {
        keyValuePairsQuery = Boolean(keyValuePairsQuery) ? `${keyValuePairsQuery}&${keyValuePair}` : keyValuePair;
    };

    if (top) setKeyValuePair(`top=${top}`);
    if (numberOfYears) setKeyValuePair(`number_of_years=${numberOfYears}`);
    if (dateRange) setKeyValuePair(getDateRangeParameters(dateRange));
    if (startDate) setKeyValuePair(`start_date=${startDate}`);
    if (endDate) setKeyValuePair(`end_date=${endDate}`);
    if (frequency) setKeyValuePair(`frequency=${frequency}`);
    if (cfraIds) setKeyValuePair(`cfra_ids=${cfraIds.join(',')}`);
    if (tickerExchanges)
        setKeyValuePair(
            `ticker-exchanges=${tickerExchanges.map((element) => `${element.ticker}-${element.exchange}`).join(',')}`,
        );
    if (orderBy) setKeyValuePair(`order_by=${orderBy}`);
    if (sortDirection) setKeyValuePair(`sort_direction=${sortDirection}`);
    if (filterBy)
        setKeyValuePair(`filter_by=${filterBy.map((element) => `${element.fieldName}:${element.value}`).join(',')}`);
    if (fields) setKeyValuePair(`fields=${fields.join(',')}`);
    if (aggregateBy) setKeyValuePair(`aggregate_by=${aggregateBy}`);
    if (securityType) setKeyValuePair(`security_type=${securityType}`);
    if (view) setKeyValuePair(`view=${view}`);
    if (ssrmStartRowIndex !== undefined && ssrmStartRowIndex >= 0) setKeyValuePair(`from=${ssrmStartRowIndex}`);
    if (ssrmRowsToFetch) setKeyValuePair(`size=${ssrmRowsToFetch}`);
    if (typeof includeData === 'boolean') {
        if (includeData) {
            setKeyValuePair(`include_data=1`);
        } else {
            setKeyValuePair(`include_data=0`);
        }
    }
    if (typeof includeMetadata === 'boolean') {
        if (includeMetadata) {
            setKeyValuePair(`include_metadata=1`);
        } else {
            setKeyValuePair(`include_metadata=0`);
        }
    }
    if (Array.isArray(types) && types.length > 0) setKeyValuePair(`types=${types.join(',')}`);

    return keyValuePairsQuery;
}

export function getRequestQuery(params: SearchByParams, path?: string): string {
    const { cfraId, ticker, exchange, cfraIds, tickerExchanges, savedItems } = params;

    const keyValuePairsQuery = getKeyValuePairs(params);

    let requestQuery = path ? path : '';
    if (cfraId) requestQuery += `cfra_id/${cfraId}`;
    else if (ticker && exchange) requestQuery += `ticker/${ticker}/exchange/${exchange}`;
    else if (cfraIds) requestQuery += `cfra_ids`;
    else if (tickerExchanges) requestQuery += `ticker-exchanges`;
    else if (savedItems) requestQuery += `saved-items/${savedItems}`;
    else if (keyValuePairsQuery === '') throw new Error('Parameters were not specified');

    return Boolean(keyValuePairsQuery) ? `${requestQuery}?${keyValuePairsQuery}` : requestQuery;
}
