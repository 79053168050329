import { defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import {
    AgGridSelectedRowsContext,
    AgGridSelectedRowsContextProvider,
} from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';
import { IServerSideGetRowsParams, SortModelItem } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/types';
import { ETFCard, NoInformationAvailable } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import {
    ScreenerResultsChipComponents,
    ScreenerResultsCountPanel,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultPanelRow';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { MainLabel } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsPanelRowStyle';
import { SaveScreenContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/saveScreenerContext/Context';
import { ScreenerViewContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerViewContext/Context';
import { IMetadataFields, ScreenerEtfData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import {
    ssrmMaxRowsToFetch,
    watchListColumnWidth,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/Constants';
import { extractFromScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import { Box, Stack, SxProps } from '@mui/material';
import { AgGirdCard, TopPanelRow } from 'components/AgGrid/AgGridCard';
import { CardWithAgGridTopPanel } from 'components/AgGrid/CardWithAgGridTopPanel';
import { ScreenerViews } from 'features/etfScreener/components/ScreenerViews';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { UseQueryResult } from 'react-query';
import { getScreenerData, getScreenerDataSSR, getScreenerReqBody } from '../api/screener';
import { CustomViewEditorModalOpenButton } from './CustomViewEditorModals';
import { FiltersModalOpenButton, SaveScreenButton } from './FiltersModal';
import { SavedScreenMenu } from './SavedScreensMenu';
import { SelectedScreenContainer } from './SelectedSaveScreen';
import { UpdateScreenButton } from './UpdateSavedScreen';
import { WatchListColumn, WatchListModalOpenButton, WatchListsMenu } from './watchLists';

type ScreenerCardProps = {
    cardName: string;
};

const getRowID = (params: any) => {
    return params.data.id;
};

const cardLabelProps: SxProps = {
    color: '#0B2958',
    fontWeight: 500,
    lineHeight: 'normal',
    fontStyle: 'normal',
    fontSize: '26px',
    paddingBottom: '3px',
};

const topLeftActionPanelStyles: SxProps = { padding: '16px 0' };

function TopPanelSlot1Content({ cardName }: { cardName: string }) {
    const {
        chipStateManager: { chipState },
    } = useContext(ResultsContext);

    const {
        saveScreenState: { selectedScreen, screenAction },
    } = useContext(SaveScreenContext);

    const chipComponents = ScreenerResultsChipComponents();

    const showChips = chipState?.chipItems && Object.keys(chipState?.chipItems).length > 0;
    const showSaveScreenBtn = showChips && typeof selectedScreen?.id !== 'number';
    const showUpdateScreenBtn = typeof selectedScreen?.id === 'number' && screenAction !== 'FETCH_SCREEN';

    return (
        <TopPanelRow>
            <Stack direction='row' flexWrap='wrap' display='flex' maxWidth={'100%'} alignItems='center' marginY={1}>
                <div key='ScreenerFilterButtonDiv' style={{ margin: '4px 18px 4px 0' }}>
                    <FiltersModalOpenButton cardName={cardName} />
                </div>
                <>
                    {showChips && chipComponents.chipList}
                    <Box display='flex' sx={{ mx: '6px' }}>
                        {showChips && chipComponents.clearButton}
                        {showSaveScreenBtn && <SaveScreenButton cardName={cardName} />}
                        {showUpdateScreenBtn && <UpdateScreenButton cardName={cardName} />}
                    </Box>
                </>
            </Stack>
        </TopPanelRow>
    );
}

function TopPanelSlot3Content() {
    const {
        chipStateManager: { chipState },
    } = useContext(ResultsContext);
    const { selectedRowsCount } = useContext(AgGridSelectedRowsContext);
    return selectedRowsCount > 0 ? (
        <Stack direction='row' flexWrap='wrap' display='flex' width={'100%'}>
            <div style={{ verticalAlign: 'middle', margin: 'auto 0px', paddingLeft: '30px' }}>
                <WatchListModalOpenButton />
            </div>
            <div style={{ paddingLeft: '2px' }}>
                <MainLabel
                    sx={{
                        fontWeight: '400',
                    }}>{`${selectedRowsCount} out of ${chipState.resultCount} Results selected`}</MainLabel>
            </div>
        </Stack>
    ) : (
        <ScreenerResultsCountPanel />
    );
}

const getSortFields = (sortModel: SortModelItem[], metadataFields: IMetadataFields[]) => {
    let querySort = {};

    let getSortQueryField = (fieldName: string) =>
        metadataFields.filter((dict) => Object.keys(dict)[0] === fieldName)[0][fieldName].source_field;

    if (sortModel.length > 0) {
        querySort = {
            sortDirection: sortModel.map((sort) => sort.sort).join(','),
            orderBy: sortModel.map((sort) => getSortQueryField(sort.colId)).join(','),
        };
    }
    return querySort;
};

const ssrDataExportFn = function ({
    metadataFields,
    view,
    requestBody,
}: {
    metadataFields: IMetadataFields[];
    view?: string;
    requestBody?: any;
}) {
    return function (sortColumns: SortModelItem[]) {
        return getScreenerDataSSR({
            requestBody: requestBody,
            view: view,
            ...getSortFields(sortColumns, metadataFields),
        }).then((response: any) => {
            return response?.results?.etf_data || [];
        });
    };
};

const getDataSource = ({
    metadataFields,
    etfData,
    requestBody,
    view,
}: {
    metadataFields: IMetadataFields[];
    etfData: Array<{
        [key: string]: any;
    }>;
    requestBody?: any;
    view?: string;
}) => ({
    getRows(params: IServerSideGetRowsParams) {
        if (
            params.request.sortModel.length === 0 &&
            (!params.request.startRow || (params.request.startRow && params.request.startRow === 0))
        ) {
            params.success({ rowData: etfData });
        } else {
            return getScreenerDataSSR({
                requestBody: requestBody,
                view: view,
                ssrmStartRowIndex: params.request.startRow,
                ssrmRowsToFetch: params.request.endRow,
                ...getSortFields(params.request.sortModel, metadataFields),
            }).then((response: any) => {
                if (response) {
                    params.success({ rowData: response.results.etf_data || [] });
                }
            });
        }
    },
});

export function ScreenerCard({ cardName }: ScreenerCardProps) {
    const { filtersPostData } = useContext(FiltersModalContext);
    const {
        chipStateManager: { chipStateDispatcher },
    } = useContext(ResultsContext);
    const {
        screenerViewState: { screenerActiveView },
    } = useContext(ScreenerViewContext);
    if (!screenerActiveView) {
        throw new Error('screenerActiveView is not set.');
    }

    const screenerRequestBody = useMemo(
        () => getScreenerReqBody(filtersPostData, screenerActiveView.fieldsData),
        [filtersPostData, screenerActiveView.fieldsData],
    );

    const getScreenerDataInputProps = useMemo(
        () => ({
            requestBody: screenerRequestBody,
            view: screenerActiveView.key,
            ssrmStartRowIndex: 0,
            ssrmRowsToFetch: ssrmMaxRowsToFetch,
        }),
        [screenerActiveView.key, screenerRequestBody],
    );
    const screenerData = getScreenerData(getScreenerDataInputProps) as UseQueryResult<ScreenerEtfData>;

    useEffect(() => {
        if (!chipStateDispatcher) {
            return;
        }

        if (screenerData.isLoading) {
            chipStateDispatcher({ type: 'SetResultCount', newState: { resultCount: -1 } });
            return;
        }

        chipStateDispatcher({
            type: 'SetResultCount',
            newState: {
                resultCount: screenerData.data?.results.total || 0,
            },
        });
    }, [screenerData.isLoading, screenerData.data, screenerData.data?.results.etf_data, chipStateDispatcher]);

    const topPanelSlot1Content = useMemo(() => <TopPanelSlot1Content cardName={cardName} />, [cardName]);

    const screenerViewTabs = useMemo(
        () => (
            <Box
                sx={{
                    display: 'flex',
                    width: 'calc(100% - 2 * 30px)',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    alignItems: 'center',
                    borderBottom: '1px solid #E4E5E9',
                }}>
                <Box sx={{ display: 'flex', overflowX: 'auto' }}>
                    <ScreenerViews />
                </Box>
                <Box sx={{ marginLeft: '20px' }}>
                    <CustomViewEditorModalOpenButton cardName={cardName} />
                </Box>
            </Box>
        ),
        [cardName],
    );
    const topPanelSlot3Content = useMemo(() => <TopPanelSlot3Content />, []);

    const topLeftActionPanelItems = useMemo(() => [<SelectedScreenContainer key='selectedScreen' />], []);

    const topRightActionPanelItems = useMemo(
        () => [<WatchListsMenu key='watchListMenu' />, <SavedScreenMenu key='savedScreenMenu' />],
        [],
    );

    const isNoInformationAvailable = useMemo(
        () =>
            !screenerData.data ||
            !screenerData.data._metadata ||
            !screenerData.data._metadata.fields ||
            screenerData.data._metadata.fields.length === 0 ||
            !screenerData.data._viewdata ||
            !screenerData.data._viewdata.fields ||
            screenerData.data._viewdata.fields.length === 0 ||
            !screenerData.data.results ||
            !screenerData.data.results.etf_data ||
            screenerData.data.results.etf_data.length === 0,
        [screenerData.data],
    );

    const { minWidths, customFlexibleColumns, columnDefs } = useMemo(() => {
        if (isNoInformationAvailable || !screenerData.data) {
            return { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };
        }
        const result = extractFromScreenerData(screenerData.data, cardName);
        result.columnDefs.unshift(WatchListColumn);
        return result;
    }, [cardName, isNoInformationAvailable, screenerData.data]);

    const getResizableMinWidthForColumn = useCallback(
        (headerName: string) =>
            headerName === 'undefined' ? watchListColumnWidth : minWidths[headerName] || defaultMinWidth,
        [minWidths],
    );

    const SSRDataExportFn = useMemo(
        () =>
            ssrDataExportFn({
                metadataFields: screenerData?.data?._metadata.fields || [],
                view: screenerActiveView.key,
                requestBody: screenerRequestBody,
            }),
        [screenerData?.data?._metadata.fields, screenerActiveView.key, screenerRequestBody],
    );

    const dataSource = useMemo(
        () =>
            getDataSource({
                metadataFields: screenerData?.data?._metadata.fields || [],
                etfData: screenerData?.data?.results.etf_data || [],
                requestBody: screenerRequestBody,
                view: screenerActiveView.key,
            }),
        [
            screenerData?.data?._metadata.fields,
            screenerData?.data?.results.etf_data,
            screenerRequestBody,
            screenerActiveView.key,
        ],
    );

    if (screenerData.isLoading) {
        return (
            <CardWithAgGridTopPanel
                label={cardName}
                topLeftActionPanelStyles={topLeftActionPanelStyles}
                topLeftActionPanelItems={topLeftActionPanelItems}
                topRightActionPanelItems={topRightActionPanelItems}
                topPanelSlot1Content={topPanelSlot1Content}
                topPanelSlot2Content={screenerViewTabs}
                labelProps={cardLabelProps}>
                <ETFCard isLoading={screenerData.isLoading} />
            </CardWithAgGridTopPanel>
        );
    }

    if (
        !screenerData.data ||
        !screenerData.data._metadata ||
        !screenerData.data._metadata.fields ||
        screenerData.data._metadata.fields.length === 0 ||
        !screenerData.data._viewdata ||
        !screenerData.data._viewdata.fields ||
        screenerData.data._viewdata.fields.length === 0 ||
        !screenerData.data.results ||
        !screenerData.data.results.etf_data ||
        screenerData.data.results.etf_data.length === 0
    ) {
        return (
            <CardWithAgGridTopPanel
                label={cardName}
                topLeftActionPanelStyles={topLeftActionPanelStyles}
                topLeftActionPanelItems={topLeftActionPanelItems}
                topRightActionPanelItems={topRightActionPanelItems}
                topPanelSlot1Content={topPanelSlot1Content}
                labelProps={cardLabelProps}>
                <NoInformationAvailable sx={{ width: '100%', paddingTop: '20px' }} />
            </CardWithAgGridTopPanel>
        );
    }

    return (
        <AgGridSelectedRowsContextProvider>
            <AgGirdCard
                label={cardName}
                columnDefs={columnDefs}
                useSSRMode={true}
                SSRDataSource={dataSource}
                SSRrowsToFetch={ssrmMaxRowsToFetch}
                SSRDataExportFn={SSRDataExportFn}
                rowMultiSelectWithClick={true}
                rowSelection='multiple'
                suppressRowClickSelection={true}
                getRowID={getRowID}
                customFlexibleColumns={customFlexibleColumns}
                getResizableMinWidthForColumn={getResizableMinWidthForColumn}
                excelExportAsOfDateField='fund_nav_data.as_of_date'
                topLeftActionPanelStyles={topLeftActionPanelStyles}
                topLeftActionPanelItems={topLeftActionPanelItems}
                topRightActionPanelItems={topRightActionPanelItems}
                topPanelSlot1Content={topPanelSlot1Content}
                topPanelSlot2Content={screenerViewTabs}
                topPanelSlot3Content={topPanelSlot3Content}
                labelProps={cardLabelProps}
            />
        </AgGridSelectedRowsContextProvider>
    );
}
