import { ETFCard, Hooks, Layout } from '@cfra-nextgen-frontend/shared';
import {
    Categories,
    MarketTrendsDateRanges,
    ValueTypes,
    categoriesToAggregateField,
    categoriesToResponceCategoryField,
    chartTypes,
    dateRangeToReturnSplitAndDividendDataPoint,
    formatPercentages,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { CustomExportsProps } from 'components/Chart/ExportMenus';
import HighchartsReact from 'highcharts-react-official';
import { Axis } from 'highcharts/highstock';
import React, { useMemo } from 'react';
import { UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getAggregationPerformance } from '../../api/market';
import { MarketTrend } from '../../types/market';
import { ETFPerformanceThemesAndFactorsData } from '../../types/research';
import { dataLimitForAssetCategory, getChangeEvents } from './shared/ThemeFilters';
import { ThemesAndFactorsChart, onBarClick, xAxisColumnName } from './shared/ThemesAndFactors';
import {
    downloadThemesAndFactorJpegCharts,
    getExportFileName,
    prefetchData,
    prefetchFunctionProps,
    sortDirection,
} from './shared/utils';

const localStorageID = 'market-trends-etf-performance-themes-and-factors-';

export default function ETFPerformanceThemesAndFactors() {
    const navigate = useNavigate();
    const [highestChartRef, setHighestChartRef] = React.useState<React.RefObject<HighchartsReact.RefObject>>();
    const [lowestChartRef, setLowestChartRef] = React.useState<React.RefObject<HighchartsReact.RefObject>>();

    const [selectedCategory, setSelectedCategory] = Hooks.useLocalStorage<Categories>(
        localStorageID + 'selected-category',
        Categories.CategoryOne,
    );

    const [selectedDateRange, setSelectedDateRange] = Hooks.useLocalStorage<MarketTrendsDateRanges>(
        localStorageID + 'selected-date-range',
        MarketTrendsDateRanges.ThreeMonth,
    );

    const cardTitle = 'Themes & Factors: Performance';
    const subTitle = 'ETF categories with the highest and lowest trailing total returns';

    const registerAction = (action: string) =>
        globalThis.analytics?.registerAction?.({
            action: action,
            cardName: cardTitle,
            dateRange: selectedDateRange,
            selectedCategory: selectedCategory,
        });

    const { handleCategoryChange, handleDateRangeChange } = getChangeEvents({
        setSelectedCategory: setSelectedCategory,
        setSelectedDateRange: setSelectedDateRange,
        analyticsCategoryChangeCallback: (category: Categories) => {
            registerAction(`category selection : ${category}`);
        },
        analyticsDateRangeChangeCallback: (dateRange: MarketTrendsDateRanges) => {
            registerAction(`date range selection : ${dateRange}`);
        },
    });

    const dataMapFunction = function (item: sortDirection) {
        return getAggregationPerformance({
            sortDirection: item,
            orderBy: dateRangeToReturnSplitAndDividendDataPoint[selectedDateRange],
            aggregateBy: categoriesToAggregateField[selectedCategory],
            top: selectedCategory === Categories.AssetClass ? dataLimitForAssetCategory : 5,
        }) as UseQueryResult<{ data: ETFPerformanceThemesAndFactorsData[] }>;
    };

    const sortDirections: Array<sortDirection> = ['desc', 'asc'];
    let chartData = sortDirections.map(dataMapFunction);
    if (selectedCategory === Categories.AssetClass) chartData = [chartData[0]];

    const detailsPathName = MarketTrend.PerformanceDetails;

    const onBarClickCallback = useMemo(() => {
        const categoriesContainTickers = true;
        return onBarClick<ETFPerformanceThemesAndFactorsData>({
            selectedCategory,
            selectedDateRange,
            chartData,
            detailsPathName,
            navigate,
            categoriesContainTickers,
            cardTitle,
        });
    }, [selectedCategory, selectedDateRange, chartData, detailsPathName, navigate]);

    // show card loading if data still loading
    if (chartData.find((data) => data.isLoading)) {
        return <ETFCard.ETFCard isLoading={true} />;
    }

    // Show Nothing if no data
    if (!chartData.every((item) => item.data && item.data?.data?.length > 0)) {
        return <ETFCard.ETFEmptyCard cardLabel={cardTitle}></ETFCard.ETFEmptyCard>;
    }

    // cut off UseQueryResult attributes, extract only KeyAssetClasses data
    const yAxisColumnName = selectedDateRange + ' Performance';

    const columnsToFormatting = new Map<string, ValueTypes>();
    columnsToFormatting.set(xAxisColumnName, ValueTypes.Text);
    columnsToFormatting.set(yAxisColumnName, ValueTypes.Percentage);

    const getChartData = () => {
        return chartData.map((item, index) => {
            let chartData = item.data?.data?.map((value) =>
                formatPercentages(
                    value[
                        dateRangeToReturnSplitAndDividendDataPoint[selectedDateRange] as keyof typeof value
                    ] as number,
                ),
            );

            return {
                chartHeader:
                    selectedCategory === Categories.AssetClass
                        ? 'Performance'
                        : (index === 0 ? 'Best' : 'Worst') + ' Performing',
                categories:
                    item.data?.data?.map(
                        (value) =>
                            `${
                                value[
                                    categoriesToResponceCategoryField[selectedCategory] as keyof typeof value
                                ] as string
                            } (${value.composite_ticker})`,
                    ) || [],
                categoriesData: chartData || [],
                asOfDates: item.data?.data?.map((value) => value.as_of_date) || [],
                tickers: item.data?.data?.map((value) => value.composite_ticker) || [],
                chartRefObject: index === 0 ? highestChartRef : lowestChartRef,
                chartRefSetter: index === 0 ? setHighestChartRef : setLowestChartRef,
                columnsToFormatting: columnsToFormatting,
            };
        });
    };

    const asOfDate =
        chartData
            .flatMap((item) => item.data?.data)
            .map((item) => item?.as_of_date)
            .sort()
            .reverse()[0] || '';

    const charts = getChartData()
        .map((item) => item?.chartRefObject?.current)
        .filter(Boolean) as Array<HighchartsReact.RefObject>;
    const exportFileName = getExportFileName('performance-themes-and-factors', selectedCategory, selectedDateRange);
    const customExportsProps: CustomExportsProps = [
        {
            type: 'JPEG',
            callback: () =>
                downloadThemesAndFactorJpegCharts({
                    charts,
                    chartName: chartTypes.PerformanceDetails,
                    exportFileName,
                    title: cardTitle,
                    subTitle,
                    asOfDate,
                    category: selectedCategory,
                    timeframe: selectedDateRange,
                }),
        },
    ];
    function getColumnHeaderFormatter() {
        return function (item: any, key: any): string {
            if (item instanceof Axis && item.isXAxis) return selectedCategory;
            return yAxisColumnName;
        };
    }

    prefetchData({
        selectedCategory,
        selectedDateRange,
        prefetchFunction: ({ category, dateRange, sortDirection }: prefetchFunctionProps) =>
            getAggregationPerformance({
                sortDirection: sortDirection,
                orderBy: dateRangeToReturnSplitAndDividendDataPoint[dateRange],
                aggregateBy: categoriesToAggregateField[category],
                top: category === Categories.AssetClass ? dataLimitForAssetCategory : 5,
                usePrefetchQuery: true,
            }),
    });

    return (
        <ETFCard.ETFCard containerStyles={{ paddingBottom: '16px', position: 'relative' }}>
            <ThemesAndFactorsChart
                title={cardTitle}
                subHeader={subTitle}
                tooltipFormatterValuePrefix={`${yAxisColumnName}:`}
                columnHeaderFormatter={getColumnHeaderFormatter()}
                exportFileName={getExportFileName(
                    'performance-themes-and-factors',
                    selectedCategory,
                    selectedDateRange,
                )}
                exportHeaders={[selectedCategory, selectedDateRange + ' Performance']}
                asOfDate={asOfDate}
                detailsPathName={detailsPathName}
                subChartsProps={getChartData()}
                dateRangesDropdownConfig={{
                    selectedDateRange: selectedDateRange,
                    handleChange: handleDateRangeChange,
                }}
                categoriesPanelConfig={{
                    selectedCategory: selectedCategory,
                    handleChange: handleCategoryChange,
                }}
                onBarClick={onBarClickCallback}
                customExports={customExportsProps}
            />
            <Layout.DataAsOfDate date={asOfDate} />
        </ETFCard.ETFCard>
    );
}
