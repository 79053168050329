import { Button } from '../../components/Button';

export default function ErrorFallback() {
    return (
        <div>
            <h2>Ooops, something went wrong :( </h2>
            <Button onClick={() => window.location.assign(window.location.origin)}>Refresh</Button>
        </div>
    );
}
