import { CSSProperties } from 'react';

interface CustomTabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    style?: CSSProperties;
}

export function CustomTabPanel(props: CustomTabPanelProps) {
    const { children, value, index, style, ...other } = props;
    const isHidden = value !== index;
    const visibilityRelatedStyle: CSSProperties = isHidden
        ? { display: 'none' }
        : { display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto' };
    return (
        <div
            role='tabpanel'
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ ...visibilityRelatedStyle, ...style }}
            {...other}>
            {children}
        </div>
    );
}
