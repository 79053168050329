import { ETFMenu } from '@cfra-nextgen-frontend/shared';
import { TransparentButtonChip } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsPanelRowStyle';
import { GetWatchListIcon } from './WatchListIcon';

export function WatchListsMenu() {
    const menuItemStyles = {
        padding: '0px 13px 0px 0px',
        ':not(:last-child)': {
            borderBottom: 0,
        },
    };

    return (
        <ETFMenu.ETFMenu menuStyles={{ padding: '11px 0' }} menuItemStyles={menuItemStyles} menuItems={[]}>
            <TransparentButtonChip key='My Watchlists' text='My Watchlists' startIcon={GetWatchListIcon()} />
        </ETFMenu.ETFMenu>
    );
}
