export const ScreenerDefaultViews: Record<string, { label: string; key: string }> = {
    Overview: {
        label: 'Overview',
        key: 'overview',
    },
    Flows: {
        label: 'Flows',
        key: 'flows',
    },
    Performance: {
        label: 'Performance',
        key: 'performance',
    },
    Fundamentals: {
        label: 'Fundamentals',
        key: 'fundamentals',
    },
};

export type CustomViewBaseData = {
    id: number;
    name: string;
    saved_item_lid: number;
    updated_date: string;
};

export type AllCustomViewsBaseDataResponse = {
    data: Array<CustomViewBaseData>;
};

export type CustomViewExtendedData = {
    user_id: number;
    value: Object;
    is_active: boolean;
    created_date: string;
} & CustomViewBaseData;

export type CustomViewsExtendedDataResponse = {
    data: CustomViewExtendedData;
};
