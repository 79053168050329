import { DataDisplay, ETFCard, Layout } from '@cfra-nextgen-frontend/shared';
import { ValueTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import {
    calcPercentChange,
    formatPercentages,
    getExportingFileName,
} from '@cfra-nextgen-frontend/shared/src/utils/valuesFormatter';
import {
    addAsOfDateToHighchart,
    addLogoToHighchart,
    exportChartAsImgWithFonts,
    getExportDefaultChartOptions,
} from 'components/Chart/Export';
import { CustomExportsProps } from 'components/Chart/ExportMenus';
import {
    HistoricalStackedPercentage,
    getSeries,
    getUniqueKeys,
    sortSeriesByList,
} from 'components/Chart/HistoricalStackedPercentage';
import { getMarginOptions, getSpacingOptions, getTooltipHTML } from 'components/Chart/Options';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { buildChartStacks } from 'utils';
import { getCreditExposureHistorical } from '../api/etfDetailsData';
import { ConstituentType, CreditExposureData, ETFDetailsParams } from '../types/research';

export default function CreditExposureHistorical({
    companyData,
    numberOfYears = 5,
    componentRendered,
}: {
    companyData: ETFDetailsParams;
    numberOfYears?: number;
    componentRendered?: boolean;
}) {
    let creditExposureHistoricalQueryResult = getCreditExposureHistorical({
        ticker: companyData.ticker,
        exchange: companyData.exchange,
        numberOfYears: numberOfYears,
    });

    if (creditExposureHistoricalQueryResult.isLoading) {
        return <ETFCard.ETFCard isLoading={creditExposureHistoricalQueryResult.isLoading} />;
    }
    const cardTitle = 'Credit Exposure Over Time';
    const subTitle = 'Historical credit rating data for ETF holdings by year';
    const footnote =
        'Credit rating information provided by S&P Global Ratings. Individual US sovereign securities not rated by S&P Global.';
    // return EmptyCard if no data
    if (
        !(
            creditExposureHistoricalQueryResult.data &&
            creditExposureHistoricalQueryResult.data.ratings &&
            creditExposureHistoricalQueryResult.data.ratings.length > 0 &&
            creditExposureHistoricalQueryResult.data.ratings.some((x) => Number.isFinite(x.agg_weighting))
        )
    )
        return <ETFCard.ETFEmptyCard cardLabel={cardTitle}></ETFCard.ETFEmptyCard>;

    let creditExposureData: CreditExposureData[] = creditExposureHistoricalQueryResult.data.ratings;
    const series = getSeries(creditExposureData, ['rating', 'year_end'], 'agg_weighting').map((element) => {
        // change weightings format to percentage
        return { ...element, data: element.data.map((item) => formatPercentages(item)) };
    });
    const categories = getUniqueKeys(creditExposureData, 'year_end');
    const constituentTypes: Record<string, Array<ConstituentType>> = creditExposureData
        .filter((element) => element.constituent_types.length > 0)
        .reduce((accumulator: any, currentValue) => {
            accumulator[currentValue.year_end] = currentValue.constituent_types.map((element) => {
                return {
                    ...element,
                    // change constituent types weightings format to percentage
                    agg_weighting: formatPercentages(element.agg_weighting),
                };
            });
            return accumulator;
        }, {});

    sortSeriesByList(series, ['AAA', 'AA', 'A', 'BBB', 'BB', 'B', 'Below B', 'Not Rated', 'Other', 'Unclassified']);

    const tooltipFormatter = function (this: any): string {
        const getFormattedValue = (unformattedValue: number | null) =>
            unformattedValue !== null && unformattedValue !== 0 ? unformattedValue.toFixed(2) : unformattedValue;

        const getOtherToolTipRow = () =>
            constituentTypes[this.category].map(
                (element) => `<span>${element.type_name}: ${getFormattedValue(element.agg_weighting)}%</span>`,
            );

        const getToolTipRow = (): Array<string> => {
            let prevPoint = this.x === 0 ? null : this.series.data[this.x - 1];
            let changePercentage: string = '';

            if (prevPoint?.y) {
                changePercentage = `<span><span class="dot" /></span><span>YoY % Change: ${calcPercentChange(
                    prevPoint.y,
                    this.y,
                    2,
                )}%</span>`;
            }

            return [
                `<span><span class="dot" style="background-color: ${this.series.color}"></span></span>` +
                    `<span>${this.series.name}: ${getFormattedValue(this.y as number)}%</span>`,
                changePercentage,
            ];
        };

        return getTooltipHTML(this.category, [
            ...getToolTipRow(),
            ...(this.series.name === 'Other' ? getOtherToolTipRow() : []),
        ]);
    };

    const asOfDate = creditExposureData
        .map((item) => item.as_of_date)
        .sort()
        .reverse()[0];

    const customExportsProps: CustomExportsProps = [
        {
            type: 'JPEG',
            callback: ({ chartRef }) =>
                exportCreditExposureHistoricalJpegChart({
                    chartRef: chartRef.current,
                    title: cardTitle,
                    subTitle,
                    ticker: companyData.ticker,
                    footnote,
                    asOfDate,
                }),
        },
    ];
    const columns: Map<string, ValueTypes> = new Map<string, ValueTypes>([['Category', ValueTypes.Integer]]);
    series.map((item) => {
        columns.set(item.name, ValueTypes.Percentage);
        return '';
    });

    return (
        <ETFCard.ETFCard containerStyles={{ position: 'relative' }}>
            <HistoricalStackedPercentage
                title={cardTitle}
                subTitle={subTitle}
                series={buildChartStacks(series, (action: string) => {
                    globalThis.analytics?.registerAction?.({
                        action: action,
                        cardName: cardTitle,
                    });
                })}
                categories={categories}
                exportingFileName={getExportingFileName(cardTitle, companyData.ticker, companyData.exchange)}
                tooltipFormatter={tooltipFormatter}
                plotOptionsAnimations={componentRendered ? false : true}
                customExports={customExportsProps}
                exports={{
                    asOfDate: asOfDate,
                    ticker: companyData.ticker,
                    etfName: companyData.composite_name,
                    columns: columns,
                }}
            />
            <Layout.DataAsOfDate date={asOfDate} />
            <DataDisplay.ChartFootnote note={footnote} />
        </ETFCard.ETFCard>
    );
}

async function exportCreditExposureHistoricalJpegChart(props: {
    chartRef: HighchartsReact.RefObject;
    title: string;
    subTitle: string;
    ticker: string;
    asOfDate: string;
    footnote: string;
}) {
    const { chartRef, title, subTitle, ticker, asOfDate, footnote } = props;
    const chart: Highcharts.Chart = chartRef.chart;

    const defaultChartOptions: Highcharts.Options = getExportDefaultChartOptions({ title, subtitle: subTitle, ticker });

    const chartOptions: Highcharts.Options = {
        ...defaultChartOptions,
        chart: {
            ...defaultChartOptions.chart,
            ...getSpacingOptions([25, 45, 50, 40]),
            ...getMarginOptions([110]),
            height: 600,
            width: 1000,
            events: {
                load: function (this: Highcharts.Chart) {
                    addLogoToHighchart(this);
                    addAsOfDateToHighchart(this, asOfDate);

                    if (footnote) {
                        this.renderer
                            .text(footnote, 150, this.chartHeight - 15)
                            .attr({ align: 'left', zIndex: 99 })
                            .css({ color: '#999999', fontSize: '11px' })
                            .add();
                    }
                },
            },
        },
    };

    exportChartAsImgWithFonts(chart, chartOptions);
}
