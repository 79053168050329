import { CFRASelect, ETFCard, Layout } from '@cfra-nextgen-frontend/shared';
import { queryClient } from '@cfra-nextgen-frontend/shared/src/lib/react-query-client';
import {
    Categories,
    MarketTrendsDateRanges,
    ValueTypes,
    categoriesToResponceCategoryField,
    categoriesToResponceLidField,
    fontFamilies,
    getMomentObjectFrom,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { SelectChangeEvent } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { getDownloadAction } from 'analytics/utils';
import { CategoriesInfo } from 'components/AgGrid/CategoriesInfo';
import { BarChartWithBigLabels } from 'components/Chart/BarWithBigLabels';
import { ChartTabs } from 'components/Chart/ChartTabs';
import { CustomExportsProps } from 'components/Chart/ExportMenus';
import { getDefaultFontStyle, getTooltipHTML } from 'components/Chart/Options';
import { NumberOrNull } from 'components/Chart/types';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { Series, SeriesClickCallbackFunction, SeriesClickEventObject } from 'highcharts/highstock';
import React, { ReactNode } from 'react';
import { QueryClientProvider, UseQueryResult } from 'react-query';
import { NavigateFunction } from 'react-router-dom';
import { pathMapping } from '../../../routes/MarketTrendsRoutes';
import { MarketTrend } from '../../../types/market';
import { ETFFlowsToAssetThemesAndFactorsData, ETFPerformanceThemesAndFactorsData } from '../../../types/research';
import { EtfsOverlay } from '../../shared/EtfsOverlay';
import { getNextLevelCategory } from '../../shared/utils';
import { MarketTrendsChartsTopPanel } from './MarketTrendsChartsTopPanel';
import { renderWithContainer } from './utils';

type SubChartProps = {
    chartHeader: string;
    categories: Array<string>;
    categoriesData: Array<NumberOrNull>;
    asOfDates: Array<string>;
    tickers?: Array<string>;
    chartRefObject?: React.RefObject<HighchartsReact.RefObject>;
    chartRefSetter: (value: any) => void;
    columnsToFormatting: Map<string, ValueTypes>;
};

type ThemesAndFactorsChartProps = {
    title: string;
    subHeader: string | ReactNode;
    exportFileName: string;
    exportHeaders?: Array<string>;
    asOfDate?: string;
    detailsPathName?: MarketTrend;
    subChartsProps: Array<SubChartProps>;
    tooltipFormatterValuePrefix: string;
    columnHeaderFormatter: (item: any, key: any) => string;
    dateRangesDropdownConfig?: {
        selectedDateRange: MarketTrendsDateRanges;
        handleChange: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void;
    };
    categoriesPanelConfig?: {
        selectedCategory: Categories;
        handleChange: (event: React.SyntheticEvent<Element, Event>, newValue: number) => void;
    };
    onBarClick?: SeriesClickCallbackFunction;
    customExports?: CustomExportsProps;
};

const PaleItemSubHeader = styled(ETFCard.ItemHeader)(({ theme }) => ({
    ...getDefaultFontStyle(15),
}));

const ChartHeader = styled(Paper)(({ theme }) => ({
    fontFamily: fontFamilies.GraphikMedium,
    lineHeight: 1,
    fontSize: '15px',
    color: '#57626a',
    borderBottom: '1px solid #74828D',
    borderRadius: '0px',
    paddingBottom: '0px',
}));

export const xAxisColumnName = 'Company name';

export function getColumnHeaderFormatter(yAxisColumnName: string) {
    return function (item: any, key: any): string {
        if (item instanceof Highcharts.Axis && item.isXAxis) return xAxisColumnName;
        return yAxisColumnName;
    };
}

export function ThemesAndFactorsChart({
    title,
    subHeader,
    subChartsProps,
    exportFileName,
    exportHeaders = [],
    asOfDate = '',
    tooltipFormatterValuePrefix,
    columnHeaderFormatter,
    detailsPathName,
    onBarClick,
    dateRangesDropdownConfig,
    categoriesPanelConfig,
    customExports,
}: ThemesAndFactorsChartProps) {
    return (
        <Box sx={{ width: '100%', margin: 'auto' }}>
            <MarketTrendsChartsTopPanel
                title={title}
                detailsPathName={detailsPathName}
                multipleExportProps={{
                    subChartsProps,
                    exportFileName,
                    exportHeaders,
                }}
                exports={{ asOfDate }}
                analyticsCallback={(type: string) => {
                    globalThis.analytics?.registerAction?.({
                        action: getDownloadAction(type),
                        cardName: title,
                        dateRange: dateRangesDropdownConfig?.selectedDateRange,
                        reportType: type,
                        reportName: exportFileName,
                        selectedCategory: categoriesPanelConfig?.selectedCategory,
                    });
                }}
                customExports={customExports}
            />
            <ETFCard.HorizontalPanel>
                <PaleItemSubHeader>{subHeader}</PaleItemSubHeader>
            </ETFCard.HorizontalPanel>
            {dateRangesDropdownConfig && categoriesPanelConfig ? (
                <ETFCard.HorizontalPanel container sx={{ paddingBottom: '24px' }}>
                    <Layout.Grid item>
                        <Layout.Grid container>
                            <ChartTabs
                                tabs={Object.values(Categories)}
                                currentActiveTab={Object.values(Categories).indexOf(
                                    categoriesPanelConfig.selectedCategory,
                                )}
                                handleTabChange={categoriesPanelConfig.handleChange}
                            />
                            <Layout.Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                <CategoriesInfo
                                    analyticsOpenModalProps={{
                                        cardName: title,
                                        dateRange: dateRangesDropdownConfig?.selectedDateRange,
                                        selectedCategory: categoriesPanelConfig?.selectedCategory,
                                    }}
                                />
                            </Layout.Grid>
                        </Layout.Grid>
                    </Layout.Grid>
                    <Layout.Grid item xs={2} sx={{ textAlign: 'right' }}>
                        <CFRASelect
                            selectItems={Object.values(MarketTrendsDateRanges)}
                            currentSelection={dateRangesDropdownConfig.selectedDateRange}
                            handleSelectionChange={dateRangesDropdownConfig.handleChange}
                        />
                    </Layout.Grid>
                </ETFCard.HorizontalPanel>
            ) : (
                ''
            )}
            <Layout.Grid container columnSpacing={3.5}>
                {React.Children.toArray(
                    subChartsProps.map((value) => {
                        function tooltipFormatter(
                            this: Highcharts.TooltipFormatterContextObject & { category?: string },
                        ) {
                            // category missed in TooltipFormatterContextObject
                            const asOfDate = value.asOfDates[value.categories.indexOf(this.category as string)];
                            return getTooltipHTML(this.category, [
                                value.tickers
                                    ? `Ticker: ${value.tickers[value.categories.indexOf(this.category as string)]}`
                                    : '',
                                `${tooltipFormatterValuePrefix} ${(this.y as number).toFixed(2)}%`,
                                `As of date: ${getMomentObjectFrom(asOfDate).format('MM/DD/YYYY')}`,
                            ]);
                        }

                        return (
                            <>
                                <Layout.Grid item xs={12}>
                                    <ChartHeader sx={{ paddingBottom: '12px' }}>{value.chartHeader}</ChartHeader>
                                </Layout.Grid>
                                <Layout.Grid item xs={12} sx={{ paddingTop: '12px' }}>
                                    <BarChartWithBigLabels
                                        categories={value.categories}
                                        categoriesData={value.categoriesData}
                                        columnHeaderFormatter={columnHeaderFormatter}
                                        setChartRef={value.chartRefSetter}
                                        chartRef={value.chartRefObject}
                                        tooltipFormatter={tooltipFormatter}
                                        onBarClick={onBarClick}
                                    />
                                </Layout.Grid>
                            </>
                        );
                    }),
                )}
            </Layout.Grid>
        </Box>
    );
}

export type onBarClickProps<T> = {
    selectedCategory: Categories;
    selectedDateRange: MarketTrendsDateRanges;
    chartData: UseQueryResult<{ data: T[] }>[];
    detailsPathName: MarketTrend;
    navigate: NavigateFunction;
    categoriesContainTickers?: boolean;
    cardTitle: string;
};

export function onBarClick<T extends ETFFlowsToAssetThemesAndFactorsData | ETFPerformanceThemesAndFactorsData>({
    selectedCategory,
    selectedDateRange,
    chartData,
    detailsPathName,
    navigate,
    categoriesContainTickers = false,
    cardTitle,
}: onBarClickProps<T>) {
    return function (this: Series, event: SeriesClickEventObject) {
        if (typeof event.point.category !== 'string') {
            return;
        }

        const cutOffTicker = (category: string) => {
            if (!categoriesContainTickers) {
                return category;
            }
            const indexOfTikerOpenParenthesis = category.lastIndexOf('(');
            return indexOfTikerOpenParenthesis > -1
                ? category.substring(0, indexOfTikerOpenParenthesis).trim()
                : category;
        };

        const pointCategory = cutOffTicker(String(event.point.category));

        const pointCategoryData = chartData
            .reduce((accumulator, currentValue) => {
                return accumulator.concat(currentValue.data?.data || ([] as Array<T>));
            }, [] as Array<T>)
            .find((categoryData) => {
                const categoryName =
                    categoryData[categoriesToResponceCategoryField[selectedCategory] as keyof typeof categoryData];
                return categoryName === pointCategory;
            });

        if (!pointCategoryData) {
            return;
        }

        if (selectedCategory === Categories.CategoryTwo) {
            const lid =
                pointCategoryData[categoriesToResponceLidField[selectedCategory] as keyof typeof pointCategoryData];

            if (!lid) {
                return;
            }

            // used renderWithContainer only because there is no way to render the component inside the main root, don't use it if there is available way to render the component inside the main root
            renderWithContainer('cfra-overlay-modal-container', (unmountCallback: () => void) => (
                <QueryClientProvider client={queryClient}>
                    <EtfsOverlay
                        cardName={cardTitle}
                        categoryLevel={selectedCategory}
                        categoryName={pointCategory}
                        lid={Number(lid)}
                        label={detailsPathName}
                        dateRange={selectedDateRange}
                        callbackOnClose={unmountCallback}
                        maxGridContainerHeightPercentage={90} // max modal height in percentage
                    />
                </QueryClientProvider>
            ));
            return;
        }

        const drillDownProps = {
            categoryOne: selectedCategory === Categories.CategoryOne ? pointCategory : '',
            assetClass: pointCategoryData['asset_class'],
        };

        globalThis.analytics?.registerAction?.({
            action: `drill down : ${
                drillDownProps.categoryOne
                    ? `${Categories.CategoryOne} : ${drillDownProps.categoryOne}`
                    : `${Categories.AssetClass} : ${drillDownProps.assetClass}`
            }`,
            cardName: cardTitle,
            dateRange: selectedDateRange,
            etfAssetClass: drillDownProps.assetClass,
            selectedCategory: selectedCategory,
        });

        navigate(pathMapping[detailsPathName], {
            state: {
                drillDownProps: drillDownProps,
                selectedDateRange: selectedDateRange,
                selectedCategory: getNextLevelCategory(selectedCategory),
            },
        });
    };
}
