import { LayoutProps } from '@cfra-nextgen-frontend/shared/src/components/layout/types/types';
import { MetadataFieldDefinition } from '@cfra-nextgen-frontend/shared/src/components/types/fieldMetadata';

export type FiltersData = {
    section_mapping: SectionMapping;
    filter_metadata: FiltersMetadata;
    data: Data;
};

type Data = {
    [key: string]: {
        items: Array<Item>;
        min?: number;
        max?: number;
    };
};

export type Item = {
    key: number;
    value: string;
    count?: number;
};

export type StringKeyValueItemWithViewdata = {
    key: string;
    value: string;
    order: number;
    default_selected?: boolean;
    source_field: string;
};

export type FiltersMetadata = { [key: string]: FilterMetadata };

export type FilterMetadata = CommonFilterMetadata & Sections & RequestMapping & ItemData;

export type Containers = { [key: string]: { layout: LayoutProps } };

export type Section = {
    name: string;
    parent?: string;
    level: number;
    order: number;
    containers?: Containers;
    layout?: LayoutProps;
};

type Option = {
    label?: string;
    key: number;
};

export type SectionMapping = {
    [key: string]: Section;
};

export enum Components {
    Slider = 'slider',
    Checkbox = 'checkbox',
    Autocomplete = 'autocomplete',
    AutocompleteSearchTable = 'autocomplete_with_search_and_table',
    DateRangePicker = 'daterangepicker',
    Switch = 'switch',
    AutocompletePicklist = 'autocomplete_picklist',
    DraggablePicklist = 'draggable_picklist',
}

export type CommonFilterMetadata = {
    component: Components;
    range: [number, number];
    default_values: Array<number> | null;
    selected_label?: string;
    selected_template?: string;
    label: string;
    request_value: { value?: string; excluded_value?: string } | null;
    format?: string;
    options: Array<Option>;
    placeholder?: string;
};

export type Sections = {
    sections: {
        [key: string]: { order: number; container?: string; layout?: LayoutProps };
    };
};

export type RequestMapping = {
    request_mapping: string | FilterRequestValues;
};

export type FilterRequestValues = {
    [key: string]: {
        request_value: { values?: Array<string>; excluded_values?: Array<string> };
    };
};

export type ItemData = {
    item_metadata: MetadataFieldDefinition;
};
