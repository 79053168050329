import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout/Grid';
import { LayoutProps } from '@cfra-nextgen-frontend/shared/src/components/layout/types/types';
import { breakpointsTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { ThemeProvider } from '@emotion/react';
import React from 'react';
import { StyledFormLabel } from './StyledFormLabel';

export const FormLabelWithContainer: React.FC<{
    label: string;
    children: React.ReactNode;
    layoutProps?: LayoutProps;
    disableStacking?: boolean;
    labelLayout?: number;
}> = ({ label, children, disableStacking, labelLayout = 5, layoutProps = { xs: 12 } }) => {
    const maxMUIGridSize = 12;
    const oneRowCSS = {
        justifyContent: 'start',
        width: `${(((maxMUIGridSize - labelLayout) / maxMUIGridSize) * 100).toFixed(4)}% !important`,
    };
    const stackingRules = disableStacking
        ? { paddingLeft: '30px', ...oneRowCSS }
        : {
              width: '100%',
              justifyContent: 'start',
              paddingLeft: '30px',
              [breakpointsTheme.breakpoints.up('sm')]: oneRowCSS,
          };
    return (
        <ThemeProvider theme={breakpointsTheme}>
            <Grid
                item
                container
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    border: '1px solid #EDF0F6',
                    borderRight: '0px',
                    marginRight: '-0.8px',
                    marginTop: '-0.8px',
                    minHeight: '43.6px',
                    paddingTop: '6px',
                    paddingBottom: '6px',
                }}
                {...layoutProps}>
                <Grid
                    xs={disableStacking ? labelLayout : 12}
                    sm={labelLayout}
                    item
                    sx={{ paddingLeft: '30px', [breakpointsTheme.breakpoints.down('sm')]: { paddingBottom: '6px' } }}>
                    <StyledFormLabel>{label}</StyledFormLabel>
                </Grid>
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        paddingRight: '15px',
                        ...stackingRules,
                    }}>
                    {children}
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};
