import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import { FormLabel, SxProps } from '@mui/material';

export const inputFontStyle = {
    color: '#57626A',
    fontSize: 14,
    fontFamily: fontFamilies.GraphikRegular,
};

export const StyledFormLabel: React.FC<{ children: React.ReactNode; sx?: SxProps }> = ({ children, sx }) => {
    return (
        <FormLabel
            component='legend'
            sx={{
                ...inputFontStyle,
                display: '-webkit-box',
                WebkitLineClamp: '2',
                wordWrap: 'break-word',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                lineHeight: 1,
                ...sx,
            }}>
            {children}
        </FormLabel>
    );
};
