import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import { fFunction } from './types';

type CommonButtonProps = {
    onClickCallback: fFunction;
    content: string | React.ReactNode;
    theme: Theme;
    outlined?: boolean;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset';
};

export function CommonButton(props: CommonButtonProps) {
    const { theme, outlined, content, onClickCallback, ...rest } = props;
    return (
        <ThemeProvider theme={props.theme}>
            <Button variant={outlined ? 'outlined' : undefined} onClick={onClickCallback} {...rest}>
                {content}
            </Button>
        </ThemeProvider>
    );
}
