import { RoundedTextButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton';
import { roundedTextButtonThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import { Box, SxProps, createTheme } from '@mui/material';
import { Modal } from './Modal';

const roundedTextButtonTheme = createTheme(roundedTextButtonThemeV2, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '13px',
                },
            },
        },
    },
});

type ConfirmationModalProps = {
    openModal: boolean;
    modalText: string;
    confirmButtonText: string;
    confirmCallback: () => void;
    cancelButtonText?: string;
    cancelCallback?: () => void;
    modalBoxStyles?: SxProps;
    descriptionStyle?: SxProps;
    buttonContainerStyles?: SxProps;
};

export function ConfirmationModal(props: ConfirmationModalProps) {
    const buttonContainerSx: SxProps = {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flexWrap: 'wrap',
        paddingTop: '18px',
        gap: '16px',
        ...props.buttonContainerStyles,
    };

    return (
        <Modal
            openModal={props.openModal}
            modalBoxStyles={{
                padding: '32px 30px 29px',
                display: 'flex',
                alignContent: 'start',
                flexDirection: 'column',
                maxWidth: 'unset',
                maxHeight: 'unset',
                width: 'unset',
                height: 'unset',
                ...props.modalBoxStyles,
            }}
            showCloseButton={false}
            description={props.modalText}
            descriptionStyle={{
                fontSize: '18px',
                display: 'flex',
                justifyContent: 'center',
                ...props.descriptionStyle,
            }}>
            <Box sx={buttonContainerSx}>
                {props.cancelButtonText && props.cancelCallback ? (
                    <Box>
                        <RoundedTextButton
                            outlined
                            theme={roundedTextButtonTheme}
                            buttonText={props.cancelButtonText}
                            onClickCallback={props.cancelCallback}
                        />
                    </Box>
                ) : null}
                <Box>
                    <RoundedTextButton
                        theme={roundedTextButtonTheme}
                        buttonText={props.confirmButtonText}
                        onClickCallback={props.confirmCallback}
                    />
                </Box>
            </Box>
        </Modal>
    );
}
