import { useState } from 'react';
import { deleteSavedScreen } from '../api/savedScreens';
import { DeleteModal } from './DeleteModal';

type DeleteSavedScreenModalProps = {
    id: number;
    name: string;
    onCancel: () => void;
    onConfirm: () => void;
};

export function DeleteSavedScreenModal(props: DeleteSavedScreenModalProps) {
    const { id, name, onCancel = () => {}, onConfirm = () => {} } = props;
    const [confirm, setConfirm] = useState(false);

    const { isError, isLoading } = deleteSavedScreen({
        savedItems: id,
        config: {
            enabled: confirm,
        },
    });

    if (confirm && !isLoading && !isError) {
        onConfirm();
    }

    return (
        <DeleteModal
            id={id}
            title={`Delete ${name}?`}
            onCancel={onCancel}
            onConfirm={() => {
                setConfirm(true);
            }}
        />
    );
}
