import { watchListColumnWidth } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/Constants';

export const WatchListColumn = {
    headerClass: 'ag-grid-selection-column',
    sortable: false,
    cellClass: 'ag-grid-cfra-watchlist-icons',
    checkboxSelection: true,
    width: watchListColumnWidth,
    minWidth: watchListColumnWidth,
    onCellClicked(event: any) {
        let node = event.node;

        let isSelected = node.isSelected() === undefined ? false : node.isSelected();
        node.setSelected(!isSelected);
    },
};
