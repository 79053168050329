export function waitUntil(conditionFunction: () => boolean, functionsToRun: Array<() => void>): void {
    let waitUntilMaxAttempts = 100; // limit max attempts
    let interval = setInterval(function () {
        if (waitUntilMaxAttempts <= 1) {
            clearInterval(interval);
        }
        if (conditionFunction()) {
            clearInterval(interval);
            functionsToRun.forEach((func) => func());
        }
        waitUntilMaxAttempts--;
    }, 300);
}
