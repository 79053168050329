import { HorizontalPagePaddingString, MaxPageWidthString, fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

type Props = {
    id?: string;
    children?: React.ReactNode;
    removeTopMargin?: boolean;
    sx?: SxProps;
    shadow?: boolean;
};

export const defaultShadow = {
    boxShadow: '0px 6px 6px rgba(0, 0, 0, .1  )',
    clipPath: 'polygon(0 0, 100% 0, 100% 200%, 0 200%)',
};

export const HeaderName = styled(Paper)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '26px',
    color: '#0B2958',
    boxShadow: 'none',
    lineHeight: '1',
    paddingTop: '16px',
    paddingBottom: '16px',
}));

export function ETFHeaderContainer({ id, children, sx, shadow }: Props) {
    return (
        <Box
            id={id}
            sx={{
                backgroundColor: '#FFF',
                zIndex: 1100,
                ...(shadow && defaultShadow),
                ...sx,
            }}>
            <Container
                disableGutters
                maxWidth={false}
                sx={{
                    maxWidth: MaxPageWidthString,
                    padding: `0px ${HorizontalPagePaddingString}`,
                    backgroundColor: '#FFF',
                }}>
                {children}
            </Container>
        </Box>
    );
}
