import MUITooltip, { TooltipProps } from '@mui/material/Tooltip';
import { CSSProperties, useState } from 'react';

type CFRATooltipProps = {
    tooltipContainerStyles?: CSSProperties;
} & TooltipProps;

const Tooltip = ({ children, tooltipContainerStyles, ...rest }: CFRATooltipProps) => {
    const [renderTooltip, setRenderTooltip] = useState(false);
    return (
        <div
            onMouseEnter={() => !renderTooltip && setRenderTooltip(true)}
            className='display-contents'
            style={tooltipContainerStyles}>
            {!renderTooltip && children}
            {renderTooltip && (
                <MUITooltip {...rest} arrow>
                    {children}
                </MUITooltip>
            )}
        </div>
    );
};

export default Tooltip;
